import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { SharingModalContext } from '../context/SharingModalContext';
import { Button, Icon } from '@plecto/ui';
import { useTranslation } from 'react-i18next';

const StyledPopoverContainer = styled.div`
	width: 360px;
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
	background: #FFFFFF;
	border-radius: 4px;
`;

const StyledHeader = styled.div`
	border-bottom: 1px solid #E5E7EB;
	display: flex;
	gap: 10px;
	padding: 16px 20px;
`;

const StyledIcon = styled(Icon)`
	cursor: pointer;
`;

const StyledHeaderText = styled.span`
	flex: 1;
	color: #0F1E38;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 14px;
	line-height: 150%;
`;

const StyledButtonWrapper = styled.p`
	margin: 0;
	padding: 20px;
`;

type PopoverProps = {
	title: string;
	onClose: () => void;
	onBackClick?: () => void;
	saveButtonLabel?: string,
} & PropsWithChildren

export const PopoverContentWrapper = ({
	children,
	title,
	onClose,
	onBackClick,
	saveButtonLabel,
}: PopoverProps) => {
	const { closeModal } = React.useContext(SharingModalContext);
	const { t } = useTranslation();

	return <StyledPopoverContainer>
		<StyledHeader>
			{onBackClick ? <StyledIcon icon="chevron-left" onClick={onBackClick} /> : null}
			<StyledHeaderText>{title}</StyledHeaderText>
			<StyledIcon className='modal-close-btn' icon='x' onClick={closeModal} />
		</StyledHeader>
		{children}
		<StyledButtonWrapper>
			<Button
				className={'btn-block'}
				variant={'primary'}
				label={saveButtonLabel || t('Save')}
				onClick={onClose}
			/>
		</StyledButtonWrapper>
	</StyledPopoverContainer>;
};

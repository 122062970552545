import { Uuid } from '../types/Uuid';
import { ApiTeam } from '../types/ApiTeam';

export type TeamModel = {
	uuid: Uuid;
	name: string;
	hasWriteAccessUnderlyingObjects?: boolean,
	avatarUrl: string;
	url: string;
}

export const transformTeamToModel: (x: ApiTeam) => TeamModel = (team) => ({
	uuid: team.uuid,
	name: team.name,
	hasWriteAccessUnderlyingObjects: team.has_access_to_underlying_objects,
	avatarUrl: team.avatar_url,
	url: team.url,
});

import styled from 'styled-components';
import _clamp from 'lodash/clamp';
import _round from 'lodash/round';

const StyledBar = styled.div`
	height: 8px;
	border-radius: 8px;
`;

const StyledOuter = styled(StyledBar)`
	width: 100%;
	background: #F6F6F7;
`;

const StyledInner = styled(StyledBar)`
	background: #12A34D;
	transition: width 0.2s ease-in-out;
`;

type DialogProgressProps = {
	progress: number;
};

export function DialogProgress({
	progress
}: DialogProgressProps) {
	const progressNormalized = _round(_clamp(progress ?? 0, 0, 100), 2);
	
	return (
		<StyledOuter>
			<StyledInner style={{ width: `${progressNormalized}%` }} />
		</StyledOuter>
	);
}

import styled from 'styled-components';
import { Icon, IconName } from '../../icon';

type PreviewButtonProps = {
	onClick: () => void,
	icon: IconName
}

const StyledButton = styled.div`
	height: 24px;
	width: 24px;
	background: rgba(184, 196, 213, 0.20);
	color: #021733;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		background: rgba(206, 227, 255, 0.60);
	}

	${Icon.cssSelector} {
		font-size: 16px;
	}
`;

export const PreviewButton = ({ onClick, icon }: PreviewButtonProps) => {
	return (
		<StyledButton onClick={onClick}>
			<Icon icon={icon} />
		</StyledButton>
	);
};

export const loaderSizes = {
	default: 'default',
	sm: 'sm',
	lg: 'lg',
	xl: 'xl',
} as const;

export type LoaderSizes = typeof loaderSizes[keyof typeof loaderSizes];

type SizeStyling = {
	width: string;
	border: string;
};

type CssSizes = {
	[key in typeof loaderSizes[keyof typeof loaderSizes]]: SizeStyling;
};
export const cssSizes: CssSizes = {
	[loaderSizes.default]: {
		width: '20px',
		border: '2px',
	},
	[loaderSizes.sm]: {
		width: '12px',
		border: '2px',
	},
	[loaderSizes.lg]: {
		width: '40px',
		border: '3px',
	},
	[loaderSizes.xl]: {
		width: '80px',
		border: '3px',
	}
};

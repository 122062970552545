export const keyCodes = {
	ESC: 'Escape',
	BACKSPACE: 'Backspace',
	ENTER: 'Enter',
	E: 'KeyE',
	R: 'KeyR',
	A: 'KeyA',
	COMMA: ',',
} as const;

export type KeyCode = typeof keyCodes[keyof typeof keyCodes];


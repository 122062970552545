import { CharacterAnimation } from '../types';

const workingArea = {
	bottomLeft: 0.15,
	bottomRight: 0.85,
	bottomY: 0.75,

	topLeft: 0.42,
	topRight: 0.6,
	topY: 0.39
};

export const mountainContestDefinition = {
	image: 'https://files.static.plecto.com/contest/backgrounds/mountiain-5d987de859.png',
	animation: 'walk' as CharacterAnimation,
	characterScale: 0.000085714285714,
	positioner: (progress: number, index: number, numberOfCharacters: number,
		width: number, height: number) => {
		const xLeft = workingArea.bottomLeft
			+ ((workingArea.topLeft - workingArea.bottomLeft) * (progress / 100));

		const xRight = workingArea.bottomRight
			+ ((workingArea.topRight - workingArea.bottomRight) * (progress / 100));

		const spaceToUse = width - (xLeft * width) - ((1 - xRight) * width);
		const interval = numberOfCharacters > 1 ? spaceToUse / (numberOfCharacters - 1) : 0;
		const x = (xLeft * width) + (interval * index);

		const positionOnY = workingArea.bottomY - ((workingArea.bottomY
			- workingArea.topY) * (progress / 100));
		const y = positionOnY * height;

		return {
			x,
			y
		};
	}
};

import { useCallback, useState } from 'react';

const DEFAULT_VALUE = -1;

type Props = {
    defaultValue?: number | null;
    disabled: boolean;
    readOnly: boolean;
    onChange?: (value: number) => void;
    onActiveChange?: (value: number) => void;
}

export function useRating({ defaultValue, disabled, readOnly, onChange, onActiveChange }: Props) {
	const isActive = !disabled && !readOnly;
	const [activeValue, setActiveValue] = useState<number>(defaultValue || DEFAULT_VALUE);

	const [hoverActiveValue, setHoverActiveValue] = useState<number>(DEFAULT_VALUE);
	const [isHovered, setIsHovered] = useState<boolean>(false);

	const setRating = (value: number) => {
		setIsHovered(true);
		setHoverActiveValue(value);
	};

	const onRatingChange = useCallback((value: number) => {
		setActiveValue(value);
		onChange?.(value);
	}, [onChange]);

	const onRatingActiveChange = useCallback((value: number) => {
		setRating(value);
		onActiveChange?.(value);
	}, [onActiveChange]);

	const activeState = !isActive ? activeValue : isHovered ? hoverActiveValue : activeValue;

	const resetRating = () => {
		setIsHovered(false);
		setHoverActiveValue(defaultValue || DEFAULT_VALUE);
	};

	return {
		isHovered,
		onRatingChange,
		onRatingActiveChange,
		activeState,
		setRating,
		resetRating,
	};
}

import { useMemo } from 'react';
import styled from 'styled-components';
import _range from 'lodash/range';

import { CommonRatingProps, CustomRatingOption, optionStates } from '../CustomRating';
import { RatingSize, RatingSizes } from '../types/RatingSize';
import { RatingOption } from '../RatingOption';
import { Icon } from '../../icon';
import { RatingWithGap } from '../RatingContainer';

const StyledStar = styled(RatingOption)<{
	$color?: string;
}>`
	color: ${props => props.$color || '#0F1E38'};
`;

type StarsRatingProps = {
	startFromZero?: boolean;
	max?: number;
	color?: string;
	highlightColor?: string;
	size?: RatingSize;
} & CommonRatingProps;

export const StarsRating = ({
	startFromZero = false,
	max = 5,
	highlightColor,
	color,
	size = 'medium',
	...props
}: StarsRatingProps) => {

	const sizeDefinition = RatingSizes[size];
	const { disabled = false, readOnly = false } = props;

	const options: CustomRatingOption[] = useMemo(() => {
		return _range(startFromZero ? 0 : 1, max + 1).map(value => ({
			value,
			render: ({ state, onChange, onHover }) => <StyledStar
				sizeDefinition={sizeDefinition}
				key={value}
				$color={state === optionStates.HIGHLIGHTED ? highlightColor : color}
				onChange={onChange}
				onHover={onHover}
				readOnly={readOnly}
				disabled={disabled}
			>
				<Icon icon={state === optionStates.UNCHECKED ? 'star-outline' : 'star'} />
			</StyledStar>
		}));
	}, [color, highlightColor, max, disabled, readOnly, sizeDefinition, startFromZero]);

	return (
		<RatingWithGap
			$sizeDefinition={sizeDefinition}
			{...props}
			options={options}
		/>
	);
};

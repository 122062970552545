import React, { PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const StyledHost = styled.div`
	min-height: 100vh;
	position: absolute;
	right: 0;
	top: 0;
`;

export const SlideInHost = ({ children, className = 'slide-in-host' }: PropsWithChildren<{
	className?: string;
}>) => {
	const [container] = React.useState(() => {
		// This will be executed only on the initial render
		// https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
		return document.createElement('div');
	});
  
	React.useEffect(() => {
		document.body.appendChild(container);
		return () => {
			document.body.removeChild(container);
		};
	}, [container]);
  
	return ReactDOM.createPortal(<StyledHost className={className}>
		{children}
	</StyledHost>, container);
};

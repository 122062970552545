import React from 'react';
import { Input, FormField, InputLabel, HelpText, InputProps } from '@plecto/ui';
import styled from 'styled-components';

const StyledFormField = styled(FormField)`
	& > ${InputLabel.cssSelector} + ${HelpText.cssSelector} {
		margin-top: 0
	}
`;

const StyledHelpText = styled(HelpText)`
	font-size: 11px;
`;

type CheckboxProps = {
	labelText: string;
	helpText: string;
} & Pick<InputProps, 'id' |'checked'| 'onChange'| 'disabled'>

export function Checkbox({ id, checked, onChange, disabled, labelText, helpText }: CheckboxProps) {
	return <StyledFormField>
		<InputLabel disabled={disabled}>
			<Input type="checkbox" checked={checked} onChange={onChange} disabled={disabled} id={id} />
			{labelText}
			<StyledHelpText>{helpText}</StyledHelpText>
		</InputLabel>
	</StyledFormField>;
}

/* eslint-disable no-magic-numbers */
import { useCallback, useLayoutEffect, useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Loader } from '../../loader';
import * as PIXI from 'pixi.js-legacy';
import { AvatarSettings, CharacterAnimation } from '../types';
import { useCharacterAvatar } from './useCharacterAvatar';
import { DIMENSIONS, SCALE } from './constants';
import { PreviewButton } from './PreviewButton';
import { createPixiApp } from '../app/pixiUtilities';

export type AvatarPreviewProps = {
	settings: AvatarSettings;
	animate?: boolean;
	animation?: CharacterAnimation;
	onToggleRocket?: (value: boolean) => void;
}

const StyledPreviewContainer = styled.div`
	background: radial-gradient(60.08% 60.08% at 50% 50%, #C1CAD7 0%, #919FB3 100%);
	width: 100%;
	height: ${DIMENSIONS.height}px;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	padding: 0 12px;
`;

const StyledPreview = styled.div<{
	$visible: boolean;
}>`
	${props => !props.$visible && css`display: none;`}
	width: ${DIMENSIONS.width}px;
	height: ${DIMENSIONS.height}px;
	background: transparent;

	canvas {
		width: ${DIMENSIONS.width}px;
		height: ${DIMENSIONS.height}px;
	}
`;

const characterScale = (showInRocket?: boolean) => showInRocket ? 0.17 : 0.22;

export const AvatarPreview = ({
	settings,
	animate,
	animation,
	onToggleRocket
}: AvatarPreviewProps) => {
	const pixiApp = useRef<PIXI.Application | null>(null);
	const canvasRef = useRef<HTMLDivElement | null>(null);
	const [loaded, setLoaded] = useState(false);
	const [showInRocket, setShowInRocket] = useState(settings.showInRocket || false);

	const createOrUpdateAvatar = useCharacterAvatar(pixiApp, {
		settings, showInRocket, animate, animation, dimensions: {
			...DIMENSIONS,
			scale: characterScale(showInRocket),
			y: settings.kind === 'human' && !showInRocket ? 130 : 115,
		}});

	useLayoutEffect(() => {
		if (pixiApp.current && loaded) {
			createOrUpdateAvatar();
		}
	}, [createOrUpdateAvatar, loaded]);

	const onLoaded = useCallback(() => {
		if (pixiApp.current && canvasRef.current) {
			const canvas = pixiApp.current.view;
			
			canvasRef.current.innerHTML = '';
			canvasRef.current.appendChild(canvas);

			createOrUpdateAvatar();
		}
		setLoaded(true);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useLayoutEffect(() => {
		pixiApp.current = createPixiApp({
			width: DIMENSIONS.width * SCALE,
			height: DIMENSIONS.height * SCALE,
			onComplete: onLoaded,
		});
		
	}, [onLoaded]);

	const toggleRocket = useCallback(() => {
		const change = !showInRocket;
		setShowInRocket(change);
		onToggleRocket?.(change);
	}, [onToggleRocket, showInRocket]);

	useEffect(() => {
		if (loaded) {
			setShowInRocket(Boolean(settings.showInRocket));
		}
	}, [loaded, settings.showInRocket]);

	return <StyledPreviewContainer>
		{loaded && <PreviewButton icon="chevron-left" onClick={toggleRocket} />}
		{!loaded && <Loader />}
		<StyledPreview ref={canvasRef} $visible={loaded} />
		{loaded && <PreviewButton icon="chevron-right" onClick={toggleRocket} />}
	</StyledPreviewContainer>;
};


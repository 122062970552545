import { Uuid } from '../types/Uuid';
import { ApiPermissionType, ApiSharedObject, ApiSharing, } from '../types/ApiSharing';
import { EmployeeModel, transformEmployeeToModel } from './EmployeeModel';
import { SharedObjectType } from '../types/sharedObjectType';
import { TeamModel, transformTeamToModel } from './TeamModel';

export type SharingModel = {
	uuid: Uuid;
	objectType: SharedObjectType;
	objectUuid: Uuid;
	sharedBy?: EmployeeModel;
	objectPermissions: Array<ApiPermissionType>;
	title: string;
}

export const transformSharingToModel: (x: ApiSharing) => SharingModel = (sharing) => ({
	uuid: sharing.uuid,
	objectType: sharing.content_type,
	objectUuid: sharing.object_uuid,
	objectPermissions: sharing.object_permissions,
	title: sharing.title ?? '',
	...(sharing.shared_by ? { sharedBy: transformEmployeeToModel(sharing.shared_by) } : {}),
});

export type SharedObjectModel = SharingModel & {
	readonly sharedWith: Uuid | null;
	readonly sharedWithTeam: TeamModel | null;
}

export const transformSharedObjectToModel: (x: ApiSharedObject) => SharedObjectModel = (sharing) => ({
	...transformSharingToModel(sharing),
	sharedWith: sharing.shared_with ?? null,
	sharedWithTeam: sharing.shared_with_team ? transformTeamToModel(sharing.shared_with_team) : null,
});


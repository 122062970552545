export function scrollElementIntoView(selector: string): void {
	const domNode = document.querySelector(selector);
	if (domNode) {
		scrollSmoothly(domNode);
	}
}

function scrollSmoothly(domNode: Element, options?: ScrollIntoViewOptions): void {
	requestAnimationFrame(() => {
		domNode.scrollIntoView({
			behavior: 'smooth',
			...(options ?? {}),
		});
	});
}

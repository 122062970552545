import { ApiSharingTeam } from '../types/ApiSharing';
import { SharingModel, transformSharingToModel } from './SharingModel';
import { TeamModel, transformTeamToModel } from './TeamModel';

export type TeamSharingModel = SharingModel & {
	sharedWithTeam: TeamModel;
	teamName: string;
}

export const transformTeamSharingToModel: (x: ApiSharingTeam) => TeamSharingModel = (sharing) => ({
	...transformSharingToModel(sharing),
	sharedWithTeam: transformTeamToModel(sharing.shared_with_team),
	teamName: sharing.shared_with_team.name,
});

import { SharedObjectType, SharedObjectTypes } from '../types/sharedObjectType';
import _isEmpty from 'lodash/isEmpty';
import { InaccessibleUnderlyingObjectModel } from '../models/InaccessibleUnderlyingObjectModel';

type Props = {
	objectType: SharedObjectType,
	objects: InaccessibleUnderlyingObjectModel[],
}

export function showUnderlyingObjects({ objectType, objects }: Props) {
	if (_isEmpty(objects)) {
		return false;
	}
	const objectsWithNoAccess = objects.filter(object => !object.hasAccess);
	return objectType === SharedObjectTypes.Formula && !_isEmpty(objectsWithNoAccess);
}

import React, { PropsWithChildren } from 'react';
import { StepperProps } from './Stepper.props';
import { StepperRoot } from './Stepper.styled';
import { StepperContext } from './StepperContext';
import { StepConnector } from './StepConnector/StepConnector';

const defaultConnector = <StepConnector />;

const Stepper = React.forwardRef(function Stepper(props: PropsWithChildren<StepperProps>, ref) {
	const {
		activeStep = 0,
		alternativeLabel = false,
		children,
		className,
		component = 'ol',
		connector = defaultConnector,
		orientation = 'horizontal',
		size = 'md',
		...other
	} = props;

	const ownerState = {
		...props,
		alternativeLabel,
		orientation,
		component,
	};

	const childrenArray = React.Children.toArray(children).filter(Boolean);

	const steps = childrenArray.map((step, index) => {
		if (React.isValidElement(step)) {
			return React.cloneElement(step, {
				index,
				last: index + 1 === childrenArray.length,
				...step.props,
			});
		}
		return step;
	});

	const contextValue = React.useMemo(
		() => ({ activeStep, alternativeLabel, connector, orientation, size }),
		[activeStep, alternativeLabel, connector, orientation, size],
	);

	return (
		<StepperContext.Provider value={contextValue}>
			<StepperRoot
				as={component}
				ownerState={ownerState}
				className={className}
				ref={ref}
				{...other}
			>
				{steps}
			</StepperRoot>
		</StepperContext.Provider>
	);
});

export { Stepper };

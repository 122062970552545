import React, { useContext } from 'react';
import styled from 'styled-components';
import { TabSelector } from '@plecto/ui';
import { ObjectAccessTeamsOverview } from './ObjectAccessTeamsOverview';
import { ObjectAccessEmployeesOverview } from './ObjectAccessEmployeesOverview';
import { ShareeContext } from '../context/ShareeContext';
import { shareeTypes, ShareeType } from '../types/ShareeType';
import { TeamSharingModel } from '../models/TeamSharingModel';
import { EmployeeSharingModel } from '../models/EmployeeSharingModel';
import { SharingModel } from '../models/SharingModel';
import { useTranslation } from 'react-i18next';

export const StyledScrollableContainer = styled.div`
	max-height: 200px;
	overflow-y: auto;
`;

export const StyledTabSelector = styled(TabSelector)`
	.tab-buttons-container {
		padding: 0;
	}
`;

export const StyledParagraph = styled.p`
	padding: 16px 0;
	color: #9CA3AF;
	text-align: center;
	margin: 0;
`;

export const StyledSharings = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 8px;
	padding: 0 20px;
`;

const StyledTabContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const StyledTabSelectorContainer = styled.div``;

type ObjectAccessOverviewProps = {
	memberSharings: Array<EmployeeSharingModel> | [],
	isLoadingMemberSharings: boolean,
	teamSharings: Array<TeamSharingModel> | [],
	isLoadingTeamSharings: boolean,
	onShareeSelect: (sharings: SharingModel) => void,
	onAddMoreSharees: (shareeType: ShareeType) => void,
}

export function ObjectAccessOverview({
	memberSharings,
	isLoadingMemberSharings,
	teamSharings,
	isLoadingTeamSharings,
	onShareeSelect,
	onAddMoreSharees,
} : ObjectAccessOverviewProps) {
	const { t } = useTranslation();
	const { selectedShareeType, onSelectSharee: onSelectShareeType } = useContext(ShareeContext);

	const onSelectSharee = (sharings: SharingModel, shareeType: ShareeType) => {
		onShareeSelect(sharings);
		onSelectShareeType(shareeType);
	};

	const onTabChange = (tab: string) => onSelectShareeType(tab as ShareeType);

	const onAddMoreButtonClick = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		onAddMoreSharees(selectedShareeType);
	};

	return (
		<StyledTabSelectorContainer>
			<StyledTabSelector selectedTab={selectedShareeType} onTabChange={onTabChange}>
				<StyledTabContentWrapper
					id={shareeTypes.employees}
					title={t('Employees ({{number}})', { number: memberSharings.length })}
				>
					<ObjectAccessEmployeesOverview
						memberSharings={memberSharings}
						isLoadingMemberSharings={isLoadingMemberSharings}
						onAddMore={onAddMoreButtonClick}
						onSelectSharee={onSelectSharee}
					/>
				</StyledTabContentWrapper>
				<StyledTabContentWrapper
					id={shareeTypes.teams}
					title={t('Teams ({{number}})', { number: teamSharings.length })}
				>
					<ObjectAccessTeamsOverview
						teamSharings={teamSharings}
						isLoadingTeamSharings={isLoadingTeamSharings}
						onAddMore={onAddMoreButtonClick}
						onSelectSharee={onSelectSharee}
					/>
				</StyledTabContentWrapper>
			</StyledTabSelector>
		</StyledTabSelectorContainer>
	);
}

import styled, { css } from 'styled-components';
import { StepOwnerState } from './Step.props';
import { OrientationOptions } from '../Stepper.props';
import { StepIndicatorRoot } from '../StepIndicator/StepIndicator.styled';

const StepRoot = styled.li<StepOwnerState>`
  position: relative;
  display: flex;
  gap: 8px;
  cursor: default;
  color: ${({ theme, ownerState }) =>
		ownerState.completed || ownerState.active ?
			theme.stepper.stepLabel.primary.color : theme.stepper.stepLabel.neutral.color};
  justify-content: ${({ ownerState }) => ownerState.alternativeLabel ? 'flex-start' : 'center'};

  ${({ ownerState }) => ownerState.orientation === OrientationOptions.horizontal ? css`
    align-items: center;
    ${ownerState.alternativeLabel ? css`
      flex-direction: column;
      flex-grow: 1;
      flex-basis: 0;
      min-width: 0;
      text-align: center;
    ` : (ownerState.last ? css`
      white-space: nowrap;
      flex: 0;
    ` : css`
      flex-direction: row;
      flex: 1 auto;
    `)}
  ` : css`
    justify-content: flex-start;
    align-items: center;
    margin-top: 8px;

    &:first-child {
      margin-top: 0;
    }
  `}
`;

const StepAction = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: ${props => props.theme.stepper.stepLabel.primary.color};

  &:hover {
    color: ${props => props.theme.stepper.stepButton.color};
    text-decoration: underline;

    ${StepIndicatorRoot} {
      background-color: ${props => props.theme.stepper.stepIndicator.primary.background};
      color: ${props => props.theme.stepper.stepIndicator.primary.color};
    }
  }
`;

export { StepRoot, StepAction };

import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import noop from 'lodash/noop';
import { SharingModel } from '../models/SharingModel';
import { ShareeType, shareeTypes } from '../types/ShareeType';
import { SharedObjectMetadata } from '../types/SharedObjectMetadata';

type SharingModalContextValue = {
	isOpen: boolean;
	sharedObjectMetadata: SharedObjectMetadata | null;
	openModal: (sharedObjectMetadata: SharedObjectMetadata) => void;
	openModalWithShareeToBeModified: (sharedObjectMetadata: SharedObjectMetadata, sharings: SharingModel) => void;
	closeModal: () => void;
	shareeToBeModified: SharingModel | null;
	onSelectShareeType: (type: ShareeType) => void;
	selectedShareeType: ShareeType;
}

export const SharingModalContext = React.createContext<SharingModalContextValue>({
	isOpen: false,
	openModal: noop,
	openModalWithShareeToBeModified: noop,
	closeModal: noop,
	sharedObjectMetadata: null,
	shareeToBeModified: null,
	onSelectShareeType: noop,
	selectedShareeType: shareeTypes.employees,
});

export function SharingModalContextWrapper({ children }: PropsWithChildren) {
	const [isOpen, setIsOpen] = useState(false);
	const [
		shareeToBeModified,
		setShareeToBeModified
	] = useState<SharingModel | null>(null);

	const [selectedShareeType, setShareeType] = useState<ShareeType>(shareeTypes.employees);
	const onSelectShareeType = (type: ShareeType) => setShareeType(type);

	const [objectMetadata, setObjectMetadata] = useState<SharedObjectMetadata | null>(null);

	const openModal = useCallback((sharedObjectMetadata: SharedObjectMetadata) => {
		setObjectMetadata(sharedObjectMetadata);
		setIsOpen(true);
	}, []);

	const openModalWithShareeToBeModified = useCallback((
		sharedObjectMetadata: SharedObjectMetadata, sharings: SharingModel
	) => {
		setShareeToBeModified(sharings);
		openModal(sharedObjectMetadata);
	}, [openModal]);

	const closeModal = useCallback(() => {
		setObjectMetadata(null);
		setIsOpen(false);
		if (shareeToBeModified)
			setShareeToBeModified(null);
	}, [shareeToBeModified]);

	const contextValue: SharingModalContextValue = useMemo(() => ({
		isOpen,
		openModal,
		closeModal,
		shareeToBeModified,
		selectedShareeType,
		openModalWithShareeToBeModified,
		sharedObjectMetadata: objectMetadata,
		onSelectShareeType,
	}), [isOpen, openModal, closeModal, shareeToBeModified, selectedShareeType,
		openModalWithShareeToBeModified, objectMetadata]);

	return <SharingModalContext.Provider value={contextValue}>
		{children}
	</SharingModalContext.Provider>;
}

import styled from 'styled-components';
import { useRating } from './utils/useRating';
import { RatingSize } from './types/RatingSize';
import { PropsWithClassName } from '../tabselector/PropsWithClassName';

export const RatingContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const optionStates = {
	UNCHECKED: 'unchecked',
	HIGHLIGHTED: 'highlighted',
};

const DEFAULT_VALUE = -1;

export type OptionState = typeof optionStates[keyof typeof optionStates];

export type RatingOptionRenderProps = {
	key: number;
	hovered: boolean;
	checked: boolean;
	state: OptionState,
	onChange: () => void;
	onHover: () => void;
}

export type CustomRatingOption = {
	value: number,
	render: (props: RatingOptionRenderProps) => JSX.Element
}

export type CommonRatingProps = {
	disabled?: boolean;
	readOnly?: boolean;
	selectedValue?: number | null;
	onChange: (value: number) => void;
	onActiveChange?: (value: number) => void;
	size?: RatingSize;
}

type CustomRatingProps = {
	options: CustomRatingOption[];
	highlightSelectedOnly?: boolean;
} & CommonRatingProps;

export function CustomRating({
	disabled = false,
	selectedValue,
	options,
	onChange,
	onActiveChange,
	readOnly = false,
	highlightSelectedOnly,
	className,
}: PropsWithClassName<CustomRatingProps>) {

	const {
		isHovered,
		activeState,
		onRatingChange,
		onRatingActiveChange,
		resetRating,
	} = useRating({
		defaultValue: selectedValue, disabled, readOnly, onChange, onActiveChange
	});

	return (
		<RatingContainer className={className} onMouseLeave={resetRating}>
			{options.map((option, index) => {
				const value = option.value;

				const highlighted =
					(highlightSelectedOnly ? activeState === value : activeState >= value) 
						&& activeState !== DEFAULT_VALUE;
				const checked = activeState === value;
				const active = !disabled && !readOnly;

				const state: OptionState = highlighted ? optionStates.HIGHLIGHTED : optionStates.UNCHECKED;

				const props: RatingOptionRenderProps = {
					key: index,
					state,
					hovered: isHovered,
					checked,
					onChange: () => {
						if (active) {
							onRatingChange(value);
						}
					},
					onHover: () => {
						if (active) {
							onRatingActiveChange(value);
						}
					}
				};
				return option.render(props);
			})}
			
		</RatingContainer>
	);
}

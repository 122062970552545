import React, { useContext, useState } from 'react';
import { ModifyEmployeePermissionsPopover } from './components/popovers/ModifyEmployeePermissionsPopover';
import { SharedObjectMetadata } from './types/SharedObjectMetadata';
import { Else, If } from '@plecto/ui';
import { ShareeContext } from './context/ShareeContext';
import { AddObjectAccessPopover } from './components/popovers/AddObjectAccessPopover';
import { ModifyTeamPermissionsPopover } from './components/popovers/ModifyTeamPermissionsPopover';
import { TeamSharingModel } from './models/TeamSharingModel';
import { EmployeeSharingModel } from './models/EmployeeSharingModel';
import { SharingModel } from './models/SharingModel';
import { shareeTypes } from './types/ShareeType';
import { ObjectAccessOverviewPopover } from './components/popovers/ObjectAccessOverviewPopover';
import { SharingModalContext } from './context/SharingModalContext';
import { ApiContext } from './context/ApiContext';

type ShareObjectPopoverProps = {
	sharedObjectMetadata: SharedObjectMetadata;
}

export const ShareObjectsPopover = ({ sharedObjectMetadata }: ShareObjectPopoverProps) => {
	const api = useContext(ApiContext);

	const { selectedShareeType } = useContext(ShareeContext);
	const { shareeToBeModified: initShareeToBeModified } = useContext(SharingModalContext);
	const { objectUuid, objectType } = sharedObjectMetadata;

	const {
		data: memberSharings = [],
		isLoading: isLoadingMemberSharings,
		// @ts-ignore
	} = api.useGetMembersPermissionsQuery({ objectUuid, objectType });

	const {
		data: teamSharings = [],
		isLoading: isLoadingTeamSharings
		// @ts-ignore
	} = api.useGetTeamsPermissionsQuery({ objectUuid, objectType });

	const [
		shareeToBeModified,
		setShareeToBeModified
	] = useState<SharingModel | null>(initShareeToBeModified);
	const [shareeToBeAdded, setShareeToBeAdded] = useState<shareeTypes | null>(null);

	const onSelect = (sharings: SharingModel) => setShareeToBeModified(sharings);

	const onClickAddMore = () => setShareeToBeAdded(selectedShareeType);

	const clearShareeToBeModified = () => setShareeToBeModified(null);
	const clearMoreShareeToBeAdded = () => setShareeToBeAdded(null);

	if (shareeToBeAdded) {
		return <AddObjectAccessPopover
			memberSharings={memberSharings}
			teamSharings={teamSharings}
			onBackClick={clearMoreShareeToBeAdded}
			sharedObjectMetadata={sharedObjectMetadata}
		/>;
	}

	return <>
		<If condition={Boolean(shareeToBeModified)}>
			<If condition={selectedShareeType === shareeTypes.employees}>
				<ModifyEmployeePermissionsPopover
					shareeToBeModified={shareeToBeModified as EmployeeSharingModel}
					clearShareeToBeModified={clearShareeToBeModified}
					sharedObjectMetadata={sharedObjectMetadata}
				/>
				<Else>
					<ModifyTeamPermissionsPopover
						shareeToBeModified={shareeToBeModified as TeamSharingModel}
						clearShareeToBeModified={clearShareeToBeModified}
						sharedObjectMetadata={sharedObjectMetadata}
					/>
				</Else>
			</If>
			<Else>
				<ObjectAccessOverviewPopover
					sharedObjectMetadata={sharedObjectMetadata}
					memberSharings={memberSharings}
					isLoadingMemberSharings={isLoadingMemberSharings}
					teamSharings={teamSharings}
					isLoadingTeamSharings={isLoadingTeamSharings}
					onShareeSelect={onSelect}
					onAddMoreSharees={onClickAddMore}
				/>
			</Else>
		</If>
	</>;
};

import { SearchBar } from './SearchBar';
import { Button, Else, If } from '@plecto/ui';
import { ShareePermissionList } from './ShareePermissionList';
import { ShareeType, shareeTypes } from '../types/ShareeType';
import React, { useEffect, useState } from 'react';
import { EmployeeSharingModel } from '../models/EmployeeSharingModel';
import { SharingModel } from '../models/SharingModel';
import { StyledParagraph, StyledScrollableContainer, StyledSharings } from './ObjectAccessOverview';
import styled from 'styled-components';
import { Loader } from '@plecto/components';
import { useTranslation } from 'react-i18next';

const StyledLoaderContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 0 20px;
`;

type ObjectAccessEmployeesOverviewProps = {
	memberSharings: Array<EmployeeSharingModel>,
	isLoadingMemberSharings: boolean,
	onAddMore: (e: React.MouseEvent<HTMLElement>) => void,
	onSelectSharee: (sharings: SharingModel, shareeType: ShareeType) => void,
}

export function ObjectAccessEmployeesOverview({
	memberSharings,
	isLoadingMemberSharings,
	onAddMore,
	onSelectSharee
}: ObjectAccessEmployeesOverviewProps) {
	const [filteredMemberSharings, setFilteredMemberSharings] = useState<Array<EmployeeSharingModel>>(memberSharings);
	const { t } = useTranslation();
	const filterEmployees = (searchValue: string) => {
		const filtered = memberSharings.filter(sharing =>
			sharing.sharedWith.name.toLowerCase().includes(searchValue.toLowerCase()));

		setFilteredMemberSharings(filtered);
	};

	const onSelect = (sharings: EmployeeSharingModel) => onSelectSharee(sharings, shareeTypes.employees);

	useEffect(() => {
		setFilteredMemberSharings(memberSharings);
	}, [memberSharings]);

	return <>
		<SearchBar onChange={filterEmployees} placeholderText={t('Search employees')}>
			<Button
				size='small'
				className={'btn-block'}
				onClick={onAddMore}
				label={t('Add employee')}
				icon="plus"
			/>
		</SearchBar>
		{isLoadingMemberSharings ?
			<StyledLoaderContainer><Loader size={'sm'} /></StyledLoaderContainer> :
			<StyledScrollableContainer>
				<If condition={memberSharings.length === 0}>
					<StyledParagraph>
						{t('No employees have been added yet.')}
					</StyledParagraph>
					<Else>
						<StyledSharings>
							{filteredMemberSharings.map(sharings =>
								<ShareePermissionList
									key={sharings.sharedWith.uuid}
									sharings={sharings}
									onSelect={onSelect}
									sharedWith={sharings.sharedWith}
								/>
							)}
						</StyledSharings>
					</Else>
				</If>
			</StyledScrollableContainer>
		}
	</>;
}

import styled from 'styled-components';
import React from 'react';
import { withCssSelector } from '../shared/withCssSelector';
import { ButtonColorDefinition, ButtonColors, ButtonVariant } from './ButtonVariant';
import { ButtonSize, ButtonSizeDefinition, ButtonSizes } from './ButtonSize';
import { Icon, IconName } from '../icon';
import { basicButtonStyling, sizeDependantStyling, variantStyling, } from './buttonStyling';

const StyledButton = styled.a`
	${basicButtonStyling};

	display: inline-flex;
	text-decoration: none;
	&:active, &:focus, &:hover {
		text-decoration: none;
	}
`;

const StyledButtonVariant = styled(StyledButton)<{
	$colorDefinition: ButtonColorDefinition,
	$sizeDefinition: ButtonSizeDefinition,
	$hasLabel: boolean,
	$active?: boolean,
	disabled?: boolean,
}>`
	${props => sizeDependantStyling(props.$hasLabel, props.$sizeDefinition)}
	${props => variantStyling(props.$colorDefinition, props.$active)}
`;

export type LinkButtonProps = {
	size?: ButtonSize,
	variant?: ButtonVariant,
	label?: string,
	icon?: IconName,
	active?: boolean,
	disabled?: boolean,
} & React.ComponentPropsWithoutRef<'a'>

export const LinkButton = withCssSelector(React.forwardRef<HTMLAnchorElement, LinkButtonProps>(
	function LinkButton({
		size = 'default',
		variant = 'default',
		label,
		icon,
		children,
		active,
		disabled,
		href,
		...other
	}, ref) {

		const colorDefinition = ButtonColors[variant];
		const sizeDefinition = ButtonSizes[size];

		return <StyledButtonVariant 
			ref={ref}
			$colorDefinition={colorDefinition}
			$sizeDefinition={sizeDefinition}
			$hasLabel={Boolean(label)}
			$active={active}
			disabled={disabled}
			href={disabled ? undefined : href}
			{...other}
		>
			{icon && <Icon icon={icon} />}
			{label}
			{children}
		</StyledButtonVariant>;
	}
), StyledButton.toString());

import React, { PropsWithChildren, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { SlideInProps } from './SlideIn.props';
import { withCssSelector } from '../shared/withCssSelector';
import { useMergedRefs, useOutsideClick } from '../hooks';
import { ANIMATION_DURATION_IN_MILLIS, StyledContainer, StyledContentWrapper, StyledOverlay } from './SlideIn.styled';
import { DropdownParentContextProvider } from '../dropdown';

const animationTimeout = {
	exit: ANIMATION_DURATION_IN_MILLIS,
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyClickHandler = () => {};

export const SlideIn = withCssSelector(React.forwardRef<HTMLParagraphElement, PropsWithChildren<SlideInProps>>(
	function SlideIn({
		open,
		transparentOverlay,
		onEnterDone,
		children,
		onClick,
		onOutsideClick,
		onOverlayClick,
		borderless } : PropsWithChildren<SlideInProps>, ref) {
		const overlayRef = useRef(null);
		const containerRef = useRef(null);
	
		useOutsideClick(containerRef, onOutsideClick || emptyClickHandler, open && Boolean(onOutsideClick));
	
		const containerRefMerged = useMergedRefs(containerRef, ref);
	
		return <DropdownParentContextProvider parent={containerRef.current}>
			<CSSTransition nodeRef={overlayRef} in={open} timeout={animationTimeout} onClick={onOverlayClick} appear>
				<StyledOverlay ref={overlayRef} className="slide-in-overlay" $transparent={transparentOverlay} />
			</CSSTransition>
			<CSSTransition nodeRef={containerRef} in={open} timeout={animationTimeout} appear onEntered={onEnterDone}>
				<StyledContainer
					ref={containerRefMerged}
					className={'slide-in-content'} onClick={onClick} $borderless={borderless}>
					<StyledContentWrapper className="slide-in">
						{children}
					</StyledContentWrapper>
				</StyledContainer>
			</CSSTransition>
	
		</DropdownParentContextProvider>;
	}
), StyledContainer.toString());


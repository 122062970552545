import { PopoverContentWrapper } from '../PopoverContentWrapper';
import { ObjectAccessOverview } from '../ObjectAccessOverview';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import ControlLabel from '../ControlLabel';
import { If } from '@plecto/ui';
import { SharingModalContext } from '../../context/SharingModalContext';
import { SharedObjectMetadata } from '../../types/SharedObjectMetadata';
import { ShareeType } from '../../types/ShareeType';
import { ShareeContext } from '../../context/ShareeContext';
import { Uuid } from '../../types/Uuid';
import { EmployeeSharingModel } from '../../models/EmployeeSharingModel';
import { TeamSharingModel } from '../../models/TeamSharingModel';
import { SharingModel } from '../../models/SharingModel';
import { InaccessibleUnderlyingObjectsList } from '../InaccessibleUnderlyingObjectsList';
import { SharedObjectTypes } from '../../types/sharedObjectType';
import { useTranslation } from 'react-i18next';
import { MemberSelectorField } from '../memberSelectorField/MemberSelectorField';
import { ApiContext } from '../../context/ApiContext';
import { showUnderlyingObjects } from '../../util/showUnderlyingObjects';

const StyledShareWithSection = styled.section`
	border-bottom: 1px solid #E5E7EB;
	display: flex;
	flex-direction: column;
	gap: 4px;
	padding: 20px;

	label {
		margin: 0;
	}
`;

const StyledInaccessibleUnderlyingObjectsList = styled(InaccessibleUnderlyingObjectsList)`
	margin-top: 4px;
`;

type ObjectAccessOverviewPopoverProps = {
	memberSharings: Array<EmployeeSharingModel> | [],
	isLoadingMemberSharings: boolean,
	teamSharings: Array<TeamSharingModel> | [],
	isLoadingTeamSharings: boolean,
	sharedObjectMetadata: SharedObjectMetadata,
	onShareeSelect: (sharees: SharingModel) => unknown,
	onAddMoreSharees: (shareeType: ShareeType) => unknown,
}

enum OwnerChangeState {
	ASSIGNED_OR_CHANGED,
	REMOVED,
	NO_CHANGES,
}

export function ObjectAccessOverviewPopover({
	memberSharings,
	isLoadingMemberSharings,
	teamSharings,
	isLoadingTeamSharings,
	sharedObjectMetadata,
	onShareeSelect,
	onAddMoreSharees,
}: ObjectAccessOverviewPopoverProps) {
	const api = useContext(ApiContext);

	const { objectUuid, objectType, objectName } = sharedObjectMetadata;
	// @ts-ignore
	const [updateObjectOwner] = api.useUpdateObjectOwnerMutation();
	const [selectedOwner, setSelectedOwner] = useState<Uuid | null>(null);

	const { closeModal } = useContext(SharingModalContext);
	const { onAssignSharees } = useContext(ShareeContext);
	const { t } = useTranslation();

	// @ts-ignore
	const { data: objectOwner, isSuccess } = api.useGetObjectOwnerQuery({ objectUuid, objectType });

	// @ts-ignore
	const { data: inaccessibleUnderlyingObjects } = api.useGetInaccessibleUnderlyingObjectsQuery({
		formulaUuid: objectUuid,
		uuids: [...(selectedOwner ? [selectedOwner] : [])]
	}, {
		refetchOnMountOrArgChange: true,
		skip: !selectedOwner || objectType !== SharedObjectTypes.Formula,
	});

	const [ownerChangeState, setOwnerChangeState] = useState<OwnerChangeState>(OwnerChangeState.NO_CHANGES);

	const onOwnerChange = useCallback((selection: Uuid) => {
		if (selection && selection !== selectedOwner) {
			setOwnerChangeState(OwnerChangeState.ASSIGNED_OR_CHANGED);
		} else if (!selection && selectedOwner) {
			setOwnerChangeState(OwnerChangeState.REMOVED);
		} else {
			setOwnerChangeState(OwnerChangeState.NO_CHANGES);
		}
		setSelectedOwner(selection);
	}, [selectedOwner]);

	const onAssignOwner = useCallback(() => {
		switch (ownerChangeState) {
		case OwnerChangeState.ASSIGNED_OR_CHANGED:
			updateObjectOwner({ objectUuid: objectUuid, ownerUuid: selectedOwner, objectType, objectName });
			setOwnerChangeState(OwnerChangeState.NO_CHANGES);
			break;
		case OwnerChangeState.REMOVED:
			updateObjectOwner({ objectUuid: objectUuid, ownerUuid: null, objectType, objectName });
			setOwnerChangeState(OwnerChangeState.NO_CHANGES);
			break;
		}
	}, [objectName, objectType, objectUuid, ownerChangeState, selectedOwner, updateObjectOwner]);

	const onAssignPermissions = useCallback(() => {
		onAssignOwner();
		onAssignSharees();
		closeModal();
	}, [closeModal, onAssignOwner, onAssignSharees]);

	const showInaccessibleUnderlyingObjects =
		showUnderlyingObjects({ objectType, objects: inaccessibleUnderlyingObjects });

	useEffect(() => {
		if (isSuccess && objectOwner) {
			setSelectedOwner(objectOwner.uuid);
		}
	}, [isSuccess, objectOwner]);

	// @ts-ignore
	return <PopoverContentWrapper
		title={t('Share {{objectName}}', { objectName })}
		onClose={onAssignPermissions}
	>
		<StyledShareWithSection>
			<ControlLabel>{t('Owner')}</ControlLabel>
			<MemberSelectorField
				onFormChange={onOwnerChange}
				value={selectedOwner}
				placeholder={t('Select one employee')}
				shouldIncludeOnlyMembersWithLoginAccess
				saveAndCloseOnOutsideClick
				singleSelection
			/>
			<If condition={showInaccessibleUnderlyingObjects}>
				<StyledInaccessibleUnderlyingObjectsList
					message={t('Owner needs access to all underlying objects to edit formula')}
					underlyingObjects={inaccessibleUnderlyingObjects ?? []}
				/>
			</If>
		</StyledShareWithSection>

		<ObjectAccessOverview
			memberSharings={memberSharings}
			isLoadingMemberSharings={isLoadingMemberSharings}
			teamSharings={teamSharings}
			isLoadingTeamSharings={isLoadingTeamSharings}
			onShareeSelect={onShareeSelect}
			onAddMoreSharees={onAddMoreSharees}
		/>
	</PopoverContentWrapper>;
}

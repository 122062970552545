import styled from 'styled-components';
import { FieldLabel } from './field-label';
import React from 'react';
import { withCssSelector } from '../shared/withCssSelector';
import { FormField } from './FormField';
import { ErrorBlock } from './error-block';

const StyledInlineFormField = styled.div`
	display: flex;
	flex-direction: row;
	gap: 6px;
	align-items: baseline;

	${FormField.cssSelector} {
		flex: 1 auto;
	}
	
	${FormField.cssSelector} + & {
		margin-top: 8px;
	}

	& + ${FormField.cssSelector} {
		margin-top: 20px;
	}

	& > ${FieldLabel.cssSelector} {
		white-space: nowrap;
	}
	& ${ErrorBlock.cssSelector} {
		text-align: right;
	}
`;

export type InlineFormFieldProps = React.ComponentPropsWithoutRef<'div'>

export const InlineFormField = withCssSelector(React.forwardRef<HTMLDivElement, InlineFormFieldProps>(
	function InlineFormField(props, ref) {
		return <StyledInlineFormField ref={ref}{...props} />;
	}
), StyledInlineFormField.toString());

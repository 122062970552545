export const RatingPropsSize = [
	'extra-small',
	'small',
	'medium',
	'large',
] as const;

export type RatingSize = typeof RatingPropsSize[number];

export type RatingSizeDefinition = {
	iconSize: number,
	fontSize: number,
	gap: number,
	size: number,
};

export const RatingSizes: {[key in RatingSize]: RatingSizeDefinition} = {
	'extra-small': {
		iconSize: 12,
		fontSize: 10,
		gap: 5,
		size: 24,
	},
	small: {
		iconSize: 18,
		fontSize: 12,
		gap: 4,
		size: 32,
	},
	medium: {
		iconSize: 24,
		fontSize: 14,
		gap: 7,
		size: 38,
	},
	large: {
		iconSize: 30,
		fontSize: 16,
		gap: 7,
		size: 44,
	},
};

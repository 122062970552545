import styled from 'styled-components';

export const InputsRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  > * {
    flex: 0 1 50%;
  }
`;

import { useState } from 'react';

/**
 * Simple hook for storing boolean state
 * @param {*} intialState initial value of value creator
 * @returns [value, setTrue, setFalse, toggle]
 */
export const useBoolState = (initialState: boolean | (() => boolean) = false):
	[boolean, () => void, () => void, () => void] => {
	const [value, setValue] = useState(initialState);

	const setTrue = () => setValue(true);
	const setFalse = () => setValue(false);
	const toggle = () => setValue((prev) => !prev);

	return [
		value, setTrue, setFalse, toggle
	];
};

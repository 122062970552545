import React from 'react';
// @ts-ignore
import { MembersSelectorInput } from '@plecto/employee-selector';
import { PropsWithClassName } from '../../types/PropsWithClassName';
import { Uuid } from '../../types/Uuid';
import { SelectionFn, SelectionType } from '../../types/SelectionFn';

type Props = {
	disableInitiallySelectedIds?: boolean;
	disabledTooltip?: string | null;
	excluded?: Uuid[];
	onFormChange: SelectionFn;
	placeholder: string,
	saveAndCloseOnOutsideClick?: boolean;
	saveText?: string;
	value: SelectionType | null;
	shouldExcludeSelf?: boolean;
	shouldIncludeOnlyManagedMembers?: boolean;
	shouldIncludeOnlyMembersWithLoginAccess?: boolean;
	showEmail?: boolean;
	singleSelection?: boolean;
	size?: string;
	title?: string;
}

export function MemberSelectorField({
	className,
	disableInitiallySelectedIds,
	disabledTooltip = null,
	excluded = [],
	onFormChange: onSave,
	placeholder,
	saveAndCloseOnOutsideClick,
	saveText,
	value,
	shouldExcludeSelf = false,
	shouldIncludeOnlyManagedMembers = false,
	shouldIncludeOnlyMembersWithLoginAccess = false,
	showEmail = false,
	singleSelection,
	size,
	title,
}: PropsWithClassName<Props>) {

	return <MembersSelectorInput
		className={className}
		disableInitiallySelectedIds={disableInitiallySelectedIds}
		disabledTooltip={disabledTooltip}
		excluded={excluded}
		onSave={onSave}
		placeholder={placeholder}
		saveAndCloseOnOutsideClick={saveAndCloseOnOutsideClick}
		saveText={saveText}
		value={value}
		shouldExcludeSelf={shouldExcludeSelf}
		shouldIncludeOnlyManagedMembers={shouldIncludeOnlyManagedMembers}
		shouldIncludeOnlyMembersWithLoginAccess={shouldIncludeOnlyMembersWithLoginAccess}
		showEmail={showEmail}
		singleSelection={singleSelection}
		size={size}
		title={title}
	/>;
}

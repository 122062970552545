import React from 'react';
import { SharingModalContextWrapper } from './context/SharingModalContext';
import { ShareObjectModal } from './ShareObjectModal';
import { SharedObjectMetadata } from './types/SharedObjectMetadata';

type PopoverProps = {
	sharedObjectMetadata: SharedObjectMetadata,
	children: ({ openModal }: { openModal: () => void; }) => React.ReactNode
}

export function ShareObjectTrigger({ sharedObjectMetadata, children }: PopoverProps) {
	return <SharingModalContextWrapper>
		<ShareObjectModal sharedObjectMetadata={sharedObjectMetadata}>
			{children}
		</ShareObjectModal>
	</SharingModalContextWrapper>;
}

import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { RatingSizeDefinition } from './types/RatingSize';
import { PropsWithClassName } from '../tabselector/PropsWithClassName';

const StyledContainer = styled.div<{
	$active: boolean;
	$disabled: boolean;
	$sizeDefinition: RatingSizeDefinition;
}>`
	cursor: ${props => props.$active ? 'pointer' : 'default'};
	font-size: ${props => props.$sizeDefinition.iconSize}px;
	opacity: ${props => props.$disabled ? '0.6' : '1'};
`;

type RatingOptionProps = {
	sizeDefinition: RatingSizeDefinition;
	onChange: () => void;
	onHover: () => void;
	disabled: boolean;
	readOnly: boolean;
};

export function RatingOption({
	className,
	sizeDefinition,
	onChange,
	onHover,
	disabled,
	readOnly,
	children,
}: PropsWithChildren<PropsWithClassName<RatingOptionProps>>) {
	return (
		<StyledContainer
			$sizeDefinition={sizeDefinition}
			onClick={onChange}
			onMouseEnter={onHover}
			$disabled={disabled}
			$active={!readOnly && !disabled}
			className={className}
		>
			{children}
		</StyledContainer>
	);
}

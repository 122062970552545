import React from 'react';

const defaultCallback = () => {
	console.error('SlideInContext not provided');
};

export type SlideInContextType = {
	open: () => void;
	close: () => void;
};

export const SlideInContext = React.createContext<SlideInContextType>({
	open: defaultCallback,
	close: defaultCallback
});

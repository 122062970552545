import React, { PropsWithChildren, useState } from 'react';
import styled from 'styled-components';
import { Button, Input, Icon } from '@plecto/ui';
import { useTranslation } from 'react-i18next';

const EMPTY_VALUE = '';

const StyledSearchWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
	padding: 20px;
`;

export const StyledWrapper = styled.div`
	flex: 2 auto;
	position: relative;
`;

export const StyledIcon = styled(Icon)`
	position: absolute;
	padding: 5px 10px;
	pointer-events: none;
	left: 0;
	color: #9A9F9F;
	font-size: 17px;
`;

export const StyledClearSearchButton = styled(Button)`
	position: absolute;
	padding: 0;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	border: none;
	.icon {
		color: #9A9F9F;
	}
`;

const StyledInput = styled(Input)`
	&[type="text"] {
		font-size: 12px;
		padding-left: 30px;
		height: 30px;
	}
`;

const StyledChildrenWrapper = styled.div`
	flex: 1 auto;
`;

type SearchShareeFieldProps = {
	onChange: (searchValue: string) => void,
	searchValue?: string,
	placeholderText: string,
}

export const SearchBar = ({
	onChange, searchValue: initSearchValue = EMPTY_VALUE, placeholderText, children
}: PropsWithChildren<SearchShareeFieldProps>
) => {
	const [searchValue, setSearchValue] = useState<string>(initSearchValue);
	const { t } = useTranslation();
	const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		onChange(e.target.value);
		setSearchValue(e.target.value);
	};

	const clearSearch = () => {
		onChange(EMPTY_VALUE);
		setSearchValue(EMPTY_VALUE);
	};

	return <StyledSearchWrapper>
		<StyledWrapper>
			<StyledIcon icon='search' />
			<StyledInput
				type="text"
				placeholder={placeholderText || t('Search')}
				value={searchValue}
				onChange={onSearch}
			/>
			{searchValue.length > 0 && <StyledClearSearchButton
				icon={'x'}
				variant={'white'}
				size={'small'}
				onClick={clearSearch}
			/>}
		</StyledWrapper>
		<StyledChildrenWrapper>
			{children}
		</StyledChildrenWrapper>
	</StyledSearchWrapper>;
};

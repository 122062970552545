import React from 'react';
// @ts-ignore
import { AllTeamsSelectorInput, WithoutExcludedTeamsInput } from '@plecto/employee-selector';
import _isEmpty from 'lodash/isEmpty';
import { MultipleSelection, SelectionFn } from '../../types/SelectionFn';
import { Uuid } from '../../types/Uuid';

type Props = {
	onFormChange: SelectionFn,
	value: MultipleSelection;
	placeholder?: string;
	disableInitiallySelectedIds? :boolean;
	saveAndCloseOnOutsideClick? :boolean;
	excludedIds?: Uuid[];
	disabledTooltip?: string;
}

export function TeamSelectorField({
	onFormChange: onSave,
	value: selectedIds = [],
	placeholder,
	disableInitiallySelectedIds = false,
	saveAndCloseOnOutsideClick = false,
	excludedIds = [],
	disabledTooltip,
}: Props) {

	return !_isEmpty(excludedIds) ?
		<WithoutExcludedTeamsInput
			onSave={onSave}
			selectedIds={selectedIds}
			placeholder={placeholder}
			excludedIds={excludedIds}
			disableInitiallySelectedIds={disableInitiallySelectedIds}
			saveAndCloseOnOutsideClick={saveAndCloseOnOutsideClick}
			disabledTooltip={disabledTooltip}
		/> :
		<AllTeamsSelectorInput
			onSave={onSave}
			selectedIds={selectedIds}
			placeholder={placeholder}
			saveAndCloseOnOutsideClick={saveAndCloseOnOutsideClick}
		/>;
}

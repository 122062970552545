import styled from 'styled-components';

const StyledShareeInfoWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 20px;
`;

const StyledShareeInfoSection = styled.section`
	display: flex;
	gap: 8px;
`;

const StyledShareeDetailsSection = styled.section`
	display: flex;
	width: 115px;
	align-self: center;
	flex-direction: column;
	justify-content: flex-start;
`;

const StyledShareeName = styled.h4`
	color: #374151;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	margin: 0;
`;

const StyledShareeTitle = styled.p`
	color: #6B7280;
	line-height: 20px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 0;
`;

export {
	StyledShareeInfoWrapper as ShareeInfoWrapper,
	StyledShareeInfoSection as ShareeInfoSection,
	StyledShareeDetailsSection as ShareeDetailsSection,
	StyledShareeName as ShareeName,
	StyledShareeTitle as ShareeTitle,
};

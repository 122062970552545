import React, {
	useState,
	useEffect,
	useLayoutEffect,
	useRef,
	useCallback,
	PropsWithChildren,
	ReactNode,
	ReactElement,
} from 'react';

import { TabButton } from './TabButton';
import styled from 'styled-components';
import { useUniqueId } from './useUniqueId';

import { scrollElementIntoView } from './scrollUtilities';
import { useDraggable } from 'react-use-draggable-scroll';
import { PropsWithClassName } from './PropsWithClassName';

const StyledTabsContainer = styled.div`
	border-bottom: 1px solid #E2E7ED;
`;

const StyledTabButtons = styled.div`
	display: flex;
`;

const tabButtonClassName = (pref: string, id: string) => `${pref}-tab-button-${id}`;

type DivProps = JSX.IntrinsicElements['div'];
export interface ButtonProps extends DivProps {
  icon: string;
}

type TabSelectorProps = PropsWithChildren<PropsWithClassName<{
	selectedTab?: string,
	autoScroll?: boolean,
	scrollable?: boolean,
	onTabChange?: (id: string) => void,
	children: ReactElement<ButtonProps>[];
}>>

export function TabSelector({
	className,
	selectedTab,
	autoScroll = true,
	scrollable = true,
	onTabChange,
	children
}: TabSelectorProps) {
	const selectorRef = useRef() as React.MutableRefObject<HTMLInputElement>;

	const elements = React.Children.toArray(children).filter(child => React.isValidElement(child)) as ReactElement[];

	const selectorId = useUniqueId('tab-selector-');
	const tabIds: string[] = elements.map(({ props }) => props.id);

	const [tab, setTab] = useState(selectedTab || tabIds[0]);
	const normalizedTab = tabIds.indexOf(tab) >= 0 ? tab : tabIds[0];
	const { events } = useDraggable(selectorRef);

	const canScroll: boolean = Boolean(tab) && autoScroll && scrollable;

	useEffect(() => {
		if (selectedTab) {
			setTab(selectedTab);
		}
	}, [selectedTab]);

	useLayoutEffect(() => {
		if (canScroll) {
			scrollElementIntoView(`.${tabButtonClassName(selectorId, tab)}`);
		}
	}, [selectorId, canScroll, tab]);

	const onSelect = useCallback((id: string) => {
		setTab(id);
		if (onTabChange)
			onTabChange(id);
	}, [onTabChange]);

	let currentTab: ReactNode | null = null;

	const buttons = elements.map((element) => {
		const {
			id,
			['data-disabled']: disabled,
			['data-name']: label,
			['data-icon']: icon,
			['data-chip']: chip,
			children
		} = element.props;
		let selected = id === normalizedTab;

		if (!normalizedTab && !currentTab) {
			selected = true;
		}
		if (selected) {
			currentTab = children;
		}
		return <TabButton
			key={id}
			id={id}
			icon={icon}
			chip={chip}
			className={tabButtonClassName(selectorId, id)}
			label={label}
			disabled={disabled}
			selected={selected}
			onSelect={onSelect} />;
	});

	return <>
		<StyledTabsContainer className={className}>
			<StyledTabButtons ref={selectorRef} className={'tab-buttons-container'} {...(scrollable ? events : {})}>
				{buttons}
			</StyledTabButtons>
		</StyledTabsContainer>
		<div>
			{currentTab}
		</div>
	</>;
}

const primary = {
	25: '#F5F9FF',
	50: '#E6EAF0',
	100: '#B8C4D5',
	200: '#8B9EB7',
	300: '#627897',
	400: '#3A5374',
	500: '#152E50',
	600: '#0D2648',
	700: '#061E3E',
	800: '#021733',
};

const neutral = {
	white: '#FFFFFF',
	50: '#F4F6F9',
	100: '#E2E7ED',
	200: '#D0D7E1',
	300: '#BFC8D3',
	400: '#AFB8C6',
	500: '#9FA9B7',
	600: '#8C97A7',
	700: '#798595',
};

const green = {
	25: '#E6FFF2',
	50: '#CCFFE5',
	75: '#A3FFCF',
	100: '#7AFFBA',
	200: '#52F8A5',
	300: '#34EA8F',
	400: '#1DD67A',
	500: '#13A75E',
	600: '#0B7741',
	700: '#054425',
};

const blue = {
	25: '#E3F2FF',
	50: '#CCEAFF',
	75: '#A3D8FF',
	100: '#7AC7FF',
	200: '#5AB7FF',
	300: '#42A6FF',
	400: '#2F95FF',
	500: '#2072C4',
	600: '#144F88',
	700: '#0A2D4D',
};

const red = {
	25: '#FFEEEF',
	50: '#FFD4D7',
	75: '#FFBCBF',
	100: '#FFA5AB',
	200: '#FD848C',
	300: '#F8656F',
	400: '#F14853',
	500: '#D53B45',
	600: '#B82F38',
	700: '#9A252C',
};

const orange = {
	25: '#FFF0E5',
	50: '#FFE2CC',
	75: '#FFCCA3',
	100: '#FFB781',
	200: '#FFA565',
	300: '#FF934E',
	400: '#FF833D',
	500: '#C4642B',
	600: '#88451C',
	700: '#4D260E',
};

export { primary, neutral, green, blue, red, orange };

import _debounce from 'lodash/debounce';
import { useEffect, useMemo, useState } from 'react';

export const useDebouncedInputState = (initialValue = '', delay = 200) : [
	string, 
	string,
	(e: React.ChangeEvent<HTMLInputElement>) => void,
	(newValue: string) => void
] => {
	const [value, setValue] = useState(initialValue);
	const [debouncedValue, setDebouncedValue] = useState(initialValue);

	const debouncedChange = useMemo(() => _debounce(setDebouncedValue, delay), [delay]);

	useEffect(() => {
		return debouncedChange.cancel;
	}, [debouncedChange]);

	const forceChange = (newValue: string) => {
		debouncedChange.cancel();
		setValue(newValue);
		setDebouncedValue(newValue);
	};

	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value;
		if (!newValue) {
			forceChange('');
		} else {
			setValue(newValue);
			debouncedChange(newValue);
		}
	};

	return [
		value, 
		debouncedValue,
		onInputChange,
		forceChange
	];
};

import React, { useCallback } from 'react';
import _compact from 'lodash/compact';
import classNames from 'classnames';
import { Tooltip } from '@plecto/components';

export default function RadiosFieldSet({ children, selectedOption, onSelect, className }) {
	let currentPane = null;
	const buttons = _compact(
		React.Children.map(children, (element) => {
			if (!React.isValidElement(element)) return null;
			const { label, option, isDisabled, isHorizontal, disabledTooltip } =
				element.props;
			const selected = selectedOption === option;
			if (selected) {
				currentPane = element;
			}
			return (
				<RadiosFieldOption
					label={label}
					selectedOption={selected}
					option={option}
					onSelect={onSelect}
					isDisabled={isDisabled}
					disabledTooltip={disabledTooltip}
					isHorizontal={isHorizontal}
				/>
			);
		})
	);

	return (
		<>
			<div className={classNames('radios-inline-buttons', className)}>{buttons}</div>
			<div className="radios-inline-pane">{currentPane}</div>
		</>
	);
}

export function RadiosFieldPane({ children }) {
	if (!React.isValidElement(children)) return null;
	return children;
}

function RadiosFieldOption({
	label,
	selectedOption,
	option,
	onSelect,
	isDisabled,
	isHorizontal = true,
	disabledTooltip,
}) {
	const onChangeCallback = useCallback(() => {
		onSelect(option);
	}, [onSelect, option]);

	const radioOption = (
		<div
			className={classNames('radio', {
				disabled: isDisabled,
				'radio-inline': isHorizontal,
			})}
		>
			<label
				className="control-label"
				style={{ width: isHorizontal ? '' : '100%' }}
				disabled={isDisabled}
			>
				<input
					type="radio"
					checked={selectedOption}
					onChange={onChangeCallback}
					disabled={isDisabled}
				/>
				{label}
			</label>
		</div>
	);

	if (isDisabled && disabledTooltip) {
		return <Tooltip message={disabledTooltip}>{radioOption}</Tooltip>;
	}
	return radioOption;
}

import React, { useContext, useState } from 'react';
import { Button } from '@plecto/ui';
import { CheckboxesSection } from '../checkboxes/CheckboxesSection';
import { Permissions } from '../../types/permissions';
import { PopoverContentWrapper } from '../PopoverContentWrapper';
import { SharedObjectMetadata } from '../../types/SharedObjectMetadata';
import { SharingModalContext } from '../../context/SharingModalContext';
import { UserInfoSection } from '../UserInfoSection';
import { onArrayStateChange } from '../../util/onArrayStateChange';
import { EmployeeSharingModel } from '../../models/EmployeeSharingModel';
import { ShareeInfoWrapper } from '../../components/ShareeInfoSection';
import { useTranslation } from 'react-i18next';
import { ApiContext } from '../../context/ApiContext';

type ModifyEmployeePermissionsPopoverProps = {
	shareeToBeModified: EmployeeSharingModel,
	clearShareeToBeModified: () => void,
	sharedObjectMetadata: SharedObjectMetadata,
}

export function ModifyEmployeePermissionsPopover({
	clearShareeToBeModified,
	sharedObjectMetadata,
	shareeToBeModified,
}: ModifyEmployeePermissionsPopoverProps
) {
	const api = useContext(ApiContext);
	const { closeModal } = useContext(SharingModalContext);
	const { t } = useTranslation();

	const { uuid: sharingUuid, sharedWith, objectPermissions } = shareeToBeModified;
	const [selectedPermissions, setSelectedPermissions] = useState<Permissions[]>(objectPermissions);
	const onSelectedPermissionsChange = onArrayStateChange<Permissions>(selectedPermissions, setSelectedPermissions);
	const { objectUuid, objectType } = sharedObjectMetadata;
	// @ts-ignore
	const [updateMembersPermissions] = api.useUpdateMembersPermissionsMutation();
	// @ts-ignore
	const [deletePermissions] = api.useDeleteShareePermissionsMutation();

	const onSaveChangesToPermissions = () => {
		updateMembersPermissions({
			sharingUuid,
			...sharedObjectMetadata,
			permissions: selectedPermissions,
			memberIds: [sharedWith.uuid],
		});

		closeModal();
	};

	const onRemoveAccess = () => {
		deletePermissions({ sharingUuid, ...sharedObjectMetadata });

		closeModal();
	};

	return <PopoverContentWrapper
		title={t('Edit {{employeeName}}\'s access', { employeeName: sharedWith.name })}
		onClose={onSaveChangesToPermissions}
		onBackClick={clearShareeToBeModified}
	>
		<ShareeInfoWrapper>
			<UserInfoSection sharee={sharedWith} />
			<Button
				variant='default-danger'
				size='small'
				onClick={onRemoveAccess}
				label={t('Remove access')}
			/>
		</ShareeInfoWrapper>
		<CheckboxesSection
			onChange={onSelectedPermissionsChange}
			selectedPermissions={selectedPermissions}
			objectType={objectType}
			objectUuid={objectUuid}
			selectedIds={[sharedWith.uuid]}
		/>
	</PopoverContentWrapper>;
}

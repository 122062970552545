import { ApiObjectOwner } from '../types/ApiSharing';
import { ObjectOwner } from '../types/ObjectOwner';

export const transformOwnerToModel = ({ owner }: ApiObjectOwner): ObjectOwner | null => {
	if (owner === null) {
		return null;
	}

	return {
		uuid: owner.uuid,
		name: owner.name,
		title: owner.title,
		avatarUrl: owner.avatar_url,
		url: owner.url
	};
};

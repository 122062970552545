import { ReactNode, createContext, useContext } from 'react';
import { Orientation, Size } from './Stepper.props';

export interface StepperContextType {
  activeStep: number;
  alternativeLabel: boolean;
  connector: ReactNode;
  orientation: Orientation;
  size: Size;
}

const StepperContext = createContext<StepperContextType>({
	activeStep: 0,
	alternativeLabel: false,
	connector: undefined,
	orientation: 'horizontal',
	size: 'sm'
});

export function useStepperContext(): StepperContextType {
	return useContext(StepperContext);
}

export { StepperContext };

import styled from 'styled-components';
import React from 'react';
import { withCssSelector } from '../shared/withCssSelector';
import { PropsWithClassName } from '../tabselector/PropsWithClassName';
import { CardOption } from './CardOption';

const StyledSelector = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
`;

type CardOption = {
	value: string;
	label: string;
	graphic?: React.ReactNode;
}

export type CardSelectorProps = {
	options: CardOption[];
	selectedId?: string;
	onSelected: (value: string) => void;
}

export const CardSelector = withCssSelector(React.forwardRef<HTMLDivElement, PropsWithClassName<CardSelectorProps>>(
	function CardSelector({
		className,
		options,
		selectedId,
		onSelected
	}, ref) {
		return <StyledSelector 
			ref={ref}
			className={className}
		>
			{options.map(option => <CardOption
				key={option.value}
				{...option}
				selected={option.value === selectedId}
				onSelected={onSelected} />
			)}
		</StyledSelector>;
	}
), StyledSelector.toString());

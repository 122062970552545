import {
	AvatarKind,
	CharacterAnimation,
	ClothesColor,
	FacialFeature,
	HairColor,
	HairType,
	SkinTone,
	SpaceColor,
} from './types';

const TSHIRT_PREFIX = 'T-shirt/t_shirt_';
const SKIN_COLOR_PREFIX = 'Leather/leather_';
const HAIR_LONG_PREFIX = 'Hair_long/hair_long_';
const HAIR_MEDIUM_PREFIX = 'Hair_medium/hair_medium_';
const HAIR_SHORT_PREFIX = 'Hair_short/hair_short_';
const BEARD_PREFIX = 'Beard/beard_';
const MUSTACHE_PREFIX = 'Mustache/mustache_';
const MOUSTACHE_PREFIX = 'Mustache/moustache_';
const SHORTS_PREFIX = 'Shorts/shorts_';
const ROCKET_COLOR_PREFIX = 'rocket_';

export const getTShirtSkinName = (shirtColor: ClothesColor) => {
	return `${TSHIRT_PREFIX}${shirtColor}`;
};

export const getSkinTone = (skinTone: SkinTone) => {
	return `${SKIN_COLOR_PREFIX}${skinTone}`;
};

export const getHairSkin = (hair?: HairType, hairColorOrNull?: HairColor) => {
	const hairColor = hairColorOrNull ?? 'dark';
	switch (hair) {
	case 'short':
		return `${HAIR_SHORT_PREFIX}${hairColor}`;
	case 'medium':
		return `${HAIR_MEDIUM_PREFIX}${hairColor}`;
	case 'long':
		return `${HAIR_LONG_PREFIX}${hairColor}`;
	case 'stubble':
	default:
		return 'stubble_hair';
	}
};

export const getFacialFeatureSkin = (
	facialFeature?: FacialFeature,
	color?: HairColor
) => {
	const featureColor = color ?? 'dark';
	switch (facialFeature) {
	case 'freckles':
		return 'freckles';
	case 'beard':
		return `${BEARD_PREFIX}${featureColor}`;
	case 'moustache':
		switch (featureColor) {
		case 'blue':
		case 'pink':
		case 'violet':
			return `${MOUSTACHE_PREFIX}${featureColor}`;
		}

		return `${MUSTACHE_PREFIX}${featureColor}`;
	default:
		return 'default';
	}
};

export const getShortsSkin = (shortsColor: ClothesColor) => {
	return `${SHORTS_PREFIX}${shortsColor}`;
};

export const getShipSkin = (shipColor: SpaceColor) => {
	return `${ROCKET_COLOR_PREFIX}${shipColor}`;
};

export const mapAnimationToName = (
	kind: AvatarKind,
	animation: CharacterAnimation
) => {
	switch (kind) {
	case 'astronaut':
		return animation;
	case 'human':
		switch (animation) {
		case 'walk':
			return 'Walking _up_(fix)';
		case 'climb':
			return 'Climb';
		}
	}
};

import { ReactNode, createContext, useContext } from 'react';

export interface StepContextType {
  index: number;
  last: boolean;
  icon: ReactNode;
  active: boolean;
  completed: boolean;
  disabled: boolean;
}

const StepContext = createContext<StepContextType>({
	index: 0,
	last: false,
	icon: undefined,
	active: false,
	completed: false,
	disabled: false
});

export function useStepContext(): StepContextType {
	return useContext(StepContext);
}

export { StepContext };

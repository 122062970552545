import React, { CSSProperties, forwardRef, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { DropdownOption } from './types';
import { MenuItem } from './MenuItem';
import _groupBy from 'lodash/groupBy';
import { GroupItem } from './GroupItem';
import { useMergedRefs } from '../hooks';
import { scrollParentToChild } from './scrollParentToChild';

const noGroup = '__ungrouped__';

const MenuRoot = styled.div`
	border-radius: 2px;
	box-shadow: ${props => props.theme.form.dropdown.menu.shadow};
	z-index: 45;
`;

const MenuOverflow = styled.div`
	font-family: "Red Hat Display", sans-serif;
	box-sizing: border-box;
	background: #FFF;
	display: flex;
	flex-direction: column;
	color: ${props => props.theme.form.dropdown.chevron.color};
	max-height: 300px;
	overflow: auto;
`;

type MenuProps<T extends React.Key> = {
	value?: T | undefined;
	popperStyles: CSSProperties;
	popperAttributes: { [key: string]: string } | undefined;
	options: DropdownOption<T>[];
	noOptionsMessage?: string;
	onSelect?: (id: T) => void;
};

const MenuComponent = <T extends React.Key>({
	value,
	popperStyles,
	popperAttributes,
	onSelect,
	options,
	noOptionsMessage = 'No options',
} : MenuProps<T>, menuRef: React.ForwardedRef<HTMLDivElement>) => {

	const innerRef = React.useRef<HTMLDivElement>(null);

	const optionsByGroup = _groupBy(options, option => option.group || noGroup);
	
	const renderOption = (option: DropdownOption<T>) => <MenuItem 
		key={option.value.toString()}
		value={option.value.toString()}
		icon={option.icon}
		label={option.label}
		onSelect={option.onSelect || (() => onSelect?.(option.value))}
		selected={option.value === value}
		disabled={option.disabled}
	/>;

	const unGroupedOptions = optionsByGroup[noGroup] || [];

	useLayoutEffect(() => {
		if (value && innerRef.current) {
			const parent = innerRef.current.children[0] as HTMLElement;
			const element = innerRef.current.querySelector(`[data-value="${value}"]`) as HTMLElement;
			if (element) {
				scrollParentToChild(parent, element);
			}
		}
	}, [options, value]);

	const menuRefMerged = useMergedRefs<HTMLElement>(menuRef, innerRef);

	return (
		<MenuRoot
			ref={menuRefMerged}
			style={popperStyles}
			{...popperAttributes}>
			<MenuOverflow>
				{unGroupedOptions.length === 0 ? <MenuItem label={noOptionsMessage} disabled /> :
					<>
						{unGroupedOptions.map(renderOption)}
						{Object.entries(optionsByGroup).filter(([group]) => group !== noGroup)
							.map(([group, options]) => (
								<React.Fragment key={group}>
									<GroupItem label={group} />
									{options.map(renderOption)}
								</React.Fragment>
							))}
					</>
				}
			</MenuOverflow>
		</MenuRoot>
	);
};

export const Menu = forwardRef(MenuComponent) as <T extends React.Key>(
	props: MenuProps<T> & { ref?: React.ForwardedRef<HTMLUListElement> }
  ) => ReturnType<typeof MenuComponent>;

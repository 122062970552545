export const toggleSizes = [
	'default',
	'big',
] as const;

export type ToggleSize = typeof toggleSizes[number];

export type ToggleSizeDefinition = {
	width: number;
	height: number,
	borderRadius: number,
	innerRadius: number,
	padding: number,
	borderSize: number
};

export const ToggleSizes: {[key in ToggleSize]: ToggleSizeDefinition} = {
	default: {
		width: 35,
		height: 20,
		borderRadius: 10,
		innerRadius: 15,
		padding: 2.5,
		borderSize: 0.833333
	},
	big: {
		width: 56,
		height: 32,
		borderRadius: 16,
		innerRadius: 24,
		padding: 4,
		borderSize: 0.833333
	},
};

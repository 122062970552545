import styled from 'styled-components';
import { Icon } from '../icon';

const StyledTitlePane = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px;
	font-family: ${props => props.theme.font};
`;

const StyledTitle = styled.span`
	font-size: ${props => props.theme.slideIn.title.fontSize}px;
	color: ${props => props.theme.slideIn.title.color};
	font-weight: ${props => props.theme.slideIn.title.fontWeight};
`;

const StyledIcon = styled(Icon)`
	color: ${props => props.theme.slideIn.closeIcon.color};
	cursor: pointer;
	height: 24px;
	width: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	transition: color 0.2s ease-in-out;
	&:hover {
		color: ${props => props.theme.slideIn.closeIcon.hover};
	}
`;

export function Title({ 
	title,
	headerAction,
	onClose } : {
	title: string;
	headerAction?: React.ReactNode,
	onClose: () => void;
}) {
	return <StyledTitlePane>
		<StyledTitle>
			{title}
		</StyledTitle>
		{headerAction ? headerAction : <StyledIcon icon='x' onClick={onClose} />}
		
	</StyledTitlePane>;
}

import React, { useContext } from 'react';
import ControlLabel from './ControlLabel';
import RadiosFieldSet, { RadiosFieldPane } from './RadiosFieldSet';
import { TeamSelectorField } from './memberSelectorField/TeamSelectorField';
import { MemberSelectorField } from './memberSelectorField/MemberSelectorField';
import styled from 'styled-components';
import { shareeTypes } from '../types/ShareeType';
import { ShareeContext } from '../context/ShareeContext';
import { EmployeeSharingModel } from '../models/EmployeeSharingModel';
import { TeamSharingModel } from '../models/TeamSharingModel';
import { useTranslation } from 'react-i18next';
import { MultipleSelection } from '../types/SelectionFn';

const StyledRadiosFieldSet = styled(RadiosFieldSet)`
	display: flex;
	gap: 12px;
	padding-bottom: 8px;
	.radio-inline {
		margin: 0;
		padding: 0;
	}
`;

type ObjectAccessShareeSwitcherProps = {
	memberSharings: Array<EmployeeSharingModel>;
	teamSharings: Array<TeamSharingModel>;
	onEmployeesChange: (selection: MultipleSelection) => void;
	selectedEmployees?: MultipleSelection;
	onTeamsChange: (selection: MultipleSelection) => void;
	selectedTeams?: MultipleSelection;
}

export function ObjectAccessShareeSwitcher({
	memberSharings,
	teamSharings,
	onEmployeesChange,
	selectedEmployees = [],
	onTeamsChange,
	selectedTeams = [],
}: ObjectAccessShareeSwitcherProps) {
	const { onSelectSharee, selectedShareeType } = useContext(ShareeContext);
	const { t } = useTranslation();

	return <section>
		<ControlLabel>{t('Select employees')}</ControlLabel>
		{/* @ts-ignore */}
		<StyledRadiosFieldSet
			onSelect={onSelectSharee}
			selectedOption={selectedShareeType}
		>
			<RadiosFieldPane
				// @ts-ignore
				label={t('Employees')}
				option={shareeTypes.employees}
			>
				<MemberSelectorField
					onFormChange={onEmployeesChange}
					value={selectedEmployees}
					placeholder={t('Select employee(s)')}
					shouldIncludeOnlyMembersWithLoginAccess
					disableInitiallySelectedIds
					saveAndCloseOnOutsideClick
					excluded={memberSharings.map(({ sharedWith }) => sharedWith.uuid)}
					disabledTooltip={t('Already shared with this employee')}
				/>
			</RadiosFieldPane>
			<RadiosFieldPane
				// @ts-ignore
				label={t('Teams')}
				option={shareeTypes.teams}
			>
				<TeamSelectorField
					onFormChange={onTeamsChange}
					value={selectedTeams}
					placeholder={t('Select team(s)')}
					disableInitiallySelectedIds
					saveAndCloseOnOutsideClick
					excludedIds={teamSharings.map(({ sharedWithTeam }) => sharedWithTeam.uuid)}
					disabledTooltip={t('Already shared with this team')}
				/>
			</RadiosFieldPane>
		</StyledRadiosFieldSet>
	</section>;
}

import { t } from '../setupTranslations';
import { SharedObjectType, SharedObjectTypes } from '../types/sharedObjectType';

export function getLabelForSharedObjectType(objectType: SharedObjectType): string {
	switch (objectType) {
	case SharedObjectTypes.Formula:
		return t('Formula');
	case SharedObjectTypes.DataSource:
		return t('Data source');
	default:
		return '';
	}
}

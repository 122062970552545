import styled, { css } from 'styled-components';
import { StepIndicatorOwnerState } from './StepIndicator.props';

export const STEP_INDICATOR_SIZE = 28;

const StepIndicatorRoot = styled.div<StepIndicatorOwnerState>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: ${STEP_INDICATOR_SIZE}px;
  height: ${STEP_INDICATOR_SIZE}px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  flex-shrink: 0;
  text-decoration: none;
  ${({ theme, ownerState }) => css`
    background-color: ${ownerState.completed || ownerState.active ?
		theme.stepper.stepIndicator.primary.background : theme.stepper.stepIndicator.neutral.background};
    color: ${ownerState.completed || ownerState.active ?
		theme.stepper.stepIndicator.primary.color : theme.stepper.stepIndicator.neutral.color};
  `}
`;

export { StepIndicatorRoot };


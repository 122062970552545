import { useMemo } from 'react';
import styled from 'styled-components';
import _range from 'lodash/range';

import { CommonRatingProps, CustomRatingOption, optionStates } from '../CustomRating';
import { RatingSizeDefinition, RatingSizes } from '../types/RatingSize';
import { RatingOption } from '../RatingOption';
import { RatingWithGap } from '../RatingContainer';

const StyledNumber = styled(RatingOption)<{
	$hovered: boolean;
	$color?: string;
	$highlightColor?: string;
	$highlighted: boolean;
	$selectionColor?: string;
	sizeDefinition: RatingSizeDefinition;
}>`
	height: ${props => props.sizeDefinition.size}px;
	width: ${props => props.sizeDefinition.size}px;
	display: flex;
	font-size: ${props => props.sizeDefinition.fontSize}px;
	justify-content: center;
	align-items: center;
	background-color: ${props => props.$highlighted ?
		(props.$hovered ? props.$highlightColor || '#B4D1F9' : props.$selectionColor || '#126BDD') 
		: props.$color || '#F6F6F7'};
	border-radius: 3px;
	color: ${props => props.$highlighted ?
		(props.$hovered ? '#0F1E38' : '#FFFFFF') : props.$color || '#0F1E38'};
`;

type NumberRatingProps = {
	startFromZero?: boolean;
	max?: number;
	selectionColor?: string;
	color?: string;
	hoverColor?: string;
	highlightColor?: string;
} & CommonRatingProps;

export const NumberRating = ({
	startFromZero = false,
	selectionColor,
	color,
	highlightColor,
	max = 5,
	size = 'medium',
	...props
}: NumberRatingProps) => {
	const { disabled = false, readOnly = false } = props;
	const sizeDefinition = RatingSizes[size];

	const options: CustomRatingOption[] = useMemo(() => {
		return _range(startFromZero ? 0 : 1, max + 1).map(value => ({
			value,
			render: ({ state, onChange, onHover, hovered }) => {
				return <StyledNumber
					sizeDefinition={sizeDefinition}
					key={value}
					$color={color}
					$hovered={hovered}
					$selectionColor={selectionColor}
					$highlightColor={highlightColor}
					$highlighted={state === optionStates.HIGHLIGHTED}
					onChange={onChange}
					onHover={onHover}
					readOnly={readOnly}
					disabled={disabled}
				>{value}</StyledNumber>;
			}
		}));
	}, [startFromZero, max, sizeDefinition, color, selectionColor, highlightColor, readOnly, disabled]);
	
	return (
		<RatingWithGap
			$sizeDefinition={sizeDefinition}
			{...props}
			options={options}
		/>
	);
};

import styled from 'styled-components';
const MAX_INITIALS_TO_SHOW = 3;

export type InitialsProps = {
	name: string;
}

const StyledAvatarBackground = styled.div`
	font-family: ${props => props.theme.font};
	font-size: 10px;
	width: 20px;
	height: 20px;
	text-transform: uppercase;
	color: ${props => props.theme.initials.color};
	background: ${props => props.theme.initials.background};
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Initials = ({ name }: InitialsProps) => <StyledAvatarBackground>{initials(name)}</StyledAvatarBackground>;

export function initials(name: string) {
	const names = typeof name === 'string' && name.split(' ') || [];
	const chars = names.map((name) => `${name.charAt(0).toUpperCase()}`);
	return chars.length > 2 ? chars.slice(0, MAX_INITIALS_TO_SHOW).join('') : chars.join('');
}

import { css, FlattenSimpleInterpolation } from 'styled-components';

export const labelVariants = <const> [
	'neutral',
	'informational',
	'success',
	'warning',
	'critical',
	'secondary',
];

export type LabelVariant = typeof labelVariants[number];

export const LabelVariantCssDefinition: { [key in LabelVariant]: FlattenSimpleInterpolation } = {
	neutral: css`
		background-color: #E5E7EB;
		color: #374151;
	`,
	informational: css`
		background-color: #CCEAFF;
		color: #144F88;
	`,
	success: css`
		background-color: #61E2A1;
		color: #010D17;
	`,
	warning: css`
		background-color: #FFC453;
		color: #010D17;
	`,
	critical: css`
		background-color: #F87171;
		color: #FFFFFF;
	`,
	secondary: css`
		background-color: #1D3152;
		color: #FFFFFF;
	`
};

import styled from 'styled-components';
import React from 'react';
import { withCssSelector } from '../shared/withCssSelector';
import { LabelVariant, LabelVariantCssDefinition } from './LabelVariant';

const StyledSpan = styled.span<{
	$variant: LabelVariant;
}>`
	height: 20px;
	padding: 2px 8px;
	border-radius: 20px;
	width: fit-content;

	text-align: center;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;

	${props => LabelVariantCssDefinition[props.$variant]}
`;

export type LabelProps = {
	variant?: LabelVariant | undefined
} & React.ComponentPropsWithoutRef<'span'>

export const Label = withCssSelector(React.forwardRef<HTMLSpanElement, LabelProps>(
	function Label({ variant = 'neutral', ...props }, ref) {
		return <StyledSpan $variant={variant} {...props} ref={ref} />;
	}
), StyledSpan.toString());

import styled, { css } from 'styled-components';
import { Icon, IconName } from '../icon';
import { primary } from '../themes/colors';

const ItemLabel = styled.span<{
	$disabled?: boolean,
}>`
	flex: 1;
	font-size: 14px;
	color: ${({ $disabled, theme }) => $disabled ? theme.form.dropdown.menu.disabled.color : theme.form.label.color};
`;

const StyledMenuItem = styled.div<{
	$selected?: boolean,
	$disabled?: boolean,
}>`
	padding: 8px 15px;
	display: flex;
	align-items: center;
	gap: 8px;
	font-weight: 400;
	
	${Icon.cssSelector} {
		flex: 0 1 auto;
		width: 24px;
		height: 24px;
		font-size: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: ${primary['600']};
	}

	${({ $disabled }) => !$disabled && css`
		cursor: pointer;
		&:hover {
			background: ${props => props.theme.form.dropdown.menu.hover};
		}
	`};

	${({ $selected, theme }) => $selected && css`
		background: ${theme.form.dropdown.menu.selected};
	`};
`;

export function MenuItem({
	icon,
	label,
	selected,
	onSelect,
	disabled,
	value,
}: {
	icon?: IconName,
	label: string,
	selected?: boolean,
	onSelect?: () => void,
	disabled?: boolean,
	value?: string
}) {
	const onClick = () => !disabled && onSelect?.();
	return <StyledMenuItem onClick={onClick} $selected={selected} $disabled={disabled} data-value={value}>
		{icon && <Icon icon={icon} />}
		<ItemLabel $disabled={disabled}>{label}</ItemLabel>
	</StyledMenuItem>;
}

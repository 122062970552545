import React from 'react';
import { Avatar } from '@plecto/ui';
import { TeamModel } from '../models/TeamModel';
import { ShareeDetailsSection, ShareeInfoSection, ShareeName } from '../components/ShareeInfoSection';

type ShareeInfoProps = {
	sharee: TeamModel;
}

export function TeamInfoSection({ sharee }: ShareeInfoProps) {
	const { avatarUrl, uuid, name } = sharee;

	return (
		<ShareeInfoSection>
			<Avatar url={avatarUrl} size={40} />
			<ShareeDetailsSection>
				<a href={`/team/${uuid}`} target="_blank" rel="noreferrer"><ShareeName>{name}</ShareeName></a>
			</ShareeDetailsSection>
		</ShareeInfoSection>
	);
}

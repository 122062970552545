import { createContext, PropsWithChildren } from 'react';

export const DialogParentContext = createContext<{
	parent: HTMLElement | null;
}>({ parent: null });

export const DialogParentContextProvider = ({ parent, children }: PropsWithChildren<{ 
	parent: HTMLElement| null, 
}>) => {
	return <DialogParentContext.Provider value={{ parent }}>{children}</DialogParentContext.Provider>;
};

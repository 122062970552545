import styled from 'styled-components';
import { FormField } from './FormField';

export const FormFieldsRow = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;

	& + & {
		margin-top: 20px;
	}

	& + &,
	& + ${FormField.cssSelector},
	${FormField.cssSelector} + & {
		margin-top: 20px;
	}

	> ${FormField.cssSelector} {
		flex: 0 1 50%;
		margin-top: 0;
	}
`;

import * as PIXI from 'pixi.js-legacy';
import 'pixi-spine';
import { Spine, SpineParser } from 'pixi-spine';
import { Skin } from '@pixi-spine/runtime-3.8';
import { ISkin } from 'pixi-spine';
import _compact from 'lodash/compact';
import { AstronautAvatar, HumanAvatar, SpaceColor } from '../types';
import { getFacialFeatureSkin,
	getHairSkin, getShipSkin, getShortsSkin, getSkinTone, getTShirtSkinName } from '../mapper';
import { Position, PositionWithScale } from './types';
import { ANIMATION_MIX_DURATION } from './constants';

if (PIXI.Loader._plugins.indexOf(SpineParser) === -1) {
	PIXI.Loader.registerPlugin(SpineParser);
}
export const CHARACTER_JSON_URL = 'https://files.static.plecto.com/animations/avatar_2905/Plecto_character.json';
export const ROCKET_JSON_URL = 'https://files.static.plecto.com/animations/rocket_2705/Vehicle.json';
export const ASTRONAUT_JSON_URL = 'https://files.static.plecto.com/animations/astronaut_2705/Plecto_astronaut.json';

export const createPixiApp = ({
	width,
	height,
	onComplete,
	backgroundAlpha,
	backgroundColor,
	autoStart = true,
	resolution = 1
}: {
	resolution?: number;
	autoStart?: boolean;
	width?: number;
	height?: number;
	onComplete?: () => void;
	backgroundColor?: string;
	backgroundAlpha?: number;
}) => {
	const app = new PIXI.Application({
		antialias: true,
		backgroundAlpha: backgroundAlpha || 0,
		backgroundColor: parseInt(backgroundColor || '0x000000'),
		resolution,
		width,
		height,
		autoStart,
	});

	PIXI.Ticker.shared.stop();
	PIXI.Ticker.system.stop();

	app.loader.reset();
	app.loader.add('animatedAvatar', CHARACTER_JSON_URL);
	app.loader.add('astronaut', ASTRONAUT_JSON_URL);
	app.loader.add('rocket', ROCKET_JSON_URL);

	app.loader.load(onComplete);

	return app;
};

export const createHumanSpine = (app: PIXI.Application, avatarProps: HumanAvatar | AstronautAvatar,
	position: PositionWithScale) => {
	// @ts-ignore
	const spineModel = new Spine(app.loader.resources.animatedAvatar.spineData);
	spineModel.stateData.defaultMix = ANIMATION_MIX_DURATION;
	
	setUpSkin(spineModel, avatarProps as HumanAvatar);
	spineModel.position.set(position.x, position.y);
	spineModel.scale.set((position.flip ? -1 : 1) * position.scale, position.scale);
	spineModel.mask = null;
	return spineModel;
};

export const updateHumanSpine = (spineModel: Spine, 
	avatarProps: HumanAvatar | AstronautAvatar) => {
	setUpSkin(spineModel, avatarProps as HumanAvatar);
};

export const updateSpinePosition = (spineModel: Spine, position: Position) => {
	spineModel.position.set(position.x, position.y);
};

const setUpSkin = (spineModel: Spine, avatarProps: HumanAvatar) => {
	const skins: (ISkin | null)[] = [];
	skins.push(spineModel.spineData.findSkin(getTShirtSkinName(avatarProps.tshirt)));
	skins.push(spineModel.spineData.findSkin(getSkinTone(avatarProps.skin)));
	skins.push(spineModel.spineData.findSkin(getHairSkin(avatarProps.hair.type, avatarProps.hair.color)));
	skins.push(spineModel.spineData.findSkin(getFacialFeatureSkin(avatarProps.face.feature,
		avatarProps.face.featureColor)));
	skins.push(spineModel.spineData.findSkin(getShortsSkin(avatarProps.shorts)));

	const newSkin = new Skin('combined-skin');
	for (const skin of _compact(skins)) {
		newSkin.addSkin(skin);
	}

	spineModel.skeleton.setSkin(newSkin);
	spineModel.skeleton.setSlotsToSetupPose();
};

export const createAstronautSpine = (app: PIXI.Application, astronautProps: HumanAvatar | AstronautAvatar,
	position: PositionWithScale) => {
	// @ts-ignore
	const astronautModel = new Spine(app.loader.resources.astronaut.spineData);
	astronautModel.stateData.defaultMix = ANIMATION_MIX_DURATION;

	astronautModel.skeleton.setSkinByName((astronautProps as AstronautAvatar).suitColor);
	astronautModel.skeleton.setSlotsToSetupPose();

	astronautModel.position.set(position.x, position.y);
	astronautModel.scale.set((position.flip ? -1 : 1) * position.scale, position.scale);
	astronautModel.mask = null;

	return astronautModel;
};

export const updateAstronautSpine = (spineModel: Spine, astronautProps: HumanAvatar | AstronautAvatar) => {
	spineModel.skeleton.setSkinByName((astronautProps as AstronautAvatar).suitColor);
	spineModel.skeleton.setSlotsToSetupPose();
};

export const createRocketSpine = (pixiApp: PIXI.Application, pilotModel: Spine, position: PositionWithScale,
	shipColor: SpaceColor) => {
	// @ts-ignore
	const rocketModel = new Spine(pixiApp.loader.resources.rocket.spineData);

	const characterPosition = { x: 350, y: -75 };
	const maskRectSize = { width: 1000, height: 300 };

	if (rocketModel !== undefined) {
		pixiApp.stage.addChild(rocketModel);
		rocketModel.slotContainers[1].addChild(pilotModel);
		rocketModel.position.set(position.x, position.y);
		rocketModel.scale.set(position.scale);

		rocketModel.skeleton.setSkinByName(getShipSkin(shipColor));
		rocketModel.skeleton.setSlotsToSetupPose();
	}

	if (pilotModel !== undefined) {
		pilotModel.position.set(characterPosition.x, characterPosition.y); //set to fit the rocket
		pilotModel.scale.x = -1.7;
		pilotModel.scale.y = -1.7;
		const mask = new PIXI.Graphics();
		mask.beginFill(0x000000);
		mask.drawRect(0, 0, maskRectSize.width, maskRectSize.height);
		pilotModel.mask = mask;
		pilotModel.state.setEmptyAnimation(0, 1.0);
		pilotModel.skeleton.setSlotsToSetupPose();

	}

	return rocketModel;
};

import styled, { css } from 'styled-components';

// should match @transitions-duration prop in livestats slide-in.less
export const ANIMATION_DURATION_IN_MILLIS = 500;
const SLIDE_IN_WIDTH_IN_PX = 400;
const ENTER_DONE_OPACITY = .5;

const zIndexSlideIn = 1070;
const zIndexAboveAll = 2147483646;

export const StyledOverlay = styled.div<{
	$transparent?: boolean,
}>`
	position: fixed;
	top: 0;
	left: 0;
	height: 0;
	width: 0;
	z-index: ${zIndexSlideIn};
	background-color: ${props => props.theme.slideIn.overlay};
	transition: opacity ${ANIMATION_DURATION_IN_MILLIS}ms;
	opacity: 0;

	&.enter-done, &.exit, &.exit-active {
		height: 100%;
		width: 100%;
	}

	&.enter-done {
		opacity: ${props => props.$transparent ? 0 : ENTER_DONE_OPACITY};
	}`;

export const StyledContainer = styled.div<{
	$borderless?: boolean,
}>`
	min-height: 100vh;
	background-color: ${props => props.theme.slideIn.background};
	position: fixed;
	right: -${SLIDE_IN_WIDTH_IN_PX}px;
	z-index: ${zIndexAboveAll};
	transition: right ${ANIMATION_DURATION_IN_MILLIS}ms, box-shadow ${ANIMATION_DURATION_IN_MILLIS}ms;
	${props => !props.$borderless && css`border: 1px solid ${props.theme.slideIn.border};`};

	@media (max-width: ${SLIDE_IN_WIDTH_IN_PX}px) {
		right: -100vw;
	}

	&.enter-done {
		right: 0;

		-webkit-box-shadow: -5px 0 5px 0 rgba(0, 0, 0, 0.2);
		-moz-box-shadow: -5px 0 5px 0 rgba(0, 0, 0, 0.2);
		box-shadow: -5px 0 5px 0 rgba(0, 0, 0, 0.2);
		@media (max-width: ${SLIDE_IN_WIDTH_IN_PX}px) {
			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;
		}
	}`;

export const StyledContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100vh;

	width: ${SLIDE_IN_WIDTH_IN_PX}px;
	@media (max-width: ${SLIDE_IN_WIDTH_IN_PX}px) {
		width: 100vw;
	}
`;

import React, { PropsWithChildren, useMemo } from 'react';
import { SharedObjectMetadata } from '../types/SharedObjectMetadata';

type DataInputObjectContextWrapperProps = {
	sharedObjectMetadata: SharedObjectMetadata | null,
}

type ObjectDataContextValue = {
	sharedObjectMetadata: SharedObjectMetadata | null,
}

export const ObjectDataContext = React.createContext<ObjectDataContextValue>({
	sharedObjectMetadata: null,
});

export function ObjectDataContextWrapper({
	sharedObjectMetadata,
	children
}: PropsWithChildren<DataInputObjectContextWrapperProps>) {

	const contextValue: ObjectDataContextValue = useMemo(() => ({
		sharedObjectMetadata,
	}), [sharedObjectMetadata]);

	return <ObjectDataContext.Provider value={contextValue}>{children}</ObjectDataContext.Provider>;
}

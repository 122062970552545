import { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';

import {
	CommonRatingProps,
	CustomRating,
	CustomRatingOption,
	optionStates,
	RatingOptionRenderProps
} from '../CustomRating';
import { Icon, IconName } from '../../icon';
import { RatingOption } from '../RatingOption';
import { RatingVariant, RatingVariantCssDefinition } from '../types/RatingVariant';
import { RatingSizes } from '../types/RatingSize';

const StyledEmoji = styled(RatingOption)<{
	$active: boolean;
	$variant: RatingVariant;
	$highlighted: boolean;
}>`
  position: relative;
  border-width: 1px;
  border-style: solid;
  padding: 3px 10px;
  margin-left: -1px;
  border-color: #c8c8cc;
  color: ${({ $variant }) => RatingVariantCssDefinition[$variant].color};
  &:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  ${({ $variant, $highlighted, $active }) =>
		$highlighted && $variant && $active ? css`
            z-index: 1;
            border-color: ${RatingVariantCssDefinition[$variant].borderColor};
            background-color: ${RatingVariantCssDefinition[$variant].backgroundColor};
          ` : ''}
  &:hover {
    ${({ $variant, $active }) =>
		$variant && $active ? css`
              z-index: 2;
              border-color: ${RatingVariantCssDefinition[$variant].borderColor};
              background-color: ${RatingVariantCssDefinition[$variant].backgroundColor};
            ` : ''}
  }
`;

type EmojiRatingProps = CommonRatingProps;

type EmojiRenderOptionProps = {
	icon: IconName,
	variant: RatingVariant;
} & RatingOptionRenderProps

export const EmojiRating = ({
	size = 'medium',
	...props
}: EmojiRatingProps) => {

	const sizeDefinition = RatingSizes[size];
	const { disabled = false, readOnly = false } = props;
	const active = !readOnly && !disabled;

	const OptionContainer = useCallback(({ state, onChange, onHover, icon, variant }: EmojiRenderOptionProps) =>
		<StyledEmoji
			sizeDefinition={sizeDefinition}
			$highlighted={state === optionStates.HIGHLIGHTED}
			onChange={onChange}
			onHover={onHover}
			readOnly={readOnly}
			disabled={disabled}
			$variant={variant}
			$active={active}
		>
			<Icon icon={icon} />
		</StyledEmoji>, [active, disabled, readOnly, sizeDefinition]);

	const options: CustomRatingOption[] = useMemo(() => {
		return [
			{
				value: 1,
				render: (props) => <OptionContainer
					icon={'face-frown-regular'}
					variant={'danger'}
					{...props}
				/>
			},
			{
				value: 2,
				render: (props) => <OptionContainer
					icon={'face-frown-open-regular'}
					variant={'warning'}
					{...props}
				/>
			},
			{
				value: 3,
				render: (props) => <OptionContainer
					icon={'face-meh-regular'}
					variant={'neutral'}
					{...props}
				/>
			},
			{
				value: 4,
				render: (props) => <OptionContainer
					icon={'face-smile-regular'}
					variant={'success'}
					{...props}
				/>
			},
			{
				value: 5,
				render: (props) => <OptionContainer
					icon={'face-laugh-beam-regular'}
					variant={'primaryDark'}
					{...props}
				/>
			},
		];
	}, [OptionContainer]);
	
	return (
		<CustomRating
			{...props}
			highlightSelectedOnly
			options={options}
		/>
	);
};

import { Uuid } from '../types/Uuid';
import { ApiMemberWithTitle } from '../types/ApiSharing';

export type EmployeeModel = {
	uuid: Uuid;
	name: string;
	hasWriteAccessUnderlyingObjects?: boolean,
	title?: string | null;
	avatarUrl: string;
	url: string;
}

export const transformEmployeeToModel: (x: ApiMemberWithTitle) => EmployeeModel = (employee) => ({
	uuid: employee.uuid,
	name: employee.name,
	hasWriteAccessUnderlyingObjects: employee.has_access_to_underlying_objects,
	avatarUrl: employee.avatar_url,
	url: employee.url,
	title: employee.title,
});

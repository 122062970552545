import { dayIndexes } from './DateRangePicker.props';

export function getLastBusinessDayOfMonth(year?: number, month?: number) {
	const date = new Date();
	let offset = 0;
	let result = null;
    
	if (typeof year === 'undefined' || null === year) {
		year = date.getFullYear();
	}
    
	if (typeof month === 'undefined' || null === month) {
		month = date.getMonth();
	}

	do {
		result = new Date(year, month, offset);
		offset--;
	} while (result.getDay() === dayIndexes.sunday || result.getDay() === dayIndexes.saturday);

	return result;
}

import React from 'react';
import styled, { css } from 'styled-components';
import { withCssSelector } from '../../shared/withCssSelector';

const StyledTextArea = styled.textarea<{
	$disabled?: boolean | undefined;
	$hasError?: boolean | undefined;
}>`
	font-family: ${props => props.theme.font};
	accent-color: ${props => props.theme.form.input.accentColor};
	pointer-events: ${props => props.$disabled ? 'none' : 'auto'};

	background-image: none;
	border: ${props => props.theme.form.border.width}px 
		solid ${props => props.theme.form.input.border};
	${props => props.$hasError && css`
		border-color: ${props.theme.form.input.error.border};
		box-shadow: ${props.theme.form.input.error.shadow};
	`}
	border-radius: ${props => props.theme.form.border.radius}px;

	-webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	-o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;	

	${({ $disabled, theme }) => $disabled && css`
		opacity: ${theme.form.disabled.opacity};
	`}

	display: block;
	width: 100%;
	padding: 8px 10px;
	font-size: 14px;
	line-height: 1.42857143;
	color: #2d415a;
	background-color: #fff;

	:focus {
		border-color: ${props => props.theme.form.input.focus.border};
		box-shadow: ${props => props.theme.form.input.focus.shadow};
		outline: 0;
	}

	::placeholder {
		color: ${props => props.theme.form.input.placeholderColor};
	}
`;

export type TextAreaProps = {
	hasError?: boolean | undefined
} & React.ComponentPropsWithoutRef<'textarea'>

export const TextArea = withCssSelector(React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
	function TextArea(
		{
			hasError,
			disabled,
			tabIndex,
			readOnly,
			...props
		},
		ref
	) {
		return <StyledTextArea
			ref={ref}
			$hasError={hasError}
			$disabled={disabled}
			tabIndex={disabled ? -1 : tabIndex}
			readOnly={disabled ? true : readOnly}
			{...props}
		/>;
	}
), StyledTextArea.toString());

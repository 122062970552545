import { TAG_TYPES } from './setupApi';
import { shareeTypes } from '../types/ShareeType';
import {
	apiPrefix,
	mapSharedObjectResultsToModel,
	mapEmployeeSharingToModel,
	mapTeamSharingToModel,

} from './sharingApi';
import { transformOwnerToModel } from '../models/OwnerModel';

export function setupSharingEndpoints(api) {
	api.injectEndpoints({
		endpoints: (builder) => ({
			getMembersPermissions: builder.query({
				query: ({ objectUuid, objectType }) =>
					`${apiPrefix}/?object_uuid=${objectUuid}&content_type=${objectType}&scope=${shareeTypes.employees}`,
				transformResponse: mapEmployeeSharingToModel,
				providesTags: [TAG_TYPES.EMPLOYEE_SHARING]
			}),

			getTeamsPermissions: builder.query({
				query: ({ objectUuid, objectType }) =>
					`${apiPrefix}/?object_uuid=${objectUuid}&content_type=${objectType}&scope=${shareeTypes.teams}`,
				transformResponse: mapTeamSharingToModel,
				providesTags: [TAG_TYPES.TEAM_SHARING]
			}),

			createMembersPermissions: builder.mutation({
				query: ({ objectUuid, objectType, memberIds, permissions }) => ({
					url: `${apiPrefix}/`,
					method: 'POST',
					body: {
						content_type: objectType,
						object_uuid: objectUuid,
						shared_with: memberIds,
						object_permissions: permissions,
					}
				}),
				transformResponse: mapEmployeeSharingToModel,
				invalidatesTags: [TAG_TYPES.EMPLOYEE_SHARING]
			}),

			createTeamsPermissions: builder.mutation({
				query: ({ objectUuid, objectType, teamIds, permissions }) => ({
					url: `${apiPrefix}/`,
					method: 'POST',
					body: {
						content_type: objectType,
						object_uuid: objectUuid,
						shared_with_teams: teamIds,
						object_permissions: permissions,
					}
				}),
				transformResponse: mapTeamSharingToModel,
				invalidatesTags: [TAG_TYPES.TEAM_SHARING]
			}),

			updateMembersPermissions: builder.mutation({
				query: ({ sharingUuid, objectUuid, objectType, memberIds, permissions }) => ({
					url: `${apiPrefix}/${sharingUuid}/`,
					method: 'PUT',
					body: {
						uuid: sharingUuid,
						content_type: objectType,
						object_uuid: objectUuid,
						shared_with: memberIds,
						object_permissions: permissions,
					}
				}),
				transformResponse: mapEmployeeSharingToModel,
				invalidatesTags: [TAG_TYPES.EMPLOYEE_SHARING]
			}),

			updateTeamsPermissions: builder.mutation({
				query: ({ sharingUuid, objectUuid, objectType, teamIds, permissions }) => ({
					url: `${apiPrefix}/${sharingUuid}/`,
					method: 'PUT',
					body: {
						uuid: sharingUuid,
						content_type: objectType,
						object_uuid: objectUuid,
						shared_with_teams: teamIds,
						object_permissions: permissions,
					}
				}),
				transformResponse: mapTeamSharingToModel,
				invalidatesTags: [TAG_TYPES.TEAM_SHARING]
			}),

			deleteShareePermissions: builder.mutation({
				query: ({ sharingUuid }) => ({
					url: `${apiPrefix}/${sharingUuid}/`,
					method: 'DELETE',
				}),
				invalidatesTags: [TAG_TYPES.EMPLOYEE_SHARING, TAG_TYPES.TEAM_SHARING]
			}),

			getObjectOwner: builder.query({
				query: ({ objectUuid, objectType }) => `v3/ownership/${objectType}/${objectUuid}/`,
				transformResponse: transformOwnerToModel,
				providesTags: [TAG_TYPES.OWNER],
			}),

			updateObjectOwner: builder.mutation({
				query: ({ ownerUuid, objectUuid, objectType }) => ({
					url: `v3/ownership/${objectType}/${objectUuid}/`,
					method: 'PUT',
					body: {
						owner: ownerUuid
					}
				}),
				transformResponse: (response, _, arg) => {
					return {
						originalArg: arg,
						data: response,
					};
				},
				invalidatesTags: [TAG_TYPES.OWNER]
			}),

			getSharingsForUser: builder.query({
				query: ({ memberUuid }) => `v3/permissions/shared_objects/${memberUuid}/`,
				transformResponse: mapSharedObjectResultsToModel,
				providesTags: [TAG_TYPES.EMPLOYEE_SHARING]
			}),

			getSharingsForTeam: builder.query({
				query: ({ teamUuid }) => `v3/permissions/team_shared_objects/${teamUuid}/`,
				transformResponse: mapSharedObjectResultsToModel,
				providesTags: [TAG_TYPES.TEAM_SHARING]
			}),
		}),
		overrideExisting: true
	});

}

import { Children, PropsWithChildren, ReactElement, memo } from 'react';

type IfProps = {
	condition: boolean;
};

const MemoizedIf = memo(({ condition, children }: PropsWithChildren<IfProps>) => {
	const childrenArray = Children.toArray(children) as Array<ReactElement>;
	const ElseComponent = childrenArray.find((child: ReactElement) => child.type === Else);
	const remainingChildren = childrenArray.filter((child: ReactElement) => child.type !== Else);

	if (condition) {
		return remainingChildren;
	} else if (ElseComponent) {
		return ElseComponent.props.children;
	}

	return null;
});
MemoizedIf.displayName = 'If';

const MemoizedElse = memo(({ children }: PropsWithChildren<Record<never, never>>) => <>{children}</>);
MemoizedElse.displayName = 'Else';

export const If = MemoizedIf;
export const Else = MemoizedElse;

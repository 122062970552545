import { css } from 'styled-components';
import { ButtonColorDefinition as ButtonVariantDefinition } from './ButtonVariant';
import { ButtonSizeDefinition } from './ButtonSize';

export const basicButtonStyling = css`
	align-items: center;
	justify-content: center;
	border-radius: 2px;
	transition: background 0.16s, border 0.16s, color 0.16s;
	font-family: ${props => props.theme.font};

	.icon:before {
		vertical-align: baseline;
	}
`;

export const sizeDependantStyling = (hasLabel: boolean, sizeDefinition: ButtonSizeDefinition) => css`
	line-height: ${sizeDefinition.lineHeight}px;
	padding: ${hasLabel
		? `${sizeDefinition.paddingVertical}px ${sizeDefinition.paddingHorizontal}px`
		: `${sizeDefinition.paddingNoLabel}px`};
	gap: ${sizeDefinition.gap}px;
	font-size: ${sizeDefinition.fontSize}px;
	${sizeDefinition.uppercase && css`
		text-transform: uppercase;
	`}
`;

export const variantStyling = (variantDefinition: ButtonVariantDefinition, active?: boolean) => css`
	cursor: pointer;
	${!variantDefinition.border && css`
		border: 0;
	`}
	${active ?
		css`
				background: ${variantDefinition.background.active};
				color: ${variantDefinition.textColor.active};
				${variantDefinition.border && css`
					border: 1px solid ${variantDefinition.border.active};
				`}
			` :
		css`
				background: ${variantDefinition.background.default};
				color: ${variantDefinition.textColor.default};
				${variantDefinition.border && css`
					border: 1px solid ${variantDefinition.border.default};
				`}
			`}
	&:hover {
		background: ${variantDefinition.background.hover};
		color: ${variantDefinition.textColor.hover};
		${variantDefinition.border && css`
			border: 1px solid ${variantDefinition.border.hover};
		`}
	}

	&:active, &:focus {
		background: ${variantDefinition.background.active};
		color: ${variantDefinition.textColor.active};
		${variantDefinition.border && css`
			border: 1px solid ${variantDefinition.border.active};
		`}
	}

	&:disabled, &[disabled] {
		cursor: default;
		background: ${variantDefinition.background.disabled};
		color: ${variantDefinition.textColor.disabled};
		${variantDefinition.border && css`
			border: 1px solid ${variantDefinition.border.disabled};
		`}
		${!variantDefinition.background.disabledNoOpacity && css`
			opacity: 0.5;
		`}
	}
`;

import { put, takeEvery } from 'redux-saga/effects';
import { toastsActions } from '@plecto/components';
import { t } from '../setupTranslations';

const injectSharingSagas = (api) => {
	return [
		takeEvery(api.endpoints.updateObjectOwner.matchFulfilled, onObjectOwnerUpdated),
		takeEvery([
			api.endpoints.createMembersPermissions.matchFulfilled,
			api.endpoints.updateMembersPermissions.matchFulfilled,
		], onObjectPermissionMutation),
		takeEvery([
			api.endpoints.createTeamsPermissions.matchFulfilled,
			api.endpoints.updateTeamsPermissions.matchFulfilled,
		], onTeamPermissionMutation),
		takeEvery(api.endpoints.deleteShareePermissions.matchFulfilled,
			onObjectPermissionDeleted),
	];
};

function* onObjectOwnerUpdated({ meta }) {
	const { objectName, ownerUuid } = meta.arg.originalArgs;
	const message = ownerUuid ? t('Success. Assigned new owner to “{{objectName}}”', { objectName }
	) : t('Success. Owner removed from “{{objectName}}”', { objectName });

	yield put(toastsActions.showSuccess(message));
}

function* onObjectPermissionMutation({ meta }) {
	const { objectName } = meta.arg.originalArgs;
	const message = t('Success. “{{objectName}}” shared with employee(s)', { objectName });

	yield put(toastsActions.showSuccess(message));
}

function* onTeamPermissionMutation({ meta }) {
	const { objectName } = meta.arg.originalArgs;
	const message = t('Success. “{{objectName}}” shared with team(s)', { objectName });

	yield put(toastsActions.showSuccess(message));
}

function* onObjectPermissionDeleted({ meta }) {
	const { objectName } = meta.arg.originalArgs;
	const message = t('Success. Access to “{{objectName}}” revoked', { objectName });

	yield put(toastsActions.showSuccess(message));
}

export { injectSharingSagas };

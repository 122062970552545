import styled from 'styled-components';

const StyledAction = styled.div`
	padding: 4px 18px 4px 12px;
	display: flex;
	align-items: center;
`;

const StyledLabel = styled.span`
	flex: 1;
	font-size: 14px;
	color: #9CA3AF;
	text-transform: uppercase;
`;

export function GroupItem({
	label,
}: {
	label: string,
}) {
	return <StyledAction>
		<StyledLabel>{label}</StyledLabel>
	</StyledAction>;
}

import ReactModal, { OnAfterOpenCallbackOptions } from 'react-modal';
import styled from 'styled-components';

const isOverflowing = (overlayElClientHeight: number) => document.body.scrollHeight > overlayElClientHeight;

const hideBodyScrollBar = (obj?: OnAfterOpenCallbackOptions) => {
	if (obj) {
		const { overlayEl } = obj;
		const overflow = isOverflowing(overlayEl.clientHeight);
		if (overflow) {
			document.body.style.overflow = 'hidden';
			document.body.style.padding = '0 16px 0 0';
		}
	}
};
const showBodyScrollBar = () => {
	document.body.style.overflow = 'auto';
	document.body.style.padding = '0';
};

// this here is a workaround allowing for using styled-components with react-modal
const ClassNameWrapper = ({ className, ...props }: ReactModal.Props) =>
	<ReactModal
		onAfterOpen={hideBodyScrollBar}
		onAfterClose={showBodyScrollBar}
		ariaHideApp={false}
		className={className}
		{...props}
	/>;

const StyledClassNameWrapper = styled(ClassNameWrapper)`
	outline: none;
`;

const OverlayClassNameWrapper = ({ className, ...props }: ReactModal.Props) =>
	<StyledClassNameWrapper overlayClassName={className} {...props} />;

const StyledOverlayClassNameWrapper = styled(OverlayClassNameWrapper)`
	align-items: center;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	height: 100vh;
	justify-content: center;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1000;
`;

export const Modal = (props: ReactModal.Props) => <StyledOverlayClassNameWrapper {...props} />;

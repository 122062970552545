import React, { memo } from 'react';
import styled from 'styled-components';
import { UnderlyingObjectListItem } from './UnderlyingObjectListItem';
import { InaccessibleUnderlyingObjectModel } from '../models/InaccessibleUnderlyingObjectModel';

const StyledArticle = styled.article`
	align-items: flex-start;
	align-self: stretch;
	background-color: #FEF1E1;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	gap: 4px;
	padding: 8px 12px;
`;

const StyledP = styled.p`
	color: #E57E00;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	margin-bottom: 0;
`;

const StyledList = styled.ul`
	list-style: none;
	margin: 0;
	max-height: 300px;
	overflow-y: auto;
	padding: 0;
`;

type Props = {
	message: string,
	underlyingObjects: Array<InaccessibleUnderlyingObjectModel>,
	className?: string,
};

export const InaccessibleUnderlyingObjectsList = memo(({ message, underlyingObjects, className }: Props) => {
	return (
		<StyledArticle className={className}>
			<StyledP>{message}</StyledP>
			<StyledList>
				{ underlyingObjects.map((underlyingObject) =>
					<UnderlyingObjectListItem key={underlyingObject.uuid} item={underlyingObject} />
				)}
			</StyledList>
		</StyledArticle>
	);
});

InaccessibleUnderlyingObjectsList.displayName = 'InaccessibleUnderlyingObjectsList';

import React, { useCallback, useContext } from 'react';
import { Checkbox } from '../../Checkbox';
import { Permissions, PermissionTypes } from '../../../../types/permissions';
import { Uuid } from '../../../../types/Uuid';
import isEmpty from 'lodash/isEmpty';
import { If } from '@plecto/ui';
import { InaccessibleUnderlyingObjectsList } from '../../../InaccessibleUnderlyingObjectsList';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ApiContext } from '../../../../context/ApiContext';
import { showUnderlyingObjects } from '../../../../util/showUnderlyingObjects';
import { SharedObjectTypes } from '../../../../types/sharedObjectType';

const StyledInaccessibleUnderlyingObjectsList = styled(InaccessibleUnderlyingObjectsList)`
	margin-top: 4px;
`;

type Props = {
	onChange: (permission: Permissions) => void;
	isPermissionSelected: (permission: Permissions) => boolean;
	formulaUuid?: Uuid;
	selectedIds?: Array<Uuid>;
	isTeam: boolean;
};

export const FormulaCheckboxes = ({ isPermissionSelected, onChange, formulaUuid, selectedIds = [], isTeam }: Props) => {
	const api = useContext(ApiContext);

	const handleChange = useCallback((permission: Permissions) => () => onChange(permission), [onChange]);
	const { t } = useTranslation();

	// @ts-ignore
	const { data: inaccessibleUnderlyingObjects } = api.useGetInaccessibleUnderlyingObjectsQuery({
		formulaUuid: formulaUuid ?? '',
		uuids: selectedIds,
		isTeam,
	}, {
		skip: !formulaUuid || isEmpty(selectedIds) || !isPermissionSelected(PermissionTypes.write),
	});

	const showInaccessibleUnderlyingObjects = showUnderlyingObjects({
		objectType: SharedObjectTypes.Formula,
		objects: inaccessibleUnderlyingObjects
	});

	return (
		<>
			<Checkbox
				id='read'
				checked={isPermissionSelected(PermissionTypes.read)}
				onChange={handleChange(PermissionTypes.read)}
				disabled
				labelText={t('View')}
				helpText={t('Use in dashboards, reports, notifications, 1:1s')}
			/>
			<Checkbox
				id='write'
				checked={isPermissionSelected(PermissionTypes.write)}
				onChange={handleChange(PermissionTypes.write)}
				labelText={t('Edit')}
				helpText={t('Modify calculation and its settings')}
			/>
			<If condition={showInaccessibleUnderlyingObjects}>
				<StyledInaccessibleUnderlyingObjectsList
					message={t('Employees need access to all underlying objects to edit formula:')}
					underlyingObjects={inaccessibleUnderlyingObjects ?? []}
				/>
			</If>
		</>
	);
};

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import common_en from '../locale/en/common.json';
import common_da from '../locale/da/common.json';
import common_pl from '../locale/pl/common.json';
import common_es from '../locale/es/common.json';
import common_sl from '../locale/sl/common.json';
import common_sv from '../locale/sv/common.json';

i18n
	.use(initReactI18next)
	.init({
		wait: true,
		resources: {
			en: {
				common: common_en
			},
			pl: {
				common: common_pl
			},
			da: {
				common: common_da
			},
			es: {
				common: common_es
			},
			sl: {
				common: common_sl
			},
			sv: {
				common: common_sv
			},
		},
		lng: 'en',
		nsSeparator: false,
		keySeparator: false,
		defaultNS: 'common',
		returnEmptyString: false,
		// do not load a fallback
		fallbackLng: false,

		interpolation: {
			escapeValue: false
		}
	});

export const t = i18n.t.bind(i18n);
export const setLanguage = i18n.changeLanguage.bind(i18n);

import { Card } from './Card';
import {
	CardActions,
	CardBox,
	CardChip,
	CardContent,
	CardDescription,
	CardHeader,
	CardMedia,
	CardSubHeading,
	CardTitle,
} from './Card.styled';
import { ReactElement } from 'react';
import { Orientation, OrientationOptions } from './Card.props';

export type LogoCardProps = {
	title: string;
	subtitle?: string;
	description?: string;
	selected?: boolean;
	disabled?: boolean;
	chip?: ReactElement;
	actions?: ReactElement;
	logo: string;
	orientation?: Orientation;
	mediaWidth?: number;
	mediaHeight?: number;
}

export function LogoCard({
	selected,
	disabled,
	title,
	subtitle,
	description,
	chip,
	logo,
	actions,
	mediaWidth,
	mediaHeight,
	orientation = OrientationOptions.vertical
}: LogoCardProps) {
	return <Card selected={selected} disabled={disabled}>
		<CardBox $orientation={orientation} $padding={20} $gap={12}>
			<CardMedia src={logo} alt={title} $alignSelf $width={mediaWidth} $height={mediaHeight} />
			<CardContent>
				<CardHeader>
					<CardTitle>{title}</CardTitle>
					{chip && <CardChip>{chip}</CardChip>}
				</CardHeader>
				{subtitle && <CardSubHeading>{subtitle}</CardSubHeading>}
				{description && <CardDescription>{description}</CardDescription>}
				{actions ? <CardActions>{actions}</CardActions> : null}
			</CardContent>
		</CardBox>
	</Card>;
}

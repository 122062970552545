import styled, { css } from 'styled-components';
import { blue } from '../themes/colors';
import { Icon } from '../icon';

export const CustomDateRangePicker = styled.div`
  font-family: "Red Hat Display", sans-serif;
  position: relative;

  .rmdp-arrow-container {
    position: absolute;
  }

  .rmdp-header-values {
    & > span {
      position: relative;
    }
  }

  .rmdp-top-class {
    padding: 15px;
  }

  .rmdp-shadow {
    border-radius: 2px;
    border: 1px solid #D0D7E1;
    background: #FFF;
    box-shadow: 1px 2px 10px 0 rgba(121, 133, 149, 0.40);
  }

  .rmdp-month-picker, .rmdp-year-picker {
    z-index: 5;
    border: 1px solid #E2E7ED;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 2px;
  }

  .rmdp-header {
    z-index: 2;
    position: absolute;
    left: 0;
    right: 0;
  }

  /*Calendar arrows */

  .rmdp-arrow {
    border-color: #021733;
    height: 10px;
    width: 10px;
  }

  .rmdp-left {
    left: 10px;
  }

  .rmdp-right {
    right: 10px;
  }

  .rmdp-arrow-container:hover {
    box-shadow: none;
    background: none;
  }

  .rmdp-arrow-container:hover .rmdp-arrow {
    border-color: #2F95FF;
  }

  .rmdp-calendar {
    padding: 0;
    position: relative;
  }

  .rmdp-day-picker {
    padding: 0;

    & > div {
      border: 1px solid #E2E7ED;
      padding: 40px 20px 0;
    }
  }

  .rmdp-border-left {
    border: none;
  }

  .rmdp-week-day {
    color: #798595;
    font-weight: 400;
  }

  .rmdp-ep-arrow {
    display: none;
  }

  .rmdp-today:not(.rmdp-range) {
    border-radius: 50%;
  }

  .rmdp-day.rmdp-selected span:not(.highlight) {
    box-shadow: none;
    background-color: #7AC7FF;
    color: #021733;
  }

  .rmdp-range-hover,
  .rmdp-range {
    box-shadow: none;
    color: #021733;
    background-color: #E3F2FF;
  }

  .rmdp-day span {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }

  .rmdp-range {
    border-radius: 50%;
  }

  .rmdp-range-hover ~ .rmdp-range-hover,
  .rmdp-range ~ .rmdp-range {
    border-radius: 0;
  }

  .rmdp-day:not(.rmdp-disabled,.rmdp-day-hidden) span:hover,
  .rmdp-range.start,
  .rmdp-range:hover,
  .rmdp-range.end {
    & span {
      background-color: #7AC7FF;
      color: #021733;
    }
  }

  .rmdp-range.start:not(:last-child) {
    border-radius: 50% 0 0 50%;
  }

  .rmdp-range.end:not(:first-child) {
    border-radius: 0 50% 50% 0;
  }

  .rmdp-week {
    margin-bottom: 5px;

    .rmdp-range-hover:has(+ .rmdp-deactive),
    .rmdp-range:has(+ .rmdp-deactive),
    div:last-child:not(.start),
    .rmdp-range-hover.end:not(.force),
    .rmdp-range.end:not(.force) {
      border-radius: 0 50% 50% 0;
    }
    
    .rmdp-day:not(.rmdp-range) + .rmdp-range,
    .rmdp-deactive + .rmdp-range-hover,
    .rmdp-deactive + .rmdp-range,
    div:first-child {
      border-radius: 50% 0 0 50%;
    }

    .rmdp-range-hover:first-child:hover,
    .rmdp-deactive + .rmdp-range.end,
    .rmdp-range.start:has(+ .rmdp-deactive),
    .rmdp-range.start.end {
      border-radius: 50%;
    }
  }
`;

const DateRangePickerWrapper = styled.div`
  font-family: ${props => props.theme.font};
`;

const DateTimeControl = styled.div<{
    $highlight: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-family: ${props => props.theme.font};
  accent-color: ${props => props.theme.form.input.accentColor};
  cursor: pointer;

  background-image: none;
  border: ${props => props.theme.form.border.width}px solid ${props => props.theme.form.input.border};
  border-radius: ${props => props.theme.form.border.radius}px;

  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  line-height: 1.42857143;
  color: ${props => props.theme.form.input.color};
  background-color: #fff;

  &:hover {
    border-color: ${props => props.theme.form.input.focus.border};
    box-shadow: ${props => props.theme.form.input.focus.shadow};
  }

  ${({ $highlight, theme }) => $highlight && css`
    border-color: ${theme.form.input.focus.border};
    box-shadow: ${theme.form.input.focus.shadow};
  `}
  & > [type='text'],
  & > [type='time'] {
    border: none;
    cursor: pointer;

    &:focus {
      border-color: transparent;
      box-shadow: none;
    }
  }

  & > [type='text'] {
    flex: 1 auto;
    width: auto;
  }

  & > [type='time'] {
    width: auto;
    flex-shrink: 0;
    text-align: right;

    &::-webkit-calendar-picker-indicator {
      display: none;
    }
  }
`;

const ActionButton = styled.a`
  color: ${blue['400']};
  cursor: pointer;
  font-weight: 400;
  display: flex;
  gap: 8px;
  align-items: center;

  &:hover {
    color: ${blue['500']};
  }
`;

const AddNewButton = styled(ActionButton)`
  height: 40px;
`;

const FormFieldSeparator = styled.div`
  display: flex;
  align-items: flex-end;

  & > ${Icon.cssSelector} {
    display: flex;
    width: 20px;
    height: 40px;
    padding: 12px 3px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const AddEndDatePlaceholder = styled.div`
  display: flex;
  flex: 0 1 50%;
  align-items: flex-end;
`;

export {
	FormFieldSeparator,
	DateRangePickerWrapper,
	DateTimeControl,
	ActionButton,
	AddEndDatePlaceholder,
	AddNewButton,
};

import React, { useCallback, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { DropdownOption } from './types';
import { Box, StyledDropdownBox } from './Box';
import { useDropdownLogic } from './useDropdownLogic';
import { createPortal } from 'react-dom';
import { Menu } from './Menu';
import { Icon, IconName } from '../icon';
import { DropdownParentContext } from './DropdownParentContext';
import { keyCodes } from '../utils/keyCodes';
import { useDebouncedInputState, useMergedRefs } from '../hooks';
import { ComponentWithCssSelector, withCssSelector } from '../shared/withCssSelector';
import { PropsWithClassName } from '../tabselector/PropsWithClassName';

const ValueIcon = styled(Icon)`
	font-size: 14px;
	flex: 0 1;
`;

const StyledValue = styled.span`
	font-size: 14px;
	flex: 1;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`;

const StyledPlaceholder = styled.span`
	color: ${props => props.theme.form.input.placeholderColor};
	font-size: 14px;
	font-weight: 400;
	flex: 1;
`;

const StyledTextBox = styled.input`
	border: none;
	outline: none;
	flex: 1;
	font-size: 14px;
	height: 20px;
	padding: 0;
	font-weight: 400;

	&::placeholder {
		color: ${props => props.theme.form.input.placeholderColor};
		opacity: 1;
	}
`;

const StyledSearchIcon = styled(Icon)`
	flex: 0 1 auto;
	color: ${props => props.theme.form.dropdown.chevron.color};
	font-size: 16px;
	align-self: baseline;
`;

type DropdownProps<T extends React.Key> = {
	value: T | undefined,
	icon?: IconName,
	loading?: boolean,
	onClose?: () => void,
	onSelect: (id: T) => unknown,
	options: DropdownOption<T>[],
	restricted?: boolean,
	placeholder?: string,
	searchLabel?: string,
	loadingLabel?: string,
	searchable?: boolean,
	disabled?: boolean,
	hasError?: boolean,
};

function DropdownWithRef<T extends React.Key>({
	value,
	icon,
	loading,
	onClose: propsOnClose,
	onSelect,
	restricted,
	options = [],
	placeholder,
	searchLabel,
	loadingLabel,
	searchable,
	disabled,
	className,
	hasError
}: PropsWithClassName<DropdownProps<T>>, boxExternalRef: React.ForwardedRef<HTMLDivElement>) {
	const [
		searchInputValue, 
		searchPhrase,
		onSearchChange,
		forceSearchPhraseChange
	] = useDebouncedInputState();

	const onMenuClose = () => {
		forceSearchPhraseChange('');
		propsOnClose?.();
	};

	const {
		isOpen,
		styles,
		attributes,
		onClose,
		onOpen,
		buttonRef,
		menuRef,
		actualPlacement
	} = useDropdownLogic({ onMenuClose, sticky: true });

	const menuOnTop = actualPlacement === 'top-start';

	const selectedItem = value ? options.find(option => option.value === value) : null;

	const onKeyUp = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.code === keyCodes.ESC) {
			onClose();
		}
	}, [onClose]);

	const searchMode = isOpen && searchable;

	const filteredOptions = useMemo(() => searchMode && searchPhrase ? options.filter((option) => 
		option.label.toLowerCase().includes(searchPhrase.toLowerCase())) : options, 
	[options, searchMode, searchPhrase]);

	const onValueSelected = (value: T) => {
		onSelect(value);
		onClose();
	};

	const { parent } = useContext(DropdownParentContext);

	const boxRef = useMergedRefs(buttonRef, boxExternalRef);

	return <>
		<Box
			ref={boxRef} 
			loading={loading}
			isOpen={isOpen}
			onOpen={onOpen}
			onClose={onClose}
			disabled={disabled}
			isOpenOnTop={menuOnTop}
			restricted={restricted}
			className={className}
			hasError={hasError}>
			{searchMode 
				? <>
					{!selectedItem && <StyledSearchIcon icon='magnifying-glass' />}
					<StyledTextBox
						value={searchInputValue}
						onChange={onSearchChange}
						autoFocus
						type='text'
						onKeyUp={onKeyUp}
						placeholder={selectedItem?.label || searchLabel || 'Search'} /> 
				</>
				: (selectedItem ? 
					<>
						{icon && <ValueIcon icon={icon} />}
						<StyledValue>
							{selectedItem.label}
						</StyledValue>
					</> 
					: <StyledPlaceholder>
						{loading ? (loadingLabel || 'Loading') : placeholder || 'Select'}
					</StyledPlaceholder>)}
		
		</Box>
		{isOpen && createPortal(<Menu
			popperStyles={styles}
			popperAttributes={attributes}
			onSelect={onValueSelected}
			options={filteredOptions}
			ref={menuRef}
			value={value}
		/>, parent || document.body)}
	</>;
}

// eslint-disable-next-line max-len
export const Dropdown = withCssSelector(React.forwardRef(DropdownWithRef), StyledDropdownBox.toString()) as unknown as (<T extends React.Key>(
	props: PropsWithClassName<DropdownProps<T>> & { ref?: React.ForwardedRef<HTMLDivElement> }
  ) => ReturnType<typeof DropdownWithRef>) & ComponentWithCssSelector;


import React, { PropsWithChildren } from 'react';
import { StepIndicatorProps } from './StepIndicator.props';
import { useStepperContext } from '../StepperContext';
import { useStepContext } from '../Step/StepContext';
import { StepIndicatorRoot } from './StepIndicator.styled';

const StepIndicator = React.forwardRef(function StepIndicator(props: PropsWithChildren<StepIndicatorProps>, ref) {
	const { children, className, ...other } = props;

	const { alternativeLabel, orientation = 'horizontal' } = useStepperContext();
	const { active, disabled, completed } = useStepContext();

	const ownerState = { ...props, alternativeLabel, orientation, active, completed, disabled };

	return (
		<StepIndicatorRoot
			className={className}
			ref={ref as React.RefObject<HTMLDivElement>}
			ownerState={ownerState}
			{...other}
		>{children}</StepIndicatorRoot>
	);
});

export { StepIndicator };

import React, { useContext } from 'react';
import { SharingModalContext } from './context/SharingModalContext';
import { ShareObjectsPopover } from './ShareObjectsPopover';
import { Modal } from '@plecto/ui';
import { ShareeContextWrapper } from './context/ShareeContext';

export function SharingModal() {
	const { isOpen, sharedObjectMetadata, closeModal } = useContext(SharingModalContext);

	if (!sharedObjectMetadata) return null;

	return (
		<Modal isOpen={isOpen} onRequestClose={closeModal}>
			<ShareeContextWrapper sharedObjectMetadata={sharedObjectMetadata}>
				<ShareObjectsPopover sharedObjectMetadata={sharedObjectMetadata} />
			</ShareeContextWrapper>
		</Modal>
	);

}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const isDevelopmentEnvironment = process.env.NODE_ENV === 'development' || process.env.STORYBOOK_ENV === 'development';

export enum TAG_TYPES {
	EMPLOYEE_SHARING = 'EmployeeSharing',
	TEAM_SHARING = 'TeamSharing',
	OWNER = 'Owner',
}

export const api = createApi({
	reducerPath: 'api',
	baseQuery: fetchBaseQuery({
		baseUrl: isDevelopmentEnvironment ? 'http://localhost:8000/api' : '/api',
		credentials: isDevelopmentEnvironment ? 'include' : 'same-origin',
		mode: isDevelopmentEnvironment ? 'cors' : 'same-origin',
		prepareHeaders: (headers) => {
			const tokenNode = document.querySelector('meta[name="csrf-token"]');
			const csrfToken = tokenNode ? tokenNode.getAttribute('content') : '';
			headers.set('X-CSRFToken', csrfToken || '');
			return headers;
		},
	}),
	tagTypes: Object.values(TAG_TYPES),
	endpoints: () => ({}),
});

export const toggleVariants = [
	'default',
	'dark',
] as const;

export type ToggleVariant = typeof toggleVariants[number];

export type ToggleVariantDefinition = {
	shadow: boolean;
	trackColor: string;
	trackActiveColor: string;
};

export const ToggleVariants: {[key in ToggleVariant]: ToggleVariantDefinition} = {
	default: {
		shadow: true,
		trackColor: '#D0D7E1',
		trackActiveColor: '#2F95FF',
	},
	dark: {
		shadow: true,
		trackColor: '#8B9EB7',
		trackActiveColor: '#2F95FF',
	},
};

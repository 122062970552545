import { SharedObjectType } from '../types/sharedObjectType';
import { ApiInaccessibleUnderlyingObject } from '../types/ApiInaccessibleUnderlyingObject';
import { Uuid } from '../types/Uuid';

export type InaccessibleUnderlyingObjectModel = {
	uuid: Uuid,
	title: string,
	icon: string,
	url: string,
	objectType: SharedObjectType,
	hasAccess: boolean,
}

export const transformInaccessibleUnderlyingObjectToModel:
	(x: ApiInaccessibleUnderlyingObject) => InaccessibleUnderlyingObjectModel =
	(inaccessibleObject) => ({
		uuid: inaccessibleObject.uuid,
		title: inaccessibleObject.title,
		icon: inaccessibleObject.icon,
		url: inaccessibleObject.url,
		objectType: inaccessibleObject.content_type,
		hasAccess: inaccessibleObject.has_access,
	});

import React from 'react';
import styled, { css } from 'styled-components';
import { withCssSelector } from '../../shared/withCssSelector';
import { FormInputSizes, formInputSizes } from '../formInputSizes';

// eslint-disable-next-line max-len
const checkmarkUrl = 'data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'12\' height=\'8\' viewBox=\'0 0 12 8\' fill=\'none\'%3E%3Cpath d=\'M11.7489 0.234776C12.0837 0.54731 12.0837 1.05237 11.7489 1.3649L4.89134 7.7656C4.5565 8.07813 4.0154 8.07813 3.68056 7.7656L0.25105 4.56525C-0.0836833 4.25272 -0.0836833 3.74766 0.25105 3.43513C0.585836 3.12259 1.12855 3.12259 1.46339 3.43513L4.26185 6.06791L10.5381 0.234776C10.8729 -0.0782586 11.414 -0.0782586 11.7489 0.234776Z\' fill=\'white\'/%3E%3C/svg%3E';

const StyledInput = styled.input<{
	$disabled?: boolean | undefined;
	$hasError?: boolean | undefined;
	$size: FormInputSizes;
}>`
	box-sizing: border-box;
	width: 16px;
	height: 16px;
	padding: 0;
	border-radius: 2px;
	pointer-events: ${props => props.$disabled ? 'none' : 'auto'};
	border: 1px solid ${props => props.theme.form.checkbox.default.border};
	appearance: none;
	background-color: ${props => props.theme.form.checkbox.default.background};
	outline: none !important;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	${props => props.$hasError && css`
		border-color: ${props.theme.form.input.error.border};
		box-shadow: ${props.theme.form.input.error.shadow};
	`}
	${({ $disabled, theme }) => $disabled && css`
		opacity: ${theme.form.disabled.opacity};
	`}
	&:focus {
		outline: none;
		border: 1px solid ${props => props.theme.form.checkbox.focus.border};
		box-shadow: ${props => props.theme.form.checkbox.focus.shadow};
	}

	&:checked {
		border-color: ${props => props.theme.form.checkbox.checked.border};
		background: ${props => props.theme.form.checkbox.checked.background};
	}

	&:not(:disabled):checked {
		&:before {
			height: 18px;
			// eslint-disable-next-line max-len
			content: url("${checkmarkUrl}")
		}
	}
`;

export type CheckboxProps = {
	hasError?: boolean | undefined,
	size?: FormInputSizes | undefined
} & Omit<React.ComponentPropsWithoutRef<'input'>, 'type'>

export const Checkbox = withCssSelector(React.forwardRef<HTMLInputElement, CheckboxProps>(
	function Checkbox(
		{
			hasError,
			disabled,
			tabIndex,
			readOnly,
			size = formInputSizes.default,
			...props
		},
		ref
	) {
		return <StyledInput
			ref={ref}
			type={'checkbox'}
			$hasError={hasError}
			$disabled={disabled}
			$size={size}
			tabIndex={disabled ? -1 : tabIndex}
			readOnly={disabled ? true : readOnly}
			{...props}
		/>;
	}
), StyledInput.toString());

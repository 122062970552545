import { useEffect } from 'react';

// this bootstrap class prevents scrolling on body, it's useful if we have position: fixed modal
export function useBlockBodyScroll(condition?: boolean) {
	useEffect(() => {
		if (condition) {
			document.body.classList.add('modal-open');

		} else {
			document.body.classList.remove('modal-open');
		}

		return () => document.body.classList.remove('modal-open');
	}, [condition]);
}

import styled from 'styled-components';

const AvatarSizes = {
	sm: 20,
	md: 40,
} as const;

export type AvatarProps = {
	size?: typeof AvatarSizes[keyof typeof AvatarSizes];
	url: string;
}

const StyledAvatar = styled.div<AvatarProps>`
	background-size: cover;
	background-image: url(${props => props.url});
	background-position: center;
	border-radius: 50%;
	width: ${props => props.size}px;
	height: ${props => props.size}px;
`;

export const Avatar = ({ size = 20, url }: AvatarProps) => <StyledAvatar size={size} url={url} />;


import { IconName } from '../icon';
import { WidgetType, widgetTypes } from './WidgetTemplateCard.props';

export function getWidgetTypeLabel(widgetType: WidgetType) : {
	icon: IconName,
	label: string,
} {
	switch (widgetType) {
	case widgetTypes.timeline:
		return {
			icon: 'widget-timeline',
			label: 'Timeline',
		};
	case widgetTypes.speedometer:
		return {
			icon: 'widget-speedometer',
			label: 'Speedometer',
		};
	case widgetTypes.leaderboard:
		return {
			icon: 'widget-leaderboard',
			label: 'Leaderboard',
		};
	case widgetTypes.lineChart:
		return {
			icon: 'widget-line',
			label: 'Line chart',
		};
	case widgetTypes.areaChart:
		return {
			icon: 'widget-area',
			label: 'Area chart',
		};
	case widgetTypes.columnChart:
		return {
			icon: 'widget-column',
			label: 'Column chart',
		};
	case widgetTypes.pieChart:
		return {
			icon: 'widget-donut',
			label: 'Donut chart',
		};
	case widgetTypes.number:
	case widgetTypes.numberPercentage:
		return {
			icon: 'widget-number',
			label: 'Number Box',
		};
	case widgetTypes.table:
		return {
			icon: 'widget-table',
			label: 'Table chart',
		};
	default:
		throw new Error(`Unknown widget type: ${widgetType}`);
	}
}

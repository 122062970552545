export const ratingVariants = <const> [
	'neutral',
	'success',
	'info',
	'primaryDark',
	'warning',
	'danger',
];

export type RatingVariant = typeof ratingVariants[number];

export const RatingVariantCssDefinition = {
	neutral: {
		color: '#9A9F9F',
		borderColor: '#9A9F9F',
		backgroundColor: 'rgba(154, 159, 159, 0.1)',
	},
	primaryDark: {
		color: '#0D7C30',
		borderColor: '#0D7C30',
		backgroundColor: 'rgba(13, 124, 48, 0.1)',
	},
	info: {
		color: '#9A9F9F',
		borderColor: '#9A9F9F',
		backgroundColor: 'rgba(154, 159, 159, 0.1)',
	},
	success: {
		color: '#12A34D',
		borderColor: '#12A34D',
		backgroundColor: 'rgba(18, 163, 77, 0.1)',
	},
	warning: {
		color: '#F79116',
		borderColor: '#F79116',
		backgroundColor: 'rgba(247, 145, 22, 0.1)',
	},
	danger: {
		color: '#DC2626',
		borderColor: '#DC2626',
		backgroundColor: 'rgba(220, 38, 38, 0.1)',
	},
};

import React, { PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';
import { ShareeType, shareeTypes } from '../types/ShareeType';
import _noop from 'lodash/noop';
import { Uuid } from '../types/Uuid';
import _isEmpty from 'lodash/isEmpty';
import _difference from 'lodash/difference';
import { Permissions, PermissionTypes } from '../types/permissions';
import { onArrayStateChange } from '../util/onArrayStateChange';
import { SharingModalContext } from './SharingModalContext';
import { ApiContext } from './ApiContext';
import { SharedObjectMetadata } from '../types/SharedObjectMetadata';

enum ShareeStatusNames {
	CHANGED,
	NO_CHANGES
}

type ShareeContextValue = {
	selectedShareeType: ShareeType;
	onSelectSharee: (type: ShareeType) => void;
	onTeamsChange: (selection: Uuid[]) => void,
	selectedTeams: Uuid[],
	onEmployeesChange: (selection: Uuid[]) => void,
	selectedEmployees: Uuid[],
	onAssignSharees: () => void,
	selectedPermissions: Permissions[];
	onSelectedPermissionsChange: (permission: Permissions) => void,
}

export const ShareeContext = React.createContext<ShareeContextValue>({
	selectedShareeType: shareeTypes.employees,
	onSelectSharee: _noop,
	onTeamsChange: _noop,
	selectedTeams: [],
	onEmployeesChange: _noop,
	selectedEmployees: [],
	onAssignSharees: _noop,
	onSelectedPermissionsChange: _noop,
	selectedPermissions: [],
});

type ShareeContextWrapperProps = {
	sharedObjectMetadata: SharedObjectMetadata;
}

export function ShareeContextWrapper({ sharedObjectMetadata, children }: PropsWithChildren<ShareeContextWrapperProps>) {
	const api = useContext(ApiContext);
	const { selectedShareeType: initSelectedShareeType } = useContext(SharingModalContext);

	// @ts-ignore
	const [createMembersPermissions] = api.useCreateMembersPermissionsMutation();
	// @ts-ignore
	const [createTeamsPermissions] = api.useCreateTeamsPermissionsMutation();

	const [selectedPermissions, setSelectedPermissions] = useState<Permissions[]>([PermissionTypes.read]);
	const onSelectedPermissionsChange = onArrayStateChange<Permissions>(selectedPermissions, setSelectedPermissions);

	const [selectedShareeType, setShareeType] = useState<ShareeType>(initSelectedShareeType);
	const onSelectSharee = (type: ShareeType) => {
		setSelectedEmployees([]);
		setEmployeeStatus(ShareeStatusNames.NO_CHANGES);
		setSelectedTeams([]);
		setTeamStatus(ShareeStatusNames.NO_CHANGES);
		setShareeType(type);
	};

	const [selectedEmployees, setSelectedEmployees] = useState<Uuid[]>([]);
	const [
		employeeStatus,
		setEmployeeStatus
	] = useState<ShareeStatusNames>(ShareeStatusNames.NO_CHANGES);

	const [selectedTeams, setSelectedTeams] = useState<Uuid[]>([]);
	const [
		teamStatus,
		setTeamStatus
	] = useState<ShareeStatusNames>(ShareeStatusNames.NO_CHANGES);

	const onAssignSharees = useCallback(() => {
		if (employeeStatus === ShareeStatusNames.CHANGED) {
			createMembersPermissions({
				...sharedObjectMetadata,
				memberIds: selectedEmployees,
				permissions: selectedPermissions
			});
			setEmployeeStatus(ShareeStatusNames.NO_CHANGES);
		}
		if (teamStatus === ShareeStatusNames.CHANGED) {
			createTeamsPermissions({
				...sharedObjectMetadata,
				teamIds: selectedTeams,
				permissions: selectedPermissions
			});
			setTeamStatus(ShareeStatusNames.NO_CHANGES);
		}
	}, [createMembersPermissions, createTeamsPermissions, employeeStatus, selectedEmployees,
		selectedPermissions, selectedTeams, sharedObjectMetadata, teamStatus]);

	const checkIfStatusChanged = useCallback((updatedUuids: Uuid[], selectedUuids: Uuid[]) => {
		const change = _difference(updatedUuids, selectedUuids);
		return !_isEmpty(change);
	}, []);

	const onEmployeesChange = useCallback((updatedSelectedEmployees: Uuid[]) => {
		if (checkIfStatusChanged(updatedSelectedEmployees, selectedEmployees)) {
			setEmployeeStatus(ShareeStatusNames.CHANGED);
		} else {
			setEmployeeStatus(ShareeStatusNames.NO_CHANGES);
		}

		setSelectedEmployees(updatedSelectedEmployees);
	}, [checkIfStatusChanged, selectedEmployees]);

	const onTeamsChange = useCallback((updatedSelectedTeams: Uuid[]) => {
		if (checkIfStatusChanged(updatedSelectedTeams, selectedTeams)) {
			setTeamStatus(ShareeStatusNames.CHANGED);
		} else {
			setTeamStatus(ShareeStatusNames.NO_CHANGES);
		}
		setSelectedTeams(updatedSelectedTeams);
	}, [checkIfStatusChanged, setTeamStatus, setSelectedTeams, selectedTeams]);

	const contextValue: ShareeContextValue = useMemo(() => ({
		onSelectSharee,
		selectedShareeType,
		onTeamsChange,
		selectedTeams,
		onEmployeesChange,
		selectedEmployees,
		onAssignSharees,
		onSelectedPermissionsChange,
		selectedPermissions,
	}), [
		onAssignSharees, onEmployeesChange, onSelectedPermissionsChange, onTeamsChange, selectedEmployees,
		selectedPermissions, selectedShareeType, selectedTeams
	]);

	return <ShareeContext.Provider value={contextValue}>{children}</ShareeContext.Provider>;
}

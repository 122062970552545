import React from 'react';
import styled, { css } from 'styled-components';
import { withCssSelector } from '../../shared/withCssSelector';
import { FormInputSizes, formInputSizes } from '../formInputSizes';

const StyledInput = styled.input<{
	$disabled?: boolean | undefined;
	$hasError?: boolean | undefined;
	$size: FormInputSizes;
}>`
	box-sizing: border-box;
	width: 16px;
	height: 16px;
	padding: 0;
	border-radius: 50%;
	pointer-events: ${props => props.$disabled ? 'none' : 'auto'};
	border: 1px solid ${props => props.theme.form.radio.default.border};
	appearance: none;
	background-color: ${props => props.theme.form.radio.default.background};
	outline: none !important;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	${props => props.$hasError && css`
		border-color: ${props.theme.form.input.error.border};
		box-shadow: ${props.theme.form.input.error.shadow};
	`}

	${({ $disabled, theme }) => $disabled && css`
		opacity: ${theme.form.disabled.opacity};
	`}
	
	&:focus {
		outline: none;
		border: 1px solid ${props => props.theme.form.radio.focus.border};
		box-shadow: ${props => props.theme.form.radio.focus.shadow};
	}

	&:checked {
		border-color: ${props => props.theme.form.radio.checked.border};
		background: ${props => props.theme.form.radio.checked.background};
	}

	&:not(:disabled):checked {
		&:before {
			height: 8px;
			width: 8px;
			content: '';
			background-color: ${props => props.theme.form.radio.checked.border};
			border-radius: 50%;
			transform: translateX(-50%, -50%);
			left: 50%;
			top: 50%;
		}
	}
`;

export type RadioProps = {
	hasError?: boolean | undefined,
	size?: FormInputSizes | undefined
} & Omit<React.ComponentPropsWithoutRef<'input'>, 'type'>

export const Radio = withCssSelector(React.forwardRef<HTMLInputElement, RadioProps>(
	function Radio(
		{
			hasError,
			disabled,
			tabIndex,
			readOnly,
			size = formInputSizes.default,
			...props
		},
		ref
	) {
		return <StyledInput
			ref={ref}
			type={'radio'}
			$hasError={hasError}
			$disabled={disabled}
			$size={size}
			tabIndex={disabled ? -1 : tabIndex}
			readOnly={disabled ? true : readOnly}
			{...props}
		/>;
	}
), StyledInput.toString());

import { CustomCard } from './CustomCard';
import { ReactElement } from 'react';
import { WidgetTypeSelector } from './WidgetTypeSelector';
import { Integrations, Widgets } from './WidgetTemplateCard.props';
import { TemplateCardContextWrapper } from './TemplateCardContextWrapper';
import { TemplateCardActions } from './TemplateCardActions';

export type TemplateCardProps = {
    chip?: ReactElement;
    disabled?: boolean;
    integrations?: Integrations;
    limit?: number;
    onSelect: (id: string) => void;
    selected?: boolean;
    title: string;
    value: string;
    widgets: Widgets;
}

export function WidgetTemplateCard({
	chip,
	disabled,
	integrations = [],
	limit,
	onSelect,
	selected,
	title,
	value,
	widgets,
}: TemplateCardProps) {

	return <TemplateCardContextWrapper>
		<CustomCard
			chip={chip}
			selected={selected}
			disabled={disabled}
			title={title}
			Component={<WidgetTypeSelector
				onSelect={onSelect}
				widgets={widgets}
				value={value}
			/>}
			{...(integrations?.length > 0 && {
				actions: <TemplateCardActions integrations={integrations} limit={limit} />
			})}
		/>
	</TemplateCardContextWrapper>;
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */

import * as PIXI from 'pixi.js-legacy';
import { ContestAppOptions,
	Contestant, ContestantInApp, Position, PositionWithScale, ProgressInContest } from './types';
import { createPixiApp, updateSpinePosition } from './pixiUtilities';
import _clamp from 'lodash/clamp';
import { AnimationState } from 'pixi-spine';
import { AnimationScheduler } from './animationScheduler';
import { ANIMATION_SPEED, CAPTION_SIZE_RATIO, SPACING_RATIO, SCORE_SPACING_RATIO, 
	ANIMATION_MIX_DURATION, 
	FONT_SHADOW_RATIO } from './constants';
import _keyBy from 'lodash/keyBy';

export class ContestApp<ContestantType extends Contestant> {
	container: HTMLElement | undefined;
	options: ContestAppOptions<ContestantType>;
	pixiApp: PIXI.Application | undefined;
	contestants: Record<string, ContestantInApp<ContestantType>> = {};
	contestantIndex = 0;
	ongoingAnimations: Record<string, {
		ticker: PIXI.Ticker,
		lastPosition: Position,
	}> = {};

	animationScheduler: AnimationScheduler;

	constructor(options: ContestAppOptions<ContestantType>) {
		this.options = options;

		this.animationScheduler = new AnimationScheduler({ animationSpeed: options.height * ANIMATION_SPEED });
	}

	calculatePosition(progressInContest: ProgressInContest, index: number): PositionWithScale {
		const {
			positioner,
			renderScale,
			width,
			height,
			characterScale,
			numberOfContestants,
		} = this.options;

		const progress = _clamp(progressInContest.progress, 0, 100);

		const position = positioner(progress, index, numberOfContestants, width, height);
		return {
			x: position.x * renderScale,
			y: position.y * renderScale,
			scale: characterScale * height * renderScale,
			flip: index < numberOfContestants / 2,
		};
	}

	renderCaption(caption: string, position: Position,  
		positioningFunction: (caption: PIXI.Text, position: Position) => void) {
		const {
			renderScale,
		} = this.options;
		const text = new PIXI.Text(caption, {
			fontFamily: 'Red Hat Display',
			fontSize: this.options.height * CAPTION_SIZE_RATIO * renderScale,
			fontWeight: '700',
			fill: 0xffffff,
			strokeThickness: (this.options.height * CAPTION_SIZE_RATIO * renderScale) / FONT_SHADOW_RATIO
		});

		positioningFunction(text, position);

		this.pixiApp!.stage.addChild(text);
		// eslint-disable-next-line no-magic-numbers
		text.anchor.set(0.5);
		return text;
	}

	setCaptionPosition(caption: PIXI.Text, position: Position) {
		const {
			renderScale,
			height,
		} = this.options;

		caption.x = position.x;
		caption.y = position.y + (height * SPACING_RATIO) * renderScale;
	}

	setScorePosition(caption: PIXI.Text, position: Position) {
		const {
			renderScale,
			height,
		} = this.options;

		caption.x = position.x;
		caption.y = position.y - (height * SCORE_SPACING_RATIO) * renderScale;
	}

	renderScore(progressInContest: ProgressInContest, position: Position) {
		const score = progressInContest.formattedValue || `${progressInContest.progress}%`;
		return this.renderCaption(score, position, this.setScorePosition.bind(this));
	}

	renderAndStop() {
		this.pixiApp?.render();
		this.pixiApp?.ticker.stop();
	}

	addContestant(contestant: ContestantType, initialProgress: ProgressInContest, atIndex?: number) {
		const {
			renderer,
		} = this.options;

		const index = atIndex !== undefined ? atIndex : this.contestantIndex++;

		const positionScaled = this.calculatePosition(initialProgress, index);

		const spine = renderer(
			this.pixiApp!,
			contestant, 
			positionScaled);

		this.pixiApp!.stage.addChild(spine);

		const caption = this.renderCaption(contestant.name, positionScaled, this.setCaptionPosition.bind(this));
		const scoreCaption = this.renderScore(initialProgress, positionScaled);

		this.contestants[contestant.id] = {
			...contestant,
			position: positionScaled,
			progress: initialProgress,
			spine,
			index,
			nameCaption: caption,
			scoreCaption
		};
	}

	updateContestants(contestants: (ContestantType & {
		progress: ProgressInContest
	})[]) {
		const visibleContestants = _keyBy(contestants, 'id');
		const removedContestants: ContestantInApp<ContestantType>[] = [];
		for (const contestant of Object.values(this.contestants)) {
			if (!visibleContestants[contestant.id]) {
				removedContestants.push(contestant);
			}
		}
		let progressUpdated = false;
		let replacedIndex = 0;

		for (const contestant of contestants) {
			if (this.contestants[contestant.id]) {
				const contestantProgressUpdated = this.updateProgress(contestant.id, contestant.progress);

				progressUpdated = progressUpdated || contestantProgressUpdated;
			} else {
				const removedContestant = removedContestants[replacedIndex];
				this.removeContestant(removedContestant.id);
				this.addContestant(contestant, contestant.progress, removedContestant.index);
				++replacedIndex;
			}
		}

		if (!progressUpdated && removedContestants.length && !this.animationScheduler.isRunningAnyAnimations()) {
			this.renderAndStop();
		}
	}

	removeContestant(id: string) {
		const contestant = this.contestants[id];
		if (!contestant) {
			return;
		}
		this.animationScheduler.stopAnimation(id);
		contestant.spine.destroy();
		contestant.nameCaption.destroy();
		contestant.scoreCaption.destroy();

		delete this.contestants[id];
	}

	init(container: HTMLElement, onDone: () => void) {
		const pixiApp = createPixiApp({
			width: this.options.width * this.options.renderScale,
			height: this.options.height * this.options.renderScale,
			onComplete: () => {
				const canvas = pixiApp.view;
				container.innerHTML = '';
				container.appendChild(canvas);

				onDone?.();
			},
			autoStart: false,
			resolution: 1
		});

		this.container = container;

		this.pixiApp = pixiApp;
	}

	destroy() {
		this.animationScheduler.stopAllAnimations();
		for (const contestant of Object.values(this.contestants)) {
			contestant.spine.destroy();
			contestant.nameCaption.destroy();
			contestant.scoreCaption.destroy();
		}
		this.pixiApp?.destroy(true, true);

		this.pixiApp = undefined;
	}

	rerender(width: number, height: number, onDone: () => void) {
		this.destroy();
		this.options.width = width;
		this.options.height = height;
		this.contestants = {};
		this.contestantIndex = 0;
		if (this.container) {
			this.container.innerHTML = '';
		}
		this.init(this.container!, onDone);
	}

	updateContestantPosition(
		contestant: ContestantInApp<ContestantType>,
		nextPosition: Position
	) {
		const { spine, nameCaption, scoreCaption } = contestant;

		updateSpinePosition(spine, nextPosition);

		this.setCaptionPosition(nameCaption, nextPosition);

		if (scoreCaption) {
			this.setScorePosition(scoreCaption, nextPosition);
		}
	}

	updateProgress(contestantId: string, progressInContest: ProgressInContest): boolean {
		const { animation, animationProvider } = this.options;

		const contestant = this.contestants[contestantId];
		if (!contestant) {
			return false;
		}

		const { spine, position: currentPosition, progress: currentProgress, index, scoreCaption } = contestant;

		if (currentProgress.progress === progressInContest.progress) {
			return false;
		}

		if (!this.pixiApp?.ticker.started) {
			this.pixiApp?.ticker.start();
		}

		const targetPosition = this.calculatePosition(progressInContest, index);
	
		const interrupted = this.animationScheduler.scheduleAnimation(
			contestantId,
			currentPosition,
			targetPosition,
			(nextPosition, ended) => {
				this.updateContestantPosition(contestant, nextPosition);
				if (ended) {
					spine.state.setEmptyAnimation(0, ANIMATION_MIX_DURATION);
					this.onAnimationEnded();
				}
			}
		);
		if (!interrupted) {
			spine.state.setAnimationWith(0, AnimationState.emptyAnimation, true);
			setTimeout(() => {
				spine.state.setAnimation(0, animationProvider.getMoveAnimation(animation, contestant), true);
			// eslint-disable-next-line no-magic-numbers
			}, 50);
	
		}
		
		this.contestants[contestantId].position = targetPosition;
		this.contestants[contestantId].progress = progressInContest;

		scoreCaption.text = progressInContest.formattedValue || `${progressInContest.progress}%`;

		scoreCaption.updateText(true);

		return true;
	}

	onAnimationEnded() {
		if (!this.animationScheduler.isRunningAnyAnimations()) {
			setTimeout(() => {
				if (!this.animationScheduler.isRunningAnyAnimations()) {
					this.pixiApp?.ticker.stop();
				}
			// eslint-disable-next-line no-magic-numbers
			}, ANIMATION_MIX_DURATION * 1000 + 50);
		}
	}
}

import React, { ComponentPropsWithoutRef } from 'react';
import styled, { css } from 'styled-components';
import { formOpacities } from '../formOpacities';
import { withCssSelector } from '../../shared/withCssSelector';
import { Input } from '../input';
import { Checkbox } from '../checkbox';
import { Radio } from '../radio';
import { ErrorBlock } from '../error-block';

const StyledInputLabel = styled.label<{
	$disabled?: boolean | undefined;
	$hasError?: boolean | undefined;
}>`
	font-family: ${props => props.theme.font};
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: ${props => props.$hasError ? '#b21d1d' : '#374151'};
	opacity: ${props => props.$disabled ? formOpacities.disabled : formOpacities.full};
	margin: 0;
	padding-left: 23px;
	pointer-events: ${props => props.$disabled ? 'none' : 'auto'};
	position: relative;

	${Input.cssSelector}[type="checkbox"],
	${Input.cssSelector}[type="radio"] {
		margin: 1px 8px 0px -23px;
		display: block;
		position: absolute;
	}

	${Radio.cssSelector},
	${Checkbox.cssSelector} {
		margin: 1px 8px 0px -23px;
		position: absolute;
	}

	${props => props.$disabled ? '' : css`
		cursor: pointer;
	`}
	${ErrorBlock.cssSelector} {
		display: block;
	}
`;

export type InputLabelProps = {
	disabled?: boolean | undefined
	hasError?: boolean | undefined
} & ComponentPropsWithoutRef<'label'>

export const InputLabel = withCssSelector(React.forwardRef<HTMLLabelElement, InputLabelProps>(
	function InputLabel({ disabled, hasError, ...props }, ref) {
		return <StyledInputLabel ref={ref} $disabled={disabled} $hasError={hasError} {...props} />;
	}
), StyledInputLabel.toString());

/* eslint-disable no-magic-numbers */
import styled from 'styled-components';
import React from 'react';
import { withCssSelector } from '../shared/withCssSelector';
import { Input, InputProps } from '../form/input';
import { Icon } from '../icon';
import { FormInputSizes } from '../form';

const StyledContainer = styled.div`
	position: relative;
`;

const StyledInput = styled(Input)`
	&[type='search'] {
		padding-left: ${props => props.size === 'small' ? 28 : 34}px;
	}
`;

const StyledIcon = styled(Icon)<{
	$size: FormInputSizes;
}>`
	position: absolute;
	left: 10px;
	top: 50%;
	transform: translateY(-50%);
	color: ${props => props.theme.form.input.placeholderColor};
	font-size: ${props => props.$size === 'small' ? 18 : 22}px;
	align-items: center;
	display: flex;
`;

export const SearchInput = withCssSelector(React.forwardRef<HTMLInputElement, InputProps>(
	function SearchInput({ size = 'default', className, ...otherProps }, ref) {
		return <StyledContainer className={className}>
			<StyledIcon icon="search" $size={size} />
			<StyledInput placeholder='Search' {...otherProps} size={size} type="search" ref={ref}>
			</StyledInput>
		</StyledContainer>;
	}
), StyledContainer.toString());


import * as PIXI from 'pixi.js-legacy';
import { AvatarSettings, CharacterAnimation, HumanAvatar } from '../types';
import { createAstronautSpine, createHumanSpine } from './pixiUtilities';
import { Contestant, PositionWithScale } from './types';
import { mapAnimationToName } from '../mapper';

const createFunctions = {
	human: createHumanSpine,
	astronaut: createAstronautSpine
};

export type CharacterContestant = Contestant & {
	avatarSettings: AvatarSettings
};

export const characterRenderer = (
	app: PIXI.Application, 
	contestant: CharacterContestant, 
	position: PositionWithScale) => {

	const createFunc = createFunctions[contestant.avatarSettings.kind];

	return createFunc(app,
		contestant.avatarSettings[contestant.avatarSettings.kind] as HumanAvatar, position);
};

export const animationsHandler = {
	getMoveAnimation: (animation: CharacterAnimation, contestant: CharacterContestant) => {
		return mapAnimationToName(contestant.avatarSettings.kind, animation);
	}
};

export const widgetTypes = {
	timeline: 'timeline',
	speedometer: 'speedometer',
	leaderboard: 'leaderboard',
	lineChart: 'line_chart',
	areaChart: 'area_chart',
	columnChart: 'column_chart',
	pieChart: 'pie',
	number: 'number',
	numberPercentage: 'number_percentage',
	table: 'table',
	countdown: 'countdown',
	clock: 'clock',
	text: 'text',
	image: 'image',
	streamingNumber: 'streaming_number',
	streamingSpeedometer: 'streaming_speedometer',
};

export type WidgetType = typeof widgetTypes[keyof typeof widgetTypes];

export type Widgets = {
	uuid: string;
	type: WidgetType;
}[];

export type Integrations = {
	uuid: string;
	name: string;
	thumbnail: string;
}[]

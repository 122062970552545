import React, { createContext, useContext } from 'react';
import { SelectorApiProvider } from '@plecto/employee-selector';

export const ApiContext = createContext(undefined);

export function ApiContextWrapper({ children, api }) {
	const alreadySet = useContext(ApiContext);

	const context = alreadySet || api;

	return (
		<ApiContext.Provider value={context}>
			<SelectorApiProvider api={context}>
				{children}
			</SelectorApiProvider>
		</ApiContext.Provider>
	);
}

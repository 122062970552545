import { blue, neutral, primary, red } from './colors';

export const mainTheme = {
	name: 'Main',
	primaryTextColor: primary['800'],
	form: {
		label: {
			color: primary['800'],
			requiredAsteriskColor: red['400'],
			fontWeight: 500,
			fontSize: 14,
		},
		requiredAsterisk: {
			color: red['400'],
			fontWeight: 500,
		},
		error: {
			fontWeight: 400,
			fontSize: 14,
			color: red['500'],
		},
		input: {
			accentColor: blue['400'],
			border: neutral['200'],
			error: {
				border: red['400'],
				shadow: `0px 0px 0px 2px ${red['75']}`,
			},
			focus: {
				border: blue['400'],
				shadow: `0px 0px 0px 3px ${blue['50']}`,
			},
			color: primary['800'],
			placeholderColor: neutral['400'],
			size: {
				default: {
					height: 40,
					fontSize: 14,
					padding: '8px 10px',
				},
				small: {
					height: 28,
					fontSize: 14,
					padding: '4px 10px',
				}
			}
		},
		radio: {
			default: {
				border: neutral['300'],
				background: neutral['white'],
			},
			focus: {
				border: blue['300'],
				shadow: `0px 0px 0px 3px ${blue['50']}`,
			},
			checked: {
				border: blue['300'],
				background: neutral.white,
			}
		},
		checkbox: {
			default: {
				border: neutral['300'],
				background: neutral['white'],
			},
			focus: {
				border: blue['300'],
				shadow: `0px 0px 0px 3px ${blue['50']}`,
			},
			checked: {
				border: blue['300'],
				background: blue['300'],
			}
		},
		dropdown: {
			chevron: {
				color: neutral['300'],
			},
			menu: {
				shadow: '0px 6px 12px 0px rgba(0, 0, 0, 0.18)',
				selected: blue['25'],
				hover: blue['25'],
				disabled: {
					color: '#9CA3AF',
				},
			},
			restricted: {
				border: '#F79116',
			},
		},
		help: {
			color: neutral['700'],
		},
		disabled: {
			opacity: 0.5,
			background: neutral['50'],
		},
		border: {
			width: 1,
			radius: 2,
		},

		multiSelectInput: {
			itemBackground: neutral['100'],
			removeIcon: primary['100'],
			removeIconHover: primary['300'],
			itemColor: primary['400'],
		}
		
	},
	stepper: {
		stepIndicator: {
			primary: {
				background: blue['400'],
				color: neutral['white'],
			},
			neutral: {
				background: primary['50'],
				color: neutral['700'],
			}
		},
		stepLabel: {
			primary: {
				color: blue['700'],
			},
			neutral: {
				color: neutral['700'],
			},
		},
		stepButton: {
			color: blue['400'],
		}
	},
	slideIn: {
		overlay: '#000',
		title: {
			color: primary['800'],
			fontWeight: 500,
			fontSize: 18,
		},
		background: neutral['white'],
		closeIcon: {
			color: primary['200'],
			hover: primary['300'],
		},
		border: neutral['100'],
	},
	card: {
		title: primary['800'],
		subtitle: primary['200'],
		color: primary['500'],
		background: neutral.white,
		default: {
			border: neutral['200'],
		},
		selected: {
			border: blue['400'],
			boxShadow: blue['50']
		}
	},
	tabs: {
		highlight: blue['300'],
		default: {
			color: primary['200'],
			icon: primary['200'],
		},
		hover: {
			color: primary['400'],
			icon: primary['400'],
		},
		active: {
			color: primary['800'],
			icon: primary['400'],
		},
	},
	initials: {
		background: primary['200'],
		color: neutral.white,
	},
	font: '"Red Hat Display"',
};

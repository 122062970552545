/* eslint-disable no-magic-numbers */
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Loader } from '../../loader';
import { CharacterAnimation } from '../types';
import { CharacterContestant, animationsHandler, characterRenderer } from '../app/characterRenderer';
import { ContestApp } from '../app/ContestApp';
import { PositioningFunction, ProgressInContest } from '../app/types';
import _orderBy from 'lodash/orderBy';
import _isNil from 'lodash/isNil';

export type AnimatedContestWidgetProps = {
	image: string,
	animation: CharacterAnimation,
	characterScale: number,
	positioner: PositioningFunction,
	width: number,
	height: number,
	maxNumberOfContestants?: number,
	contestants: (
		CharacterContestant & {
			progress: ProgressInContest,
		}
	)[]
}

const StyledBackground = styled.div<{ 
	$image: string,
	$width: number,
	$height: number,
}>`
	width: ${props => props.$width}px;
	height: ${props => props.$height}px;
	background-image: url(${props => props.$image});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover; 
`;

const StyledContainer = styled.div<{ 
	$width: number,
	$height: number,
}>`
	width: ${props => props.$width}px;
	height: ${props => props.$height}px;

	canvas {
		width: ${props => props.$width}px;
		height: ${props => props.$height}px;
	}
`;

export const AnimatedContestWidget = ({
	image,
	width,
	height,
	animation,
	characterScale,
	positioner,
	contestants,
	maxNumberOfContestants
}: AnimatedContestWidgetProps) => {
	const canvasRef = useRef<HTMLDivElement | null>(null);
	const contestApp = useRef<ContestApp<CharacterContestant> | null>(null);
	const [loaded, setLoaded] = useState(false);

	const numberOfRenderedContestants = _isNil(maxNumberOfContestants)
		? contestants.length : Math.min(contestants.length, maxNumberOfContestants);

	const visibleContestants = useMemo(() => 
		_orderBy(contestants, ['progress.progress'], ['desc']).slice(0, numberOfRenderedContestants),
	[contestants, numberOfRenderedContestants]);

	const renderContestants = useCallback(() => {
		if (!contestApp.current) {
			return;
		}

		for (const contestant of visibleContestants) {
			contestApp.current.addContestant(contestant, contestant.progress);
		}

		contestApp.current.renderAndStop();
	}, [visibleContestants]);

	const onLoaded = useCallback(() => {
		setLoaded(true);
		renderContestants();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useLayoutEffect(() => {
		contestApp.current = new ContestApp({
			width,
			height,
			renderScale: 1,
			positioner,
			characterScale,
			animation,
			numberOfContestants: numberOfRenderedContestants,
			renderer: characterRenderer,
			animationProvider: animationsHandler
		});
		if (canvasRef.current) {
			contestApp.current.init(canvasRef.current, onLoaded);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onLoaded]);

	useEffect(() => {
		if (!contestApp.current || !loaded) {
			return;
		}

		if (Object.keys(contestApp.current.contestants).length !== numberOfRenderedContestants) {
			contestApp.current.options.numberOfContestants = numberOfRenderedContestants;
			contestApp.current.rerender(width, height, renderContestants);
		} else {
			contestApp.current.updateContestants(visibleContestants);
		}

	}, [height, loaded, renderContestants, numberOfRenderedContestants, visibleContestants, width]);

	useEffect(() => {
		if (!contestApp.current || !loaded) {
			return;
		}

		contestApp.current.rerender(width, height, renderContestants);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[width, height]);

	useEffect(() => {
		return contestApp.current?.destroy;
	}, []);

	return <StyledBackground $image={image} $width={width} $height={height}>
		<StyledContainer ref={canvasRef} $width={width} $height={height} />
		{!loaded && <Loader />}
	</StyledBackground>;

};


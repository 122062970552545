import { CustomCard } from './CustomCard';
import { ReactElement } from 'react';
import { Integrations } from './WidgetTemplateCard.props';
import { Icon } from '../icon';
import { TemplateCardContextWrapper } from './TemplateCardContextWrapper';
import { TemplateCardActions } from './TemplateCardActions';
import styled from 'styled-components';
import { neutral } from '../themes/colors';

const ComponentRoot = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${neutral['100']};
  padding-right: 12px;
  flex: 0 0 68px;

  ${Icon.cssSelector} {
    padding: 12px;
    height: 60px;
    width: 60px;
    font-size: 30px;
  }
`;

export type TemplateCardProps = {
    chip?: ReactElement;
    disabled?: boolean;
    integrations: Integrations;
    limit?: number;
    selected?: boolean;
    subtitle?: string;
    title: string;
}

export function FormulaTemplateCard({
	chip,
	disabled,
	integrations,
	limit,
	selected,
	subtitle,
	title,
}: TemplateCardProps) {

	return <TemplateCardContextWrapper>
		<CustomCard
			chip={chip}
			selected={selected}
			disabled={disabled}
			title={title}
			subtitle={subtitle}
			Component={<ComponentRoot>
				<Icon icon={'formula'} />
			</ComponentRoot>}
			{...(integrations?.length > 0 && {
				actions: <TemplateCardActions integrations={integrations} limit={limit} />
			})}
		/>
	</TemplateCardContextWrapper>;
}

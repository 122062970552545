import { useEffect } from 'react';
import { Modifier, Placement, State } from '@popperjs/core';
import { usePopperPopover } from '../hooks';

const stickyModifiers = [
	{
		name: 'offset',
		options: {
			offset: [0, 2],
		},
	},
	{
		name: 'sameWidth',
		enabled: true,
		fn: ({ state }: { state: State}) => {
			state.styles.popper.width = `${state.rects.reference.width}px`;
		},
		phase: 'beforeWrite',
		requires: ['computeStyles'],
	}
] as Modifier<string, object>[];

const standardModifiers = [
	{
		name: 'offset',
		options: {
			// eslint-disable-next-line no-magic-numbers
			offset: [0, 4],
		},
	},
	
];

export const useDropdownLogic = ({
	onMenuOpen,
	onMenuClose,
	placement,
	sticky,
} : {
	onMenuOpen?: () => unknown,
	onMenuClose?: () => unknown,
	placement?: Placement,
	sticky?: boolean,
} = {}) => {
	const {
		isOpen,
		onOpen,
		onToggle,
		onClose,
		actualPlacement,
		popperStyles,
		popperAttributes,
		buttonRef,
		popoverRef
	} = usePopperPopover({
		placement,
		modifiers: sticky ? stickyModifiers : standardModifiers
	});

	useEffect(() => {
		if (isOpen) {
			onMenuOpen?.();
		} else {
			onMenuClose?.();
		}

		return () => {
			onMenuClose?.();
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	return {
		isOpen,
		styles: popperStyles,
		attributes: popperAttributes,
		onClose,
		onOpen,
		onToggle,
		buttonRef,
		menuRef: popoverRef,
		actualPlacement
	};
};

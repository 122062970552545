import React from 'react';
import styled from 'styled-components';
import { Icon, IconName, IconProps } from '../../icon';
import { withCssSelector } from '../../shared/withCssSelector';

const StyledErrorIcon = styled(Icon)`
	line-height: 18px;
`;

export type ErrorIconProps = {
	icon?: IconName
} & Omit<IconProps, 'icon'>

export const ErrorIcon = withCssSelector(React.forwardRef<HTMLSpanElement, ErrorIconProps>(
	function ErrorIcon({ icon = 'warning', ...props }, ref) {
		return <StyledErrorIcon ref={ref} icon={icon} {...props} />;
	}
), StyledErrorIcon);

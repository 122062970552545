import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { Icon } from '../icon';
import { Loader } from '../loader';
import { PropsWithClassName } from '../tabselector/PropsWithClassName';

const StyledChevron = styled(Icon)`
	flex: 0 1 auto;
	color: ${props => props.theme.form.dropdown.chevron.color};
	margin-left: 4px;
`;

export const StyledDropdownBox = styled.div<{
	$open: boolean,
	$restricted?: boolean,
	$disabled?: boolean,
	$hasError?: boolean,
}>`
	font-family: ${props => props.theme.font};
	flex: 1;
	border: 1px solid ${props => props.theme.form.input.border};
	padding: 8px 10px;
	display: flex;
	background: #fff;
	cursor: pointer;
	gap: 8px;
	height: ${props => props.theme.form.input.size.default.height}px;
	align-items: center;
	color: ${props => props.theme.form.input.color};

	border-radius: 2px;
	overflow: hidden;

	${({ $hasError, theme }) => $hasError && css`
		border: 1px solid ${theme.form.input.error.border};
		box-shadow: ${theme.form.input.error.shadow};
	`}

	&:focus {
		border-color: ${props => props.theme.form.input.focus.border};
		box-shadow: ${props => props.theme.form.input.focus.shadow};
	}
	
	${({ $open }) => $open && css`
		border-color: ${props => props.theme.form.input.focus.border};
		box-shadow: ${props => props.theme.form.input.focus.shadow};

		${StyledChevron} {
			color: ${props => props.theme.form.input.accentColor};
		}
	`}

	${({ $restricted, theme }) => $restricted && css`
		border: 1px solid ${theme.form.dropdown.restricted.border};
	`}

	${({ $disabled, theme }) => $disabled && css`
		background: ${theme.form.disabled.background};
		opacity: ${theme.form.disabled.opacity};
		cursor: auto;
	`}
`;

const StyledLoader = styled(Loader)`
	flex: 0 1 auto;
	margin: 2px;
`;

type BoxProps = {
	loading?: boolean,
	isOpen: boolean,
	isOpenOnTop: boolean,
	restricted?: boolean,
	disabled?: boolean,
	hasError?: boolean,
	onOpen: () => void,
	onClose: () => void,
};

export const Box = React.forwardRef<HTMLDivElement, PropsWithClassName<PropsWithChildren<BoxProps>>>(({
	loading,
	isOpen,
	onOpen,
	onClose,
	restricted,
	disabled,
	hasError,
	className,
	children
}, buttonRef) => {
	
	return (
		<StyledDropdownBox
			ref={buttonRef} 
			onClick={loading || isOpen || disabled ? undefined : onOpen}
			className={className}
			$open={isOpen}
			$disabled={disabled}
			$hasError={hasError}
			$restricted={restricted}>
			{children}
			{loading 
				? <StyledLoader />
				: <StyledChevron
					icon={'dropdown-chevron'}
					onClick={isOpen ? onClose : undefined} />}
		</StyledDropdownBox>
	);
});

Box.displayName = 'DropdownBox';

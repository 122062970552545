import { PropsWithChildren, useCallback, useContext } from 'react';
import styled, { css } from 'styled-components';
import { PropsWithClassName } from '../tabselector/PropsWithClassName';
import { Modal } from '../modal';
import { DialogParentContext } from './DialogParentContext';
import { Icon } from '../icon';
import { Button } from '../button';
import { DialogProgress } from './DialogProgress';

const StyledModal = styled(Modal)<{
	$hasParent: boolean
}>`
	${({ $hasParent }) => $hasParent ? css`
	position: absolute;
	` : ''}
`;

const StyledDialog = styled.div`
	width: 360px;
	background: #fff;
	box-shadow: 2px 4px 6px 4px rgba(0, 0, 0, 0.20);
	border: 1px solid #E5E7EB;
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 8px;
	border-radius: 4px;
`;

const StyledHeader = styled.div`
	display: flex;
	gap: 12px;
`;

const StyledTitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	gap: 5px;
`;

const StyledTitle = styled.div`
	color: #374151;
	font-size: 16px;
	font-weight: 500;
`;

const StyledMessage = styled.div`
	color: #374151;
	font-size: 14px;
`;

const StyledSubTitle = styled.div`
	color: #374151;
	font-size: 14px;
	font-weight: 500;
`;

const StyledCloseIcon = styled(Icon)`
	cursor: pointer;
	color: #1F2937;
	font-size: 13px;
`;

const StyledActions = styled.div`
	display: flex;
	gap: 4px;
	padding-top: 8px;
`;

const StyledAction = styled(Button)`
	flex: 1;
	font-size: 14px;
`;

const StyledProgressContainer = styled.div`
	padding: 5px 0;
`;

export type DialogProps = {
	progress?: number,
	overhead?: string,
	title: string,
	open: boolean,
	onClose: () => unknown
};

export const Dialog = ({ 
	title,
	open,
	className,
	children,
	onClose,
	progress,
}: PropsWithChildren<PropsWithClassName<DialogProps>>) => {
	const { parent } = useContext(DialogParentContext);
	const hasParent = !!parent;
	const parentSelector = useCallback(() => {
		return parent ?? document.body;
	}, [parent]);

	const showProgress = progress || progress === 0;
	return (
		<StyledModal isOpen={open} onRequestClose={onClose} parentSelector={parentSelector} $hasParent={hasParent}>
			<StyledDialog
				className={className}
			>
				<StyledHeader>
					<StyledTitleContainer>
						{showProgress && <StyledProgressContainer>
							<DialogProgress progress={progress} />
						</StyledProgressContainer>}
						<StyledTitle>{title}</StyledTitle>
					</StyledTitleContainer>
					<StyledCloseIcon icon="x" onClick={onClose} />
				</StyledHeader>
				{children}
			</StyledDialog>
		</StyledModal>);
};

Dialog.Actions = StyledActions;
Dialog.Action = StyledAction;
Dialog.Message = StyledMessage;
Dialog.SubTitle = StyledSubTitle;

import { useMemo } from 'react';
import { DateObject, Value } from 'react-multi-date-picker';
import { Shortcut, ShortcutsList } from './Shortcuts.styled';
import { getLastBusinessDayOfMonth } from './getLastBusinessDayOfMonth';
import {
	businessHoursEnd,
	businessHoursStart,
	businessMinutesEnd,
	businessMinutesStart,
	monthIndexes
} from './DateRangePicker.props';

type DatePickerShortcutsProps = {
    onChange: (date: Value[]) => void;
}

export function Shortcuts({ onChange }: DatePickerShortcutsProps) {
	const handlePresetClick = (value: Value[]) => {
		onChange(value);
	};

	const now = new DateObject();
	const nextMonthDate = new DateObject().add('1', 'month');
	const nextYearDate = new DateObject().add('1', 'year');

	const lastBusinessDayOfMonth = getLastBusinessDayOfMonth(now.year, now.month.number);
	const lastBusinessDayOfNextMonth = getLastBusinessDayOfMonth(now.year, nextMonthDate.month.number);
	const lastBusinessDayOfYear = getLastBusinessDayOfMonth(now.year, monthIndexes.december);
	const lastBusinessDayOfNextYear = getLastBusinessDayOfMonth(nextYearDate.year, monthIndexes.december);

	const shortcuts = useMemo(() => ([
		{
			label: 'Today',
			value: [
				new DateObject(),
				new DateObject().setHour(businessHoursEnd).setMinute(businessMinutesEnd)
			]
		},
		{
			label: 'Tomorrow',
			value: [
				new DateObject().add('1', 'day')
					.setHour(businessHoursStart).setMinute(businessMinutesStart),
				new DateObject().add('1', 'day')
					.setHour(businessHoursEnd).setMinute(businessMinutesEnd)
			]
		},
		{
			label: 'This week',
			value: [
				new DateObject(),
				new DateObject().toFirstOfWeek()
					.add('5', 'day').setHour(businessHoursEnd).setMinute(businessMinutesEnd)
			]
		},
		{
			label: 'Next week',
			value: [
				new DateObject().toFirstOfWeek().add('8', 'day'),
				new DateObject().toLastOfWeek()
					.add('6', 'day').setHour(businessHoursEnd).setMinute(businessMinutesEnd)
			]
		},
		{
			label: 'This month',
			value: [
				new DateObject(),
				new DateObject(lastBusinessDayOfMonth)
			]
		},
		{
			label: 'Next month',
			value: [
				new DateObject().toFirstOfMonth().add('1', 'month'),
				new DateObject(lastBusinessDayOfNextMonth)
					.setHour(businessHoursEnd).setMinute(businessMinutesEnd)
			]
		},
		{
			label: 'This year',
			value: [
				new DateObject().toFirstOfYear().add('1', 'day'),
				new DateObject(lastBusinessDayOfYear)
					.setHour(businessHoursEnd).setMinute(businessMinutesEnd)
			]
		},
		{
			label: 'Next year',
			value: [
				new DateObject().toFirstOfYear().add('1', 'year').add('1', 'day'),
				// eslint-disable-next-line max-len
				new DateObject(lastBusinessDayOfNextYear).setHour(businessHoursEnd).setMinute(businessMinutesEnd)
			]
		},
	]), [lastBusinessDayOfMonth, lastBusinessDayOfNextMonth, lastBusinessDayOfNextYear, lastBusinessDayOfYear]);

	return <ShortcutsList>
		{shortcuts.map((shortcut) => (
			<Shortcut
				key={shortcut.label}
				onClick={() => handlePresetClick(shortcut.value)}
			>
				{shortcut.label}
			</Shortcut>
		))}
	</ShortcutsList>;

}

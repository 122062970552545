import styled from 'styled-components';
import { blue } from '../themes/colors';

export const ShortcutsList = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 20px 0 0;
  margin: 0;
  list-style: none;
  justify-content: center;
  align-items: flex-start;
`;

export const Shortcut = styled.li`
  color: ${blue['400']};
  cursor: pointer;
  font-weight: 400;
  padding: 2px 10px;
  &:hover {
    background-color: ${blue['50']};
    border-radius: 16px;
  }
`;

import { createContext, PropsWithChildren } from 'react';

export const DropdownParentContext = createContext<{
	parent: HTMLElement | null;
}>({ parent: document.body });

export const DropdownParentContextProvider = ({ parent, children }: PropsWithChildren<{ 
	parent: HTMLElement| null, 
}>) => {
	return <DropdownParentContext.Provider value={{ parent }}>{children}</DropdownParentContext.Provider>;
};

import * as PIXI from 'pixi.js-legacy';
import { Position } from './types';
import _clamp from 'lodash/clamp';

export class AnimationScheduler {
	ongoingAnimations: Record<string, OngoingAnimation> = {};
	animationSpeed: number;

	constructor({
		animationSpeed
	}: {
		animationSpeed: number
	}) {
		this.animationSpeed = animationSpeed;
	}

	isRunningAnyAnimations() {
		return Object.keys(this.ongoingAnimations).length > 0;
	}

	distanceBetweenPoints(a: Position, b: Position) {
		return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
	}

	scheduleAnimation(id: string, currentPosition: Position, targetPosition: Position, 
		onPositionChanged: (position: Position, ended: boolean) => void) {

		let interrupted = false;

		if (this.ongoingAnimations[id]) {
			this.ongoingAnimations[id].ticker.stop();
			currentPosition = this.ongoingAnimations[id].position;
			delete this.ongoingAnimations[id];

			interrupted = true;
		}

		const diff = {
			x: targetPosition.x - currentPosition.x,
			y: targetPosition.y - currentPosition.y,
		};

		const distance = this.distanceBetweenPoints(currentPosition, targetPosition);

		// eslint-disable-next-line no-magic-numbers
		const animationTime = distance * 1000 / this.animationSpeed;

		const ticker = new PIXI.Ticker();
		ticker.stop();
		let timeElapsed = 0;

		ticker.add(() => {
			timeElapsed += ticker.elapsedMS;
			const progress = _clamp(timeElapsed, 0, animationTime) / animationTime;

			const nextPosition = {
				x: currentPosition.x + (progress * diff.x),
				y: currentPosition.y + (progress * diff.y)
			};

			if (timeElapsed >= animationTime) {
				ticker.stop();
				delete this.ongoingAnimations[id];
				onPositionChanged(targetPosition, true);

			} else {
				onPositionChanged(nextPosition, false);
				this.ongoingAnimations[id].position = nextPosition;
			}

		});
		ticker.start();	

		this.ongoingAnimations[id] = {
			ticker,
			position: currentPosition,
		};

		return interrupted;
	}

	stopAnimation(id: string) {
		this.ongoingAnimations[id]?.ticker?.stop?.();
		delete this.ongoingAnimations[id];
	}

	stopAllAnimations() {
		for (const id in this.ongoingAnimations) {
			this.ongoingAnimations[id].ticker.stop();
		}
		this.ongoingAnimations = {};
	}

}

type OngoingAnimation = {
	ticker: PIXI.Ticker,
	position: Position,
};

import { transformInaccessibleUnderlyingObjectToModel } from '../models/InaccessibleUnderlyingObjectModel';

const formulaApiPrefix = 'v3/formulas';

const mapInaccessibleUnderlyingObjectsToModel = (objects) =>
	objects.map(transformInaccessibleUnderlyingObjectToModel);

export function setupFormulaEndpoints(api) {
	api.injectEndpoints({
		endpoints: (builder) => ({
			getInaccessibleUnderlyingObjects: builder.query({
				query: ({ formulaUuid, uuids, isTeam = false }) => ({
					url: `${formulaApiPrefix}/${formulaUuid}/underlying_objects/`,
					method: 'POST',
					body: { ...(isTeam ? { teams_uuids: uuids } : { members_uuids: uuids }) }
				}),
				transformResponse: mapInaccessibleUnderlyingObjectsToModel,
			})
		}),
		overrideExisting: true,
	});

}

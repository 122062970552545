import { Placement } from '@popperjs/core';
import { Modifier, usePopper } from 'react-popper';
import { useBoolState } from './useBoolState';
import { RefObject, useRef, useState } from 'react';
import { CSSProperties } from 'styled-components';
import { useMergedRefs } from './useMergedRefs';
import { useOutsideClick } from './useOutsideClick';

const defaultModifiers = [
	{
		name: 'offset',
		options: {
			offset: [0, 2],
		},
	}
] as Modifier<string, object>[];

type PopperPopoverProps = {
	openOnStart?: boolean,
	closeOnClickOutside?: boolean,
	placement?: Placement,
	modifiers?: Modifier<string, object>[],
}

type PopperPopoverResult<ButtonRefType extends HTMLElement, PopoverRefType extends HTMLElement> = {
	isOpen: boolean,
	onOpen: () => void,
	onClose: () => void,
	onToggle: () => void,
	popperStyles: CSSProperties,
	actualPlacement: Placement,
	popperAttributes: { [key: string]: string } | undefined,
	buttonRef: (instance: ButtonRefType | ((prevState: ButtonRefType | null) => ButtonRefType | null) | null) => void,
	// eslint-disable-next-line max-len
	popoverRef: (instance: PopoverRefType | ((prevState: PopoverRefType | null) => PopoverRefType | null) | null) => void,
}

export function usePopperPopover<ButtonRefType extends HTMLElement, PopoverRefType extends HTMLElement>({
	openOnStart,
	closeOnClickOutside = true,
	placement = 'bottom-start',
	modifiers = defaultModifiers,
} : PopperPopoverProps = {}) : PopperPopoverResult<ButtonRefType, PopoverRefType> {

	const [isOpen, onOpen, onClose, onToggle] = useBoolState(openOnStart);

	const buttonRef = useRef<ButtonRefType>(null);
	const popoverRef = useRef<PopoverRefType>(null);

	const [buttonReferenceElement, setButtonReferenceElement] = useState<ButtonRefType | null>(null);
	const [popoverElement, setPopoverElement] = useState<PopoverRefType | null>(null);

	const { styles, attributes } = usePopper(buttonReferenceElement, popoverElement, {
		placement,
		modifiers
	});

	useOutsideClick([popoverRef as RefObject<HTMLElement>, buttonRef], onClose, isOpen && closeOnClickOutside);

	const buttonRefMerged = useMergedRefs(buttonRef, setButtonReferenceElement);
	const popoverRefMerged = useMergedRefs(popoverRef, setPopoverElement);

	return {
		isOpen,
		onOpen,
		onClose, 
		onToggle,
		popperStyles: styles.popper,
		popperAttributes: attributes.popper,
		buttonRef: buttonRefMerged,
		popoverRef: popoverRefMerged,
		actualPlacement: attributes?.popper?.['data-popper-placement'] as Placement || placement
	};
}

import { useCallback, useEffect } from 'react';
import _isArray from 'lodash/isArray';
import { KeyCode } from '../utils/keyCodes';

export function useKeyPressed(keys: KeyCode[] | KeyCode, action: () => void): void {
	const callback = useCallback(({ code }: KeyboardEvent) => {
		const keysToCheck = _isArray(keys) ? keys : [keys];
		if (keysToCheck.indexOf(code as KeyCode) >= 0) {
			action();
		}
	}, [keys, action]);
	useEffect(() => {
		window.addEventListener('keydown', callback);
		return () => window.removeEventListener('keydown', callback);
	}, [callback]);
}

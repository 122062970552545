import { useCallback, useEffect } from 'react';
import _isArray from 'lodash/isArray';
import _every from 'lodash/every';

// eslint-disable-next-line max-len
export function useOutsideClick<T extends HTMLElement>(refs: React.RefObject<T>[] | React.RefObject<T>, callback: () => unknown, condition = true): void {
	if (!_isArray(refs)) {
		refs = [refs];
	}

	const handleClick = useCallback((e: MouseEvent) => {
		if (
			_every(refs, ref => isRefOutsideNode(e.target as Node, ref)) &&
			typeof callback === 'function'
		) {
			callback();
		}
	}, [callback, refs]);

	useEffect(() => {
		condition && document.addEventListener('mousedown', handleClick);

		return () => {
			condition && document.removeEventListener('mousedown', handleClick);
		};
	}, [condition, handleClick]);
}

function isRefOutsideNode<T extends HTMLElement>(node: Node, ref: React.RefObject<T>) {
	return !ref || !ref.current || !ref.current.contains(node);
}


import React from 'react';
import { StepRoot } from './Step.styled';
import { StepContext } from './StepContext';
import { useStepperContext } from '../StepperContext';
import { StepProps } from './Step.props';

const Step = React.forwardRef(function Step(props: StepProps, ref) {
	const {
		active: activeProp,
		children,
		className,
		component = 'li',
		indicator = null,
		completed: completedProp,
		disabled: disabledProp,
		index = 0,
		last = false,
		...other
	} = props;

	const { activeStep, connector, orientation, alternativeLabel } = useStepperContext();

	let [active = false, completed = false, disabled = false] = [
		activeProp,
		completedProp,
		disabledProp,
	];

	if (activeStep === index) {
		active = activeProp !== undefined ? activeProp : true;
	} else if (activeStep > index) {
		completed = completedProp !== undefined ? completedProp : true;
	} else if (activeStep < index) {
		disabled = disabledProp !== undefined ? disabledProp : true;
	}

	const contextValue = React.useMemo(
		() => ({ index, last, icon: index + 1, active, completed, disabled }),
		[index, last, active, completed, disabled],
	);

	const ownerState = {
		...props,
		active,
		orientation,
		completed,
		disabled,
		alternativeLabel,
		component,
	};

	const showConnector = connector && !last;

	const newChildren = (
		<StepRoot
			as={component}
			className={className}
			ref={ref}
			ownerState={ownerState}
			{...other}
		>
			{indicator}
			{children}
			{showConnector ? connector : null}
		</StepRoot>
	);

	return (
		<StepContext.Provider value={contextValue}>
			{showConnector ? (
				<React.Fragment>
					{newChildren}
				</React.Fragment>
			) : (
				newChildren
			)}
		</StepContext.Provider>
	);
});

export { Step };

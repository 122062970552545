import React, { PropsWithChildren } from 'react';
import { withCssSelector } from '../shared/withCssSelector';
import { CardRoot } from './Card.styled';
import { Orientation, OrientationOptions } from './Card.props';
import { PropsWithClassName } from '../tabselector/PropsWithClassName';

export type CardProps = {
	disabled?: boolean;
	selected?: boolean;
	orientation?: Orientation;
};

export const Card = withCssSelector(React.forwardRef<HTMLDivElement, PropsWithChildren<PropsWithClassName<CardProps>>>(
	function Card({ disabled, selected, className, orientation = OrientationOptions.vertical, children }, ref) {
		return <CardRoot
			ref={ref}
			$disabled={disabled}
			$selected={selected}
			$orientation={orientation}
			className={className}>
			{children}
		</CardRoot>;
	}
), CardRoot.toString());


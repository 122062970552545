export const PermissionTypes = {
	read: 'read',
	write: 'write',
	export: 'export',
	delete: 'delete',
} as const;

export type PermissionType = typeof PermissionTypes;
type PermissionFields = keyof PermissionType;
export type PermissionValues = PermissionType[PermissionFields];

export type DataSourcePermissions = PermissionType['read'] | PermissionType['write'] | PermissionType['export'];
export type FormulaPermissions = PermissionType['read'] | PermissionType['write'];

export type Permissions = DataSourcePermissions | FormulaPermissions;

export type ApiPermission = {
	code: Permissions,
	label: Capitalize<PermissionValues>
};

/* eslint-disable no-magic-numbers */
import { RefObject, useCallback, useRef, useState } from 'react';
import { AvatarSettings, CharacterAnimation, HumanAvatar } from '../types';
import * as PIXI from 'pixi.js-legacy';
import { AnimationState, Spine } from 'pixi-spine';
import { createAstronautSpine, createHumanSpine,
	createRocketSpine, updateAstronautSpine, updateHumanSpine } from '../app/pixiUtilities';
import { SCALE } from './constants';
import { mapAnimationToName } from '../mapper';

const getPixiProperties = (dimensions: {
	width: number,
	height: number,
	scale: number,
	y: number
}) => ({
	human: {
		create: createHumanSpine,
		update: updateHumanSpine,
		position: {
			scale: dimensions.scale * SCALE,
			x: (dimensions.width / 2) * SCALE,
			y: dimensions.y * SCALE,
		},
		shipPosition: {
			scale: dimensions.scale * SCALE,
			x: (dimensions.width / 2) * SCALE,
			y: dimensions.y * SCALE,
		}
	},
	astronaut: {
		create: createAstronautSpine,
		update: updateAstronautSpine,
		position: {
			scale: dimensions.scale * SCALE,
			x: (dimensions.width / 2) * SCALE,
			y: dimensions.y * SCALE,
		},
		shipPosition: {
			scale: dimensions.scale * SCALE,
			x: (dimensions.width / 2) * SCALE,
			y: dimensions.y * SCALE,
		}
	}
});

export const useCharacterAvatar = (
	pixiApp: RefObject<PIXI.Application | null>,
	{
		settings,
		showInRocket, 
		animate,
		animation,
		dimensions
	} : {
	settings: AvatarSettings,
	showInRocket?: boolean, 
	animate?: boolean,
	animation?: CharacterAnimation,
	dimensions: {
		width: number,
		height: number,
		scale: number,
		y: number
	},
}) => {

	const avatarSpine = useRef<Spine | null>(null);
	const [currentKind, setCurrentKind] = useState(settings.kind);
	const rocketSpine = useRef<Spine | null>(null);

	return useCallback(() => {

		if (!pixiApp.current) {
			return;
		}

		const kind = settings.kind;
		const avatar = settings[kind] as HumanAvatar;

		if (rocketSpine.current && avatarSpine.current) {
			pixiApp.current.stage.removeChild(avatarSpine.current);
			pixiApp.current.stage.removeChild(rocketSpine.current);
			rocketSpine.current = null;
			avatarSpine.current = null;
		}

		if (currentKind !== kind && avatarSpine.current) {
			pixiApp.current.stage.removeChild(avatarSpine.current);
			avatarSpine.current = null;
		}

		const { create, update, position, shipPosition } = getPixiProperties(dimensions)[kind];

		if (avatarSpine.current) {
			update(avatarSpine.current, avatar);
		
		} else {
			avatarSpine.current = create(pixiApp.current, avatar, position);
			pixiApp.current.stage.addChild(avatarSpine.current);
		}

		if (!showInRocket && animate && animation) {
			avatarSpine.current.state.setAnimation(0, mapAnimationToName(kind, animation), true);
			avatarSpine.current.autoUpdate = true;
		} else {
			avatarSpine.current.state.setAnimationWith(0, AnimationState.emptyAnimation, true);

		}

		if (showInRocket && avatarSpine.current) {
			rocketSpine.current = createRocketSpine(pixiApp.current,
				avatarSpine.current, shipPosition, avatar.shipColor);

			if (animate) {
				rocketSpine.current.state.setAnimation(0, 'animation', true);
				rocketSpine.current.autoUpdate = true;
				
			} else {
				rocketSpine.current.state.setAnimation(0, 'animation', true);
				rocketSpine.current.state.setAnimationWith(0, AnimationState.emptyAnimation, true);
			}
		}

		setCurrentKind(kind);
	}, [pixiApp, settings, currentKind, dimensions, showInRocket, animate, animation]);
};

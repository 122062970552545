import { ApiSharingEmployee, } from '../types/ApiSharing';
import { EmployeeModel, transformEmployeeToModel } from './EmployeeModel';
import { SharingModel, transformSharingToModel } from './SharingModel';

export type EmployeeSharingModel = SharingModel & {
	sharedWith: EmployeeModel;
	employeeName: string;
	employeeTitle: string;
}

export const transformEmployeeSharingToModel: (x: ApiSharingEmployee) => EmployeeSharingModel = (sharing) => ({
	...transformSharingToModel(sharing),
	sharedWith: transformEmployeeToModel(sharing.shared_with),
	employeeName: sharing.shared_with.name,
	employeeTitle: sharing.shared_with.title ?? '',
});

import styled, { css } from 'styled-components';
import { Orientation } from './Card.props';
import { Icon } from '../icon';

const TITLE_SIZE_IN_PX = 16;

export const CardRoot = styled.div<{
    $selected?: boolean;
    $disabled?: boolean;
    $orientation?: Orientation;
}>`
  border-radius: 2px;
  border: 1px solid ${props => props.theme.card.default.border};
  background-color: #FFFFFF;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  transition: box-shadow 0.2s ease-in-out;

  ${({ $orientation }) => $orientation === 'vertical' && css`
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
  `};

  ${({ $selected, theme }) => $selected && css`
    border: 3px solid ${theme.card.selected.border};
    box-shadow: 0 0 0 3px ${theme.card.selected.boxShadow};
  `};
  
  ${({ $disabled }) => $disabled && css`
    pointer-events: none;
    opacity: 0.5;
  `};

  &:active,
  &:focus {
    border: 1px solid ${props => props.theme.card.selected.border};
    box-shadow: 0 0 0 3px ${props => props.theme.card.selected.boxShadow};
  }

  &:hover {
    box-shadow: 1px 1px 10px 0 rgba(121, 133, 149, 0.30);
  }
`;

export const CardIcon = styled(Icon)`
  font-size: 48px;
`;

export const CardMedia = styled.img<{
  $width?: number;
  $height?: number;
  $alignSelf?: boolean;
}>`
  max-width: 100%;

  ${({ $width }) => $width ? css`
    width: ${$width}px;
  ` : ''};

  ${({ $height }) => $height ? css`
    height: ${$height}px;
  ` : ''};

  ${({ $alignSelf }) => $alignSelf ? css`
    align-self: start;
  ` : ''};
`;

export const CardSeperator = styled.hr`
  height: 1px;
  background-color: ${props => props.theme.card.default.border};
  margin: 0;
`;

export const CardBox = styled.div<{
    $orientation?: Orientation;
    $padding?: number;
    $gap?: number;
}>`
  font-family: ${props => props.theme.font};
  color: ${props => props.theme.card.color};
  padding: ${props => props.$padding || 0}px;
  display: flex;
  flex-direction: ${props => props.$orientation === 'horizontal' ? 'row' : 'column'};
  align-items: stretch;
  justify-content: stretch;
  gap: ${props => props.$gap || 0}px;
  position: relative;
`;

export const CardContent = styled.div<{
    $padding?: number;
}>`
  color: ${props => props.theme.card.color};
  display: flex;
  padding: ${props => props.$padding || 0}px;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  flex: 1 auto;
  gap: 8px;
`;

export const CardActions = styled.div`
  flex: 1 auto;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;

  & > div {
    display: flex;
    justify-content: space-between;
    gap: 12px;
  }
`;

export const CardTitle = styled.h5<{
    $fontSize?: number,
}>`
  color: ${props => props.theme.card.title};
  font-size: ${props => props.$fontSize || TITLE_SIZE_IN_PX}px;
  font-weight: 500;
  line-height: 140%;
  margin: 0;
`;

export const CardChip = styled.div`
  flex-shrink: 0;
`;

export const CardSubHeading = styled.h5`
  color: ${props => props.theme.card.subtitle};
  font-size: 14px;
  font-weight: 400;
  margin: 0;
`;

export const CardDescription = styled.p`
  color: ${props => props.theme.card.color};
  font-size: 14px;
  font-weight: 400;
  margin: 0;
`;


import styled, { css } from 'styled-components';
import { withCssSelector } from '../shared/withCssSelector';

const StyledCard = styled.div<{
	$selected?: boolean,
}>`
	font-family: ${props => props.theme.font};
	color: ${props => props.theme.card.color};
	padding: 8px 12px;
	border-radius: 2px;
	font-size: 14px;
	border: 1px solid ${props => props.theme.card.default.border};
	box-shadow: 1px 1px 2px 0 rgba(121, 133, 149, 0.25);
	cursor: pointer;
	background-color: ${props => props.theme.card.background};
	line-height: 20px;
	min-height: 36px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: center;
	justify-content: center;

	${({ $selected, theme }) => $selected && css`
		border: 2px solid ${theme.card.selected.border};
		box-shadow: 0 0 0 3px ${theme.card.selected.boxShadow};
		padding: 8px 11px;
	`}
`;

export const CardOption = withCssSelector(({
	value,
	label,
	graphic,
	selected,
	onSelected,
}: {
	value: string,
	label: string,
	graphic?: React.ReactNode;
	selected?: boolean,
	onSelected: (value: string) => void,
}) => {
	const onClick = () => onSelected(value);
	return <StyledCard onClick={onClick} $selected={selected}>
		{graphic}
		{label}
	</StyledCard>;
}, StyledCard.toString());


import React, { useContext, useState } from 'react';
import { Button } from '@plecto/ui';
import { CheckboxesSection } from '../checkboxes/CheckboxesSection';
import { Permissions } from '../../types/permissions';
import { PopoverContentWrapper } from '../PopoverContentWrapper';
import { SharedObjectMetadata } from '../../types/SharedObjectMetadata';
import { SharingModalContext } from '../../context/SharingModalContext';
import { onArrayStateChange } from '../../util/onArrayStateChange';
import { TeamSharingModel } from '../../models/TeamSharingModel';
import { ShareeInfoWrapper } from '../../components/ShareeInfoSection';
import { TeamInfoSection } from '../TeamInfoSection';
import { useTranslation } from 'react-i18next';
import { ApiContext } from '../../context/ApiContext';

type ModifyTeamPermissionsPopoverProps = {
	shareeToBeModified: TeamSharingModel,
	clearShareeToBeModified: () => void,
	sharedObjectMetadata: SharedObjectMetadata,
}

export function ModifyTeamPermissionsPopover({
	clearShareeToBeModified,
	sharedObjectMetadata,
	shareeToBeModified,
}: ModifyTeamPermissionsPopoverProps
) {
	const api = useContext(ApiContext);
	const { closeModal } = useContext(SharingModalContext);
	const { t } = useTranslation();

	const { uuid: sharingUuid, sharedWithTeam, objectPermissions } = shareeToBeModified;
	const [selectedPermissions, setSelectedPermissions] = useState<Permissions[]>(objectPermissions);
	const onSelectedPermissionsChange = onArrayStateChange<Permissions>(selectedPermissions, setSelectedPermissions);
	const { objectUuid, objectType } = sharedObjectMetadata;
	// @ts-ignore
	const [updateTeamsPermissions] = api.useUpdateTeamsPermissionsMutation();
	// @ts-ignore
	const [deletePermissions] = api.useDeleteShareePermissionsMutation();

	const onSaveChangesToPermissions = () => {
		updateTeamsPermissions({
			sharingUuid,
			...sharedObjectMetadata,
			permissions: selectedPermissions,
			teamIds: [sharedWithTeam.uuid],
		});

		closeModal();
	};

	const onRemoveAccess = () => {
		deletePermissions({ sharingUuid, ...sharedObjectMetadata });

		closeModal();
	};

	return <PopoverContentWrapper
		title={t('Edit {{teamName}}\'s access', { teamName: sharedWithTeam.name })}
		onClose={onSaveChangesToPermissions}
		onBackClick={clearShareeToBeModified}
	>
		<ShareeInfoWrapper>
			<TeamInfoSection sharee={sharedWithTeam} />
			<Button
				variant='default-danger'
				size='small'
				onClick={onRemoveAccess}
				label={t('Remove access')}
			/>
		</ShareeInfoWrapper>
		<CheckboxesSection
			onChange={onSelectedPermissionsChange}
			selectedPermissions={selectedPermissions}
			objectType={objectType}
			objectUuid={objectUuid}
			selectedIds={[sharedWithTeam.uuid]}
		/>
	</PopoverContentWrapper>;
}

import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Permissions } from '../../types/permissions';
import { SharedObjectType, SharedObjectTypes } from '../../types/sharedObjectType';
import { DataSourceCheckboxes } from './customPermissionCheckboxes/dataSources/DataSourceCheckboxes';
import { FormulaCheckboxes } from './customPermissionCheckboxes/formulas/FormulaCheckboxes';
import { Uuid } from '../../types/Uuid';

const StyledCheckboxesSection = styled.div`
	display: flex;
	flex-direction: column;
	padding: 8px 20px;
`;

type CheckboxesSectionProps = {
	selectedPermissions: Permissions[];
	objectType: SharedObjectType;
	onChange: (permission: Permissions) => void
	selectedIds?: Array<Uuid>;
	objectUuid?: Uuid;
	isTeam?: boolean;
}

export const CheckboxesSection = ({
	selectedPermissions,
	objectType,
	onChange,
	objectUuid,
	selectedIds,
	isTeam = false,
}: CheckboxesSectionProps) => {

	const isPermissionSelected = useCallback(
		(permission: Permissions): boolean => selectedPermissions.includes(permission),
		[selectedPermissions]
	);

	const checkboxes = useMemo(() => {
		switch (objectType) {
		case SharedObjectTypes.DataSource:
			return <DataSourceCheckboxes onChange={onChange} isPermissionSelected={isPermissionSelected} />;
		case SharedObjectTypes.Formula:
			return (
				<FormulaCheckboxes
					isTeam={isTeam}
					onChange={onChange}
					isPermissionSelected={isPermissionSelected}
					formulaUuid={objectUuid}
					selectedIds={selectedIds}
				/>
			);
		}
	}, [objectType, onChange, isPermissionSelected, isTeam, objectUuid, selectedIds]);

	return (
		<StyledCheckboxesSection>
			{checkboxes}
		</StyledCheckboxesSection>
	);
};

import React, { useState, useCallback, useMemo, PropsWithChildren } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Footer } from './Footer';
import { SlideInContext } from './SlideInContext';
import { SlideInHost } from './SlideInHost';
import { SlideIn } from './SlideIn';
import { withCssSelector } from '../shared/withCssSelector';
import { EditSlideInProps } from './EditSlideIn.props';
import { Title } from './Title';
import { useKeyPressed } from '../hooks/useKeyPressed';
import { keyCodes } from '../utils/keyCodes';
import { useBlockBodyScroll } from '../hooks/useBlockBodyScroll';

const SlideInContent = styled.div`
	padding: 0 20px;
	flex: 1 1 auto;
	overflow-y: auto;
	font-family: ${props => props.theme.font};
`;

export const EditSlideIn = withCssSelector(React.forwardRef<HTMLParagraphElement, PropsWithChildren<EditSlideInProps>>(
	function EditSlideIn({
		open,
		title,
		onSave,
		onClose,
		children,
		onClick,
		isSaving,
		hideHeader,
		hideFooter,
		cancelText,
		className,
		saveText,
		transparentOverlay,
		onEnterDone,
		onOutsideClick,
		onOverlayClick,
		headerAction,
		borderless
	}, ref) {
	
		const [openByChild, setOpen] = useState<boolean | null>(null);
	
		const openChild = useCallback(() => {
			setOpen(null);
		}, []);
	
		const closeByChild = useCallback(() => {
			setOpen(false);
		}, []);
	
		const isOpen = open && (openByChild === null || openByChild);
	
		const context = useMemo(() => ({
			open: openChild,
			close: closeByChild
		}), [openChild, closeByChild]);
	
		const onEscape = useCallback(() => {
			if (isOpen) {
				onClose?.();
			}
		},[isOpen, onClose]);
	
		useKeyPressed(keyCodes.ESC, onEscape);
	
		useBlockBodyScroll(open);
	
		return <SlideInContext.Provider value={context}>
			<SlideInHost>
				<SlideIn
					open={isOpen}
					transparentOverlay={transparentOverlay}
					onEnterDone={onEnterDone}
					onClick={onClick}
					onOutsideClick={onOutsideClick}
					onOverlayClick={onOverlayClick ? onOverlayClick : onClose}
					ref={ref}
					borderless={borderless}
				>
					{!hideHeader && <Title onClose={onClose} title={title} headerAction={headerAction} />}
					<SlideInContent className={classNames('slide-in-content', className)}>
						{open && children}
					</SlideInContent>
					{!hideFooter &&
						<Footer
							onClose={onClose}
							onSave={onSave}
							isSaving={isSaving}
							cancelText={cancelText}
							saveText={saveText}
						/>}
				</SlideIn>
			</SlideInHost>
		</SlideInContext.Provider>;
	}
), SlideIn.cssSelector);

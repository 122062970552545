import classNames from 'classnames';
import React from 'react';
import styled, { css } from 'styled-components';
import { InputChip } from './InputChip';
import { withCssSelector } from '../../shared/withCssSelector';

const StyledSelectInput = styled.div<{$disabled?: boolean, $active?: boolean}>`
	font-family: ${props => props.theme.font};

	display: flex;
	flex-direction: row;
	white-space: nowrap;
	min-height: 46px;
	height: auto;
	padding: ${props => props.theme.form.input.size.default.padding};
	font-size: ${props => props.theme.form.input.size.default.fontSize}px;
	position: relative;
	cursor: pointer;
	border: ${props => props.theme.form.border.width}px 
		solid ${props => props.theme.form.input.border};
	border-radius: ${props => props.theme.form.border.radius}px;

	-webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	-o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;	

	:focus {
		border-color: ${props => props.theme.form.input.focus.border};
		box-shadow: ${props => props.theme.form.input.focus.shadow};
		outline: 0;
	}
	${props => props.$active && css`
		border-color: ${props.theme.form.input.focus.border};
		box-shadow: ${props.theme.form.input.focus.shadow};
	`}

	::placeholder {
		color: ${props => props.theme.form.input.placeholderColor};
	}

	${({ $disabled, theme }) => $disabled && css`
		background: ${theme.form.disabled.background};
		opacity: ${theme.form.disabled.opacity};
	`}
`;

const StyledSelectionItems = styled.div`
	display: flex;
	overflow: auto;
	position: relative;
	flex: 1 auto;
	flex-wrap: wrap;
	gap: 8px;
`;

const StyledPlaceholder = styled.span`
	color: ${props => props.theme.form.input.placeholderColor};;
	text-overflow: ellipsis;
	overflow: hidden;
	align-items: center;
	white-space: nowrap;
	font-size: ${props => props.theme.form.input.size.default.fontSize}px;
	align-self: center;
`;

type Props = {
	className?: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	selectedItems: Array<any>,
	onRemove: (id: string) => void,
	onClick?: (e: React.MouseEvent<HTMLDivElement>) => void,
	placeholder?: string,
	canRemove?: boolean,
	disabled?: boolean,
	showAvatars?: boolean,
	active?: boolean,
};

export const MultiSelectInput = withCssSelector(React.forwardRef<HTMLDivElement, Props>(
	function MultiSelectInput({
		className,
		selectedItems,
		onRemove,
		onClick,
		placeholder,
		disabled,
		canRemove,
		showAvatars,
		active
	}, ref) {
		const selection = selectedItems.length > 0;

		return <StyledSelectInput
			className={classNames(className, 'form-control')}
			onClick={onClick}
			$disabled={disabled}
			$active={active}
			ref={ref}
		>
			{!selection ?
				(placeholder ?
					<StyledPlaceholder>
						{placeholder}
					</StyledPlaceholder>
					: null) :
				<StyledSelectionItems>
					{selectedItems.map(item => <InputChip
						key={item.id}
						onRemove={onRemove}
						item={item}
						canRemove={canRemove}
						showAvatar={showAvatars}
					/>)}
				</StyledSelectionItems>}
		</StyledSelectInput>;
	}
), StyledSelectInput.toString());

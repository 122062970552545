import React, { ComponentPropsWithoutRef } from 'react';
import { IconName } from './IconName';
import classNames from 'classnames';
import { withCssSelector } from '../shared/withCssSelector';
import styled from 'styled-components';

const StyledIcon = styled.span``;

export type IconProps = {
	icon: IconName;
} & ComponentPropsWithoutRef<'span'>

export const Icon = withCssSelector(React.forwardRef<HTMLSpanElement, IconProps>(
	function Icon({ icon, className, ...props }, ref) {
		return <StyledIcon ref={ref} className={classNames('icon', `icon-${icon}`, className)} {...props} />;
	}
), StyledIcon.toString());

export function scrollParentToChild(parent: HTMLElement, child: HTMLElement) {
	const parentRect = parent.getBoundingClientRect();
	const parentViewableArea = {
		height: parent.clientHeight,
		width: parent.clientWidth
	};

	const childRect = child.getBoundingClientRect();
	const isChildElVisible =
		(childRect.top >= parentRect.top) && (childRect.bottom <= parentRect.top + parentViewableArea.height);

	if (!isChildElVisible) {
		const scrollTop = childRect.top - parentRect.top;
		const scrollBottom = childRect.bottom - parentRect.bottom;

		if (Math.abs(scrollTop) < Math.abs(scrollBottom)) {
			parent.scrollTop += scrollTop;
		} else {
			parent.scrollTop += scrollBottom;
		}
	}

}

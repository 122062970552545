import React from 'react';
import { Avatar, If } from '@plecto/ui';
import { EmployeeModel } from '../models/EmployeeModel';
import { ShareeDetailsSection, ShareeInfoSection, ShareeName, ShareeTitle } from '../components/ShareeInfoSection';

type ShareeInfoProps = {
	sharee: EmployeeModel;
}

export function UserInfoSection({ sharee }: ShareeInfoProps) {
	const { avatarUrl, uuid, name, title } = sharee;
	return (
		<ShareeInfoSection>
			<Avatar url={avatarUrl} size={40} />
			<ShareeDetailsSection>
				<a href={`/employee/${uuid}`} target="_blank" rel="noreferrer"><ShareeName>{name}</ShareeName></a>
				<If condition={Boolean(title)}>
					<ShareeTitle>{title}</ShareeTitle>
				</If>
			</ShareeDetailsSection>
		</ShareeInfoSection>
	);
}

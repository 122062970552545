import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Icon, If } from '@plecto/ui';
import { InaccessibleUnderlyingObjectModel } from '../models/InaccessibleUnderlyingObjectModel';
import { openInNewTab } from '../util/openInNewTab';

const StyledListItem = styled.li`
	align-items: center;
	display: flex;
	gap: 4px;
	padding: 4px 0;
`;

const StyledSpan = styled.span`
	color: #0F1E38;
	cursor: default;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
`;

const StyledIcon = styled.img`
	height: 20px;
	width: 20px;
`;

const StyledLinkIcon = styled(Icon)`
	color: #6B7280;
	cursor: pointer;
`;

type Props = {
	item: InaccessibleUnderlyingObjectModel,
};

export const UnderlyingObjectListItem = ({ item }: Props) => {
	const onClick = useCallback(() => openInNewTab(item.url), [item.url]);

	return (
		<StyledListItem>
			<StyledIcon src={item.icon} />
			<StyledSpan>{ item.title }</StyledSpan>
			<If condition={Boolean(item.url)}>
				<StyledLinkIcon icon="link" onClick={onClick} />
			</If>
		</StyledListItem>
	);
};

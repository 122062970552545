import styled from 'styled-components';
import { Loader } from '../loader';
import { Button } from '../button';

const StyledFooter = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 20px;
	gap: 12px;
	& > ${Button.cssSelector} {
		flex: 0 1 50%;
	}

	${Loader.cssSelector} {
		border-top-color: #FFFFFF;
		border-left-color: #FFFFFF
	}
`;

export function Footer({ onSave, onClose, isSaving, cancelText, saveText }: {
	onSave: () => void,
	onClose: () => void,
	isSaving?: boolean,
	cancelText: string,
	saveText: string
}) {
	return <StyledFooter className={'slide-in-footer'}>
		<Button 
			variant='secondary'
			onClick={onClose}
			label={cancelText}
		/>
		<Button 
			variant='primary'
			onClick={onSave}
			disabled={isSaving}
		>
			{isSaving ? <Loader /> : saveText}
		</Button>
	</StyledFooter>;
}

import React, { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';
import { ErrorIcon } from '../error-icon';
import { withCssSelector } from '../../shared/withCssSelector';

const StyledErrorBlock = styled.span`
	line-height: 18px;
	font-family: ${props => props.theme.font};
	font-weight: ${props => props.theme.form.error.fontWeight};
	font-size: ${props => props.theme.form.error.fontSize}px;
	color: ${props => props.theme.form.error.color};

	${ErrorIcon.cssSelector} {
		font-size: 16px;
	}
`;

export type ErrorBlockProps = ComponentPropsWithoutRef<'span'>

export const ErrorBlock = withCssSelector(React.forwardRef<HTMLSpanElement, ErrorBlockProps>(
	function ErrorBlock(props, ref) {
		return <StyledErrorBlock ref={ref} {...props} />;
	}
), StyledErrorBlock.toString());

import * as React from 'react';
import { OverrideProps } from './utilis';

export const OrientationOptions = {
	horizontal: 'horizontal',
	vertical: 'vertical',
} as const;

export type Orientation = typeof OrientationOptions[keyof typeof OrientationOptions];

export const SizeOptions = {
	sm: 'sm',
	md: 'md',
	lg: 'lg',
} as const;

export type Size = typeof SizeOptions[keyof typeof SizeOptions];

export interface StepperOwnProps {
  /**
   * Set the active step (zero based index).
   * Set to -1 to disable all the steps.
   * @default 0
   */
  activeStep?: number;
  /**
   * If set to 'true' and orientation is horizontal,
   * then the step label will be positioned under the icon.
   * @default false
   */
  alternativeLabel?: boolean;
  /**
   * Passed `<Step />` components.
   */
  children?: React.ReactNode;
  /**
   * An element to be placed between each step.
   * @default <StepConnector />
   */
  connector?: React.ReactElement | null;
  /**
   * The component orientation (layout flow direction).
   * @default 'horizontal'
   */
  orientation?: Orientation;
  size?: Size;
}

export interface StepperTypeMap<
  AdditionalProps = object,
  RootComponent extends React.ElementType = 'ol',
> {
  props: AdditionalProps & StepperOwnProps;
  defaultComponent: RootComponent;
}

export type StepperProps<
  RootComponent extends React.ElementType = StepperTypeMap['defaultComponent'],
  AdditionalProps = { component?: React.ElementType },
> = OverrideProps<StepperTypeMap<AdditionalProps, RootComponent>, RootComponent>;

export type StepperOwnerState = StepperProps

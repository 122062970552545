import React, { PropsWithChildren } from 'react';
import { SharingModalContextWrapper } from './context/SharingModalContext';
import { SharingModal } from './SharingModal';

export function SharingObjectContextWrapperWithModal({ children }: PropsWithChildren) {
	return <SharingModalContextWrapper>
		{children}
		<SharingModal />
	</SharingModalContextWrapper>;
}

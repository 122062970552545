import styled from 'styled-components';
import { Label } from '../label';
import { Integrations } from './WidgetTemplateCard.props';

export const StyledIntegrations = styled.div`
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
  align-items: center;
`;
export const StyledRemainder = styled(Label)`
  height: 22px;
  border-radius: 36px;
  padding: 4px 5px;
`;
export const StyledIntegration = styled.img`
  height: 22px;
`;

type TemplateCardActionsType = {
    integrations: Integrations;
    limit?: number;
}

export function TemplateCardActions({ integrations, limit = 3 }: TemplateCardActionsType) {
	const visibleIntegrations = integrations.length > limit ? integrations.slice(0, limit) : integrations;
	const remainder = integrations.length - limit;

	return <StyledIntegrations>
		{visibleIntegrations.map((integration) => (
			<StyledIntegration
				key={integration.uuid}
				title={integration.name}
				alt={integration.name}
				src={integration.thumbnail}
			/>
		))}
		{remainder > 0 && <StyledRemainder>{`+${remainder}`}</StyledRemainder>}
	</StyledIntegrations>;
}

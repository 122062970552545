import React, { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';
import { formOpacities } from '../formOpacities';
import { withCssSelector } from '../../shared/withCssSelector';
import { RequiredAsterisk } from './RequiredAsterisk';

const StyledFieldLabel = styled.label<{
	$disabled?: boolean | undefined;
}>`
	font-family: ${props => props.theme.font};
	font-style: normal;
	font-weight: ${props => props.theme.form.label.fontWeight};
	font-size: ${props => props.theme.form.label.fontSize}px;
	line-height: 140%;
	align-items: center;
	color: ${props => props.theme.form.label.color};
	opacity: ${props => props.$disabled ? props.theme.form.disabled.opacity : formOpacities.full};
	margin: 0;

	&[for] {
		cursor: pointer;
	}

	display: flex;
	gap: 2px;
`;

export type FieldLabelProps = {
	required?: boolean | undefined
	disabled?: boolean | undefined
} & ComponentPropsWithoutRef<'label'>

export const FieldLabel = withCssSelector(React.forwardRef<HTMLLabelElement, FieldLabelProps>(
	function FieldLabel({ disabled, htmlFor, required, children, ...props }, ref) {
		return <StyledFieldLabel
			ref={ref}
			htmlFor={disabled ? undefined : htmlFor}
			$disabled={disabled}
			{...props}
		>
			{children}
			{required && <RequiredAsterisk />}
		</StyledFieldLabel>;
	}
), StyledFieldLabel.toString());

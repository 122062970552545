import React, { useCallback, useContext } from 'react';
import { PopoverContentWrapper } from '../PopoverContentWrapper';
import { CheckboxesSection } from '../checkboxes/CheckboxesSection';
import { ObjectAccessShareeSwitcher } from '../ObjectAccessShareeSwitcher';
import styled from 'styled-components';
import { ShareeContext } from '../../context/ShareeContext';
import { SharingModalContext } from '../../context/SharingModalContext';
import { EmployeeSharingModel } from '../../models/EmployeeSharingModel';
import { TeamSharingModel } from '../../models/TeamSharingModel';
import { SharedObjectMetadata } from '../../types/SharedObjectMetadata';
import { useTranslation } from 'react-i18next';
import { shareeTypes } from '../../types/ShareeType';

const StyledShareWithSection = styled.div`
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 24px;
`;

type AddObjectAccessPopoverProps = {
	onBackClick: () => void,
	memberSharings: Array<EmployeeSharingModel>,
	teamSharings: Array<TeamSharingModel>,
	sharedObjectMetadata: SharedObjectMetadata,
}

export function AddObjectAccessPopover({
	memberSharings,
	teamSharings,
	onBackClick,
	sharedObjectMetadata,
}: AddObjectAccessPopoverProps) {
	const { t } = useTranslation();
	const { objectName, objectType, objectUuid } = sharedObjectMetadata;

	const {
		onSelectedPermissionsChange,
		selectedShareeType,
		selectedPermissions,
		onAssignSharees,
		onEmployeesChange,
		selectedEmployees,
		onTeamsChange,
		selectedTeams,
	} = useContext(ShareeContext);
	const { closeModal } = useContext(SharingModalContext);

	const onAssignPermissions = useCallback(() => {
		onAssignSharees();
		closeModal();
	}, [closeModal, onAssignSharees]);

	const isTeam = selectedShareeType === shareeTypes.teams;

	return <PopoverContentWrapper
		title={t('Share {{objectName}}', { objectName })}
		onClose={onAssignPermissions}
		onBackClick={onBackClick}
		saveButtonLabel={t('Share')}
	>
		<StyledShareWithSection>
			<ObjectAccessShareeSwitcher
				onEmployeesChange={onEmployeesChange}
				onTeamsChange={onTeamsChange}
				memberSharings={memberSharings}
				teamSharings={teamSharings}
				selectedEmployees={selectedEmployees}
				selectedTeams={selectedTeams}
			/>
		</StyledShareWithSection>
		<CheckboxesSection
			onChange={onSelectedPermissionsChange}
			selectedPermissions={selectedPermissions}
			objectType={objectType}
			objectUuid={objectUuid}
			selectedIds={isTeam ? selectedTeams : selectedEmployees}
			isTeam={isTeam}
		/>
	</PopoverContentWrapper>;
}

import React, { useCallback, useContext } from 'react';
import { SharingModalContext } from './context/SharingModalContext';
import { SharingModal } from './SharingModal';
import { SharedObjectMetadata } from './types/SharedObjectMetadata';

type ShareObjectModalProps = {
	sharedObjectMetadata: SharedObjectMetadata,
	children: ({ openModal }: { openModal: () => void; }) => React.ReactNode
}

export function ShareObjectModal({ sharedObjectMetadata, children }: ShareObjectModalProps) {
	const { openModal } = useContext(SharingModalContext);
	const onOpen = useCallback(() => openModal(sharedObjectMetadata), [openModal, sharedObjectMetadata]);

	return (
		<>
			<SharingModal />
			{children?.({
				openModal: onOpen,
			})}
		</>
	);

}

import styled, { css } from 'styled-components';
import { StepConnectorLineOwnerState } from './StepConnectorLine.props';
import { StepConnectorOwnerState } from './StepConnector.props';
import { OrientationOptions } from '../Stepper.props';
import { STEP_INDICATOR_SIZE } from '../StepIndicator/StepIndicator.styled';
import { STEPPER_GAP_HORIZONTAL, STEPPER_GAP_VERTICAL } from '../Stepper.styled';

const StepConnectorRoot = styled.div<{
    ownerState: StepConnectorOwnerState
}>`

  ${({ ownerState }) => ownerState.orientation === OrientationOptions.horizontal ? css`
    ${ownerState.alternativeLabel ? css`
      margin: 0;
      position: absolute;
      height: 1px;
      z-index: 0;
      top: calc((${STEP_INDICATOR_SIZE}px - 1px) / 2);
      left: calc(50% + ${STEP_INDICATOR_SIZE}px / 2 + ${STEPPER_GAP_HORIZONTAL}px);
      width: calc(100% - 20px - (${STEPPER_GAP_HORIZONTAL}px * 2));
    ` : css`
      flex: 1 1 auto;
      min-width: 1rem;
    `};
  ` : css`
    height: calc(100% - ${STEP_INDICATOR_SIZE}px + (${STEPPER_GAP_VERTICAL}px / 2));
    margin: 0;
    position: absolute;
    width: 1px;
    z-index: 0;
    top: calc(${STEP_INDICATOR_SIZE}px + (${STEPPER_GAP_VERTICAL}px / 2));
    left: calc(${STEP_INDICATOR_SIZE}px / 2 - 1px);
    bottom: 0;
  `}

`;

const StepConnectorLine = styled.span<{
    ownerState: StepConnectorLineOwnerState
}>`
  display: block;
  border-color: #E2E7ED;
  ${({ ownerState }) => ownerState.orientation === OrientationOptions.horizontal ? css`
    border-top-style: solid;
    border-top-width: 1px;
  ` : css`
    border-left-style: solid;
    border-left-width: 1px;
    min-height: 100%;
  `}

`;

export { StepConnectorRoot, StepConnectorLine };


import React from 'react';
import { useStepperContext } from '../StepperContext';
import { useStepContext } from '../Step/StepContext';
import { StepConnectorLine, StepConnectorRoot } from './StepConnector.styled';
import { StepConnectorProps } from './StepConnector.props';

const StepConnector = React.forwardRef(function StepConnector(props: StepConnectorProps, ref) {
	const { className, ...other } = props;

	const { alternativeLabel, orientation = 'horizontal' } = useStepperContext();
	const { active, disabled, completed } = useStepContext();

	const ownerState = { ...props, alternativeLabel, orientation, active, completed, disabled };

	return (
		<StepConnectorRoot
			className={className}
			ref={ref as React.RefObject<HTMLDivElement>}
			ownerState={ownerState}
			{...other}
		>
			<StepConnectorLine ownerState={ownerState} {...other} />
		</StepConnectorRoot>
	);
});

export { StepConnector };

/* eslint-disable no-magic-numbers */
import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Loader } from '../../loader';
import * as PIXI from 'pixi.js-legacy';
import { useCharacterAvatar } from './useCharacterAvatar';
import { SCALE } from './constants';
import { createPixiApp } from '../app/pixiUtilities';
import { AvatarFigureProps, DisplayDimensions } from './AvatarFigure.props';

const StyledPreviewContainer = styled.div<{
	$dimensions: DisplayDimensions,
}>`
	background: transparent;
	width: 100%;
	height: ${props => props.$dimensions.height}px;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
`;

const StyledPreview = styled.div<{
	$visible: boolean;
	$dimensions: DisplayDimensions,
}>`
	${props => !props.$visible && css`display: none;`}
	width: ${props => props.$dimensions.width}px;
	height: ${props => props.$dimensions.height}px;
	background: transparent;

	canvas {
		width: ${props => props.$dimensions.width}px;
		height: ${props => props.$dimensions.height}px;
	}
`;

export const AvatarFigure = ({
	avatarSettings,
	dimensions,
}: AvatarFigureProps) => {
	const pixiApp = useRef<PIXI.Application | null>(null);
	const canvasRef = useRef<HTMLDivElement | null>(null);
	const [loaded, setLoaded] = useState(false);

	const createOrUpdateAvatar = useCharacterAvatar(pixiApp, { settings: avatarSettings, dimensions });

	useLayoutEffect(() => {
		if (pixiApp.current && loaded) {
			createOrUpdateAvatar();
		}
	}, [createOrUpdateAvatar, loaded]);

	const onLoaded = useCallback(() => {
		if (pixiApp.current && canvasRef.current) {
			const canvas = pixiApp.current.view;
			
			canvasRef.current.innerHTML = '';
			canvasRef.current.appendChild(canvas);

			createOrUpdateAvatar();
		}
		setLoaded(true);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useLayoutEffect(() => {
		pixiApp.current = createPixiApp({
			width: dimensions.width * SCALE,
			height: dimensions.height * SCALE,
			onComplete: onLoaded,
		});
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onLoaded]);

	return <StyledPreviewContainer $dimensions={dimensions}>
		{!loaded && <Loader />}
		<StyledPreview ref={canvasRef} $visible={loaded} $dimensions={dimensions} />
	</StyledPreviewContainer>;

};


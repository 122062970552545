import classNames from 'classnames';
import styled from 'styled-components';
import { PropsWithClassName } from './PropsWithClassName';
import { ReactElement, useCallback } from 'react';
import { Icon, IconName } from '../icon';

const TabButtonRoot = styled.div`
  font-family: ${props => props.theme.font};
  display: inline-block;
  cursor: pointer;
  width: auto;
  text-align: center;
  font-weight: 400;
  white-space: nowrap;
  flex: 1;
  color: ${props => props.theme.tabs.default.color};
  min-height: 52px;
  user-select: none;
  max-width: 100%;

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 4px;
    background: ${props => props.theme.tabs.highlight};
    transition: width .2s;
    animation-timing-function: ease;
    margin: auto;
  }

  &:hover {
    color: ${props => props.theme.tabs.hover.color};

    ${Icon.cssSelector} {
      color: ${props => props.theme.tabs.hover.icon};
    }
  }

  &.tab-button--disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &.tab-button--selected {
    color: ${props => props.theme.tabs.active.color};

    ${Icon.cssSelector} {
      color: ${props => props.theme.tabs.active.icon};
    }

    &:after {
      width: 100%;
    }
  }
`;

const TabButtonContent = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;

const TabIcon = styled(Icon)`
  color: ${props => props.theme.tabs.default.icon};
  height: 24px;
  width: 24px;
  font-size: 18px;
`;

type TabButtonProps = PropsWithClassName<{
    id: string;
    label: string;
	chip?: ReactElement;
    icon?: IconName;
    selected?: boolean;
    disabled?: boolean;
    onSelect: (id: string) => void;
}>;

export const TabButton = ({ id, className, chip, label, icon, selected, disabled, onSelect }: TabButtonProps) => {
	const handleClick = useCallback(() => onSelect(id), [id, onSelect]);

	return (<TabButtonRoot
		className={classNames('tab-button', className, {
			'tab-button--selected': selected,
			'tab-button--disabled': disabled,
		})}
		onClick={handleClick}
	>
		<TabButtonContent>
			{chip}
			{icon && <TabIcon icon={icon} />}
			<span>{label}</span>
		</TabButtonContent>
	</TabButtonRoot>);
};

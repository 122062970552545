import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { DropdownParentContext } from '../dropdown';
import { Icon, IconName } from '../icon';
import { Menu } from '../dropdown/Menu';
import { createPortal } from 'react-dom';
import { getWidgetTypeLabel } from './widgetTypeIcons';
import { useDropdownLogic } from '../dropdown/useDropdownLogic';
import { Widgets } from './WidgetTemplateCard.props';
import { neutral } from '../themes/colors';

const SelectorRoot = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  border-right: 1px solid ${neutral['100']};
  padding-right: 12px;
  flex: 0 0 68px;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 68px;
`;

const IconSelector = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLabel = styled.span`
  color: #8B9EB7;
  font-size: 10px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
`;

const FlexIcon = styled(Icon)`
  color: #3A5374;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledWidgetIcon = styled(FlexIcon)`
  font-size: 48px;
  width: 48px;
  height: 48px;
`;

const StyledArrowIcon = styled(FlexIcon)`
  font-size: 16px;
  width: 20px;
`;

type TemplateListItemIconProps = {
    onClose?: () => void;
    widgets: Widgets;
    onSelect: (id: string) => void;
    value: string,
};

export function WidgetTypeSelector({
	onClose: propsOnClose,
	onSelect,
	widgets = [],
	value: initValue,
}: TemplateListItemIconProps) {
	const [value, setValue] = useState<string>(initValue);
	const { parent } = useContext(DropdownParentContext);

	const onMenuClose = () => {
		propsOnClose?.();
	};

	const {
		isOpen,
		styles,
		attributes,
		onClose,
		onOpen,
		buttonRef,
		menuRef,
	} = useDropdownLogic({ onMenuClose, placement: 'bottom-start' });

	const selectedWidget = widgets.filter(w => w.uuid === value)[0];
	const selection = { ...selectedWidget, ...getWidgetTypeLabel(selectedWidget.type) };

	const options: { value: string; icon: IconName; label: string; }[] =
        widgets.map((widget) => ({ value: widget.uuid, ...getWidgetTypeLabel(widget.type) }));

	const onValueSelected = (id: string) => {
		setValue(id);
		onSelect(id);
		onClose();
	};

	const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const multipleOptions = options.length > 1;

	return <SelectorRoot onClick={multipleOptions ? onClick : undefined}>
		<IconWrapper>
			<IconSelector ref={buttonRef} onClick={multipleOptions ? onOpen : undefined}>
				<StyledWidgetIcon icon={selection.icon} />
				{multipleOptions && <StyledArrowIcon icon={'caret-down'} />}
			</IconSelector>
			<StyledLabel>{selection.label}</StyledLabel>
		</IconWrapper>
		{isOpen && createPortal(<Menu
			popperStyles={styles}
			popperAttributes={attributes}
			onSelect={onValueSelected}
			options={options}
			ref={menuRef}
			value={value}
		/>, parent || document.body)}
	</SelectorRoot>;
}

/* eslint-disable no-magic-numbers */
import { useCallback } from 'react';
import styled from 'styled-components';
import { Icon } from '../../icon';
import { Avatar } from '../../avatar';
import { Initials } from '../../initials';

const StyledItemLabel = styled.span<{ $showAvatar?: boolean, $canRemove?: boolean }>`
	border-radius: 18px;
	display: flex;
	align-items: center;
	background-color: ${props => props.theme.form.multiSelectInput.itemBackground};
	padding: 6px ${props => props.$canRemove ? 6 : 12}px 6px ${props => props.$showAvatar ? 4 : 12}px;
	height: 28px;
	font-size: 14px;
	text-overflow: ellipsis;
	overflow: hidden;
	column-gap: 8px;
`;

const StyledIconButton = styled(Icon)`
	padding: 0;
	border: 0;
	background-color: transparent;
	color: ${props => props.theme.form.multiSelectInput.removeIcon};
	cursor: pointer;
	font-size: 16px;
	display: flex;
	align-items: center;

	&:hover {
		color: ${props => props.theme.form.multiSelectInput.removeIconHover};
	}
`;

const StyledName = styled.span`
	text-overflow: ellipsis;
	overflow: hidden;
	color: ${props => props.theme.form.multiSelectInput.itemColor};
`;

export function InputChip({ item, onRemove, canRemove, showAvatar } : {
	item: { id: string, name: string, avatarUrl?: string },
	onRemove: (id: string) => void,
	canRemove?: boolean,
	showAvatar?: boolean,
}) {
	const onCrossClick = useCallback((e) => {
		e.stopPropagation();
		onRemove(item.id);
	}, [item.id, onRemove]);

	return <StyledItemLabel $canRemove={canRemove} $showAvatar={showAvatar}>
		{showAvatar && 
		(item.avatarUrl ? <Avatar size={20} url={item.avatarUrl} /> : <Initials name={item.name} />)}
		<StyledName>{item.name}</StyledName>
		{canRemove && <StyledIconButton icon={'x-circle-solid'} onClick={onCrossClick} />}
	</StyledItemLabel>;
}

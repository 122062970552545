import styled, { css } from 'styled-components';
import { OrientationOptions, StepperOwnerState } from './Stepper.props';

export const STEPPER_GAP_HORIZONTAL = 8;
export const STEPPER_GAP_VERTICAL = 16;

const StepperRoot = styled.ol<{
  ownerState: StepperOwnerState
}>`
  box-sizing: border-box;
  display: flex;
  margin: 0;
  padding: 0;
  font-family: ${props => props.theme.font};
  ${({ ownerState }) => ownerState.orientation === OrientationOptions.horizontal ? css`
    flex-direction: row;
    gap: ${STEPPER_GAP_HORIZONTAL}px;
  ` : css`
    flex-direction: column;
    gap: ${STEPPER_GAP_VERTICAL}px;
  `}
`;

export { StepperRoot };

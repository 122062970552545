import { Card } from './Card';
import {
	CardActions,
	CardBox,
	CardChip,
	CardContent,
	CardDescription,
	CardHeader,
	CardMedia,
	CardSeperator,
	CardSubHeading,
	CardTitle,
} from './Card.styled';
import { ReactElement } from 'react';
import { Orientation } from './Card.props';
import { PropsWithClassName } from '../tabselector/PropsWithClassName';

export type MediaCardProps = {
	title: string;
	subtitle?: string;
	description?: string;
	disabled?: boolean;
	selected?: boolean;
	chip?: ReactElement;
	actions?: ReactElement;
	image: string;
	orientation?: Orientation;
}

export function MediaCard({
	className,
	selected,
	disabled,
	image,
	title,
	subtitle,
	description,
	chip,
	actions,
	orientation
}: PropsWithClassName<MediaCardProps>) {
	return <Card selected={selected} disabled={disabled} className={className}>
		<CardBox $orientation={orientation}>
			<CardMedia src={image} alt={title} />
			<CardSeperator />
			<CardContent $padding={20}>
				<CardHeader>
					<CardTitle>{title}</CardTitle>
					{chip && <CardChip>{chip}</CardChip>}
				</CardHeader>
				{subtitle && <CardSubHeading>{subtitle}</CardSubHeading>}
				{description && <CardDescription>{description}</CardDescription>}
				{actions ? <CardActions>{actions}</CardActions> : null}
			</CardContent>
		</CardBox>
	</Card>;
}

import React, { PropsWithChildren, useMemo } from 'react';

type CanShareContextValue = {
	canShareAccess: boolean,
	isOwner: boolean,
	isGlobalAdmin: boolean,
}

export const CanShareContext = React.createContext<CanShareContextValue>({
	canShareAccess: false,
	isOwner: false,
	isGlobalAdmin: false,
});

type CanShareContextWrapperProps = {
	isOwner: boolean,
	isGlobalAdmin: boolean,
}

export function CanShareContextWrapper(
	{ isOwner, isGlobalAdmin, children }: PropsWithChildren<CanShareContextWrapperProps>
) {

	const contextValue: CanShareContextValue = useMemo(() => ({
		canShareAccess: isOwner || isGlobalAdmin,
		isOwner,
		isGlobalAdmin,
	}), [isGlobalAdmin, isOwner]);

	return <CanShareContext.Provider value={contextValue}>{children}</CanShareContext.Provider>;
}

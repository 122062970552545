import React, { useCallback } from 'react';
import { Checkbox } from '../../Checkbox';
import { useTranslation } from 'react-i18next';
import { Permissions, PermissionTypes } from '../../../../types/permissions';

type Props = {
	onChange: (permission: Permissions) => void;
	isPermissionSelected: (permission: Permissions) => boolean;
};

export const DataSourceCheckboxes = ({ isPermissionSelected, onChange }: Props) => {
	const handleChange = useCallback((permission: Permissions) => () => onChange(permission), [onChange]);
	const { t } = useTranslation();

	return (
		<>
			<Checkbox
				id='read'
				checked={isPermissionSelected(PermissionTypes.read)}
				onChange={handleChange(PermissionTypes.read)}
				disabled
				labelText={t('View only')}
				helpText={t('Enables use in calculations (e.g. formulas, notifications)')}
			/>
			<Checkbox
				id='write'
				checked={isPermissionSelected(PermissionTypes.write)}
				onChange={handleChange(PermissionTypes.write)}
				labelText={t('Edit data source')}
				helpText={t('Modify registrations (manual), Settings')}
			/>
			<Checkbox
				id='export'
				checked={isPermissionSelected(PermissionTypes.export)}
				onChange={handleChange(PermissionTypes.export)}
				labelText={t('Export data source')}
				helpText={t('Exported data is saved to employee’s device')}
			/>
		</>
	);
};

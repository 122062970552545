import { t } from '../setupTranslations';
import { Permissions, PermissionTypes } from '../types/permissions';

export function getLabelForObjectPermission(objectPermission: Permissions): string {
	switch (objectPermission) {
	case PermissionTypes.read:
		return t('View');
	case PermissionTypes.write:
		return t('Edit');
	case PermissionTypes.export:
		return t('Export');
	default:
		return t('Unknown');
	}
}

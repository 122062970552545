import { ApiSharedObject, ApiSharingEmployee, ApiSharingTeam } from '../types/ApiSharing';
import { SharedObjectModel, transformSharedObjectToModel } from '../models/SharingModel';
import { transformTeamSharingToModel } from '../models/TeamSharingModel';
import { transformEmployeeSharingToModel } from '../models/EmployeeSharingModel';

export const apiPrefix = 'v3/sharing';

export const mapEmployeeSharingToModel = (sharings: Array<ApiSharingEmployee>) =>
	sharings.map(transformEmployeeSharingToModel);

export const mapTeamSharingToModel = (sharings: Array<ApiSharingTeam>) =>
	sharings.map(transformTeamSharingToModel);

export const mapSharedObjectResultsToModel = (
	{ results }: {results: Array<ApiSharedObject>}
): Array<SharedObjectModel> => results.map(transformSharedObjectToModel);

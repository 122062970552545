import React from 'react';
import { Button, Else, Icon, If } from '@plecto/ui';
import styled from 'styled-components';
import { UserInfoSection } from './UserInfoSection';
import { getLabelForObjectPermission } from '../util/getLabelForObjectPermission';
import { ShareeModelType } from '../types/ShareeModelType';
import { TeamInfoSection } from './TeamInfoSection';
import { SharingModel } from '../models/SharingModel';
import { Tooltip } from '@plecto/components';
import { useTranslation } from 'react-i18next';

const StyledWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

const StyledPermissions = styled.span`
	color: #6B7280;
	flex: 1 1 0;
	display: flex;
	gap: 5px;
	text-align: right;
	justify-content: flex-end;
`;

const StyledIcon = styled(Icon)`
	color: orange;
`;

const StyledIconButton = styled(Button)`
    width: 30px;
    height: 30px;
    padding: 0;

    span.icon {
        font-size: 21px;
        line-height: 21px;
        margin: 0;
    }

    &:hover {
        color: #12A34D;
        border-color: #12A34D;
    }
`;

type ShareePermissionListProps<T> = {
	sharings: T,
	onSelect: (sharings: T) => unknown,
	isTeam?: boolean,
	sharedWith: ShareeModelType,
}

export const ShareePermissionList = <T extends SharingModel>({
	isTeam = false,
	sharings,
	onSelect,
	sharedWith,
}: ShareePermissionListProps<T>) => {
	const { t } = useTranslation();

	const permissionsString = sharings.objectPermissions.map(getLabelForObjectPermission).join(', ');
	const onClick = () => onSelect(sharings);

	const showPermissionWarning = !sharedWith.hasWriteAccessUnderlyingObjects;

	return (
		<StyledWrapper>
			<If condition={isTeam}>
				<TeamInfoSection sharee={sharedWith} />
				<Else>
					<UserInfoSection sharee={sharedWith} />
				</Else>
			</If>
			<StyledPermissions>
				{showPermissionWarning && <Tooltip
					placement={'top'}
					message={t('Employee cannot edit this formula because they do not have permission to view ' +
						'some of the underlying objects.')}
				><StyledIcon icon={'warning'} /></Tooltip>}
				{permissionsString}
			</StyledPermissions>
			<StyledIconButton
				variant='white'
				size='default'
				onClick={onClick}
				icon='gear'
			/>
		</StyledWrapper>
	);
};

export const buttonSizes = [
	'default',
	'small',
	'extra-small',
	'link',
] as const;

export type ButtonSize = typeof buttonSizes[number];

export type ButtonSizeDefinition = {
	lineHeight: number;
	paddingVertical: number,
	paddingHorizontal: number,
	paddingNoLabel: number,
	fontSize: number,
	gap: number,
	uppercase: boolean,
};

export const ButtonSizes: {[key in ButtonSize]: ButtonSizeDefinition} = {
	default: {
		lineHeight: 18,
		paddingVertical: 10,
		paddingHorizontal: 16,
		paddingNoLabel: 8,
		fontSize: 14,
		gap: 4,
		uppercase: false,
	},
	small: {
		lineHeight: 18,
		paddingVertical: 6,
		paddingHorizontal: 10,
		paddingNoLabel: 6,
		fontSize: 14,
		gap: 4,
		uppercase: false
	},
	'extra-small': {
		lineHeight: 18,
		paddingVertical: 2,
		paddingHorizontal: 10,
		paddingNoLabel: 3,
		fontSize: 12,
		gap: 5,
		uppercase: false
	},
	link: {
		lineHeight: 20,
		paddingVertical: 2,
		paddingHorizontal: 6,
		paddingNoLabel: 2,
		fontSize: 14,
		gap: 6,
		uppercase: false
	},
};

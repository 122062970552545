import React from 'react';
import { DropdownParentContextProvider } from '../dropdown';

type TemplateCardProps = {
  children?: React.ReactNode;
}

export function TemplateCardContextWrapper({ children }: TemplateCardProps) {
	const [parentRef, setParentRef] = React.useState<HTMLDivElement | null>(null);

	return <DropdownParentContextProvider parent={parentRef}>
		<div ref={setParentRef}>
			{children}
		</div>
	</DropdownParentContextProvider>;
}

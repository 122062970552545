import { useCallback } from 'react';

export function useMergedRefs<T>(...refs: React.Ref<T>[]): React.RefCallback<T> {
	const mergedRef = useCallback(inst => {
		for (const ref of refs.filter(Boolean)) {
			if (typeof ref === 'function') {
				ref(inst);
			} else if (ref) {
				(ref as React.MutableRefObject<T>).current = inst;
			}
		}
		(mergedRef as unknown as React.MutableRefObject<T>).current = inst;
	}, [refs]);
	return mergedRef;
}

export const dayIndexes = {
	sunday: 0,
	monday: 1,
	tuesday: 2,
	wednesday: 3,
	thursday: 4,
	friday: 5,
	saturday: 6,
} as const;

export const monthIndexes = {
	december: 12,
} as const;

export const businessHoursStart = 9;
export const businessMinutesStart = 0;
export const businessHoursEnd = 16;
export const businessMinutesEnd = 0;

export const monthYearSeparator = ' ';
export const defaultFormat = 'YYYY/MM/DD HH:mm';
export const defaultPlaceholder = 'YYYY/MM/DD';

export type DateValue = string | null;

export const separator = '~';

import { SearchBar } from './SearchBar';
import { Button, Else, If } from '@plecto/ui';
import { ShareePermissionList } from './ShareePermissionList';
import { ShareeType, shareeTypes } from '../types/ShareeType';
import React, { useCallback, useEffect, useState } from 'react';
import { StyledParagraph, StyledScrollableContainer, StyledSharings } from './ObjectAccessOverview';
import { TeamSharingModel } from '../models/TeamSharingModel';
import { SharingModel } from '../models/SharingModel';
import styled from 'styled-components';
import { Loader } from '@plecto/components';
import { useTranslation } from 'react-i18next';

const StyledLoaderContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 0 20px;
`;

type ObjectAccessTeamsOverviewProps = {
	teamSharings: Array<TeamSharingModel>,
	isLoadingTeamSharings: boolean,
	onAddMore: (e: React.MouseEvent<HTMLElement>) => void,
	onSelectSharee: (sharings: SharingModel, shareeType: ShareeType) => void,
}

export function ObjectAccessTeamsOverview({
	teamSharings,
	isLoadingTeamSharings,
	onAddMore,
	onSelectSharee
}: ObjectAccessTeamsOverviewProps) {
	const [filteredTeamSharings, setFilteredTeamSharings] = useState<Array<TeamSharingModel>>(teamSharings);
	const { t } = useTranslation();
	const filterTeams = useCallback((searchValue: string) => {
		const filtered = teamSharings.filter(sharee =>
			sharee.sharedWithTeam.name.toLowerCase().includes(searchValue.toLowerCase()));

		setFilteredTeamSharings(filtered);
	}, [teamSharings]);

	const onSelect = (sharings: TeamSharingModel) => onSelectSharee(sharings, shareeTypes.teams);

	useEffect(() => {
		setFilteredTeamSharings(teamSharings);
	}, [teamSharings]);

	return <>
		<SearchBar onChange={filterTeams} placeholderText={t('Search teams')}>
			<Button
				className='btn-block'
				size='small'
				onClick={onAddMore}
				label={t('Add team')}
				icon="plus"
			/>
		</SearchBar>
		{isLoadingTeamSharings ?
			<StyledLoaderContainer><Loader size={'sm'} /></StyledLoaderContainer> :
			<StyledScrollableContainer>
				<StyledSharings>
					<If condition={teamSharings.length === 0}>
						<StyledParagraph>
							{t('No teams have been added yet. ')}
						</StyledParagraph>
						<Else>
							<>
								{filteredTeamSharings.map(sharings =>
									<ShareePermissionList
										key={sharings.sharedWithTeam.uuid}
										sharings={sharings}
										onSelect={onSelect}
										isTeam
										sharedWith={sharings.sharedWithTeam}
									/>
								)}
							</>
						</Else>
					</If>
				</StyledSharings>
			</StyledScrollableContainer>
		}
	</>;
}
